import React, { FC } from "react";
import {
  MarketingSectionContainer,
  HomeSection,
} from "./MarketingSection.style";
import { SkeletonCard, Icons, FadeInWhenVisible, PageBuilder } from "..";
import { IIcon } from "../../interfaces";
import BlockContent from "@sanity/block-content-to-react";

interface IMarketingSection {
  data: {
    title: string;
    content: string;
    itemList: string[];
  };
  children?: any;
  lightBlue?: true;
}
const serializers = {
  marks: {
    link: ({ mark, children }: { mark: any; children: any }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noreferrer noopener">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
  },
  listItem: (props: any) =>
    props.type === "bullet" ? (
      <li>{props.children}</li>
    ) : (
      <li>
        <Icons type={IIcon.check} />
        {props.children}
      </li>
    ),

  ignoreUnknownTypes: true,
  unknownType: (props: any, key: number) => {
    switch (props.node._type) {
      case "pageBuilderButtonList":
        return (
          <PageBuilder
            key={key}
            pageBuilder={[props.node]}
            serializer={serializers}
          />
        );
      // break;
    }
  },
  // _type: (props: any) => console.log("serializer", props),
};

const MarketingSection: FC<IMarketingSection> = (props) => {
  return (
    <FadeInWhenVisible>
      <HomeSection lightBlue={props.lightBlue}>
        <MarketingSectionContainer>
          <div className="left">
            <BlockContent serializers={serializers} blocks={props.data} />
          </div>
          <div className="right">
            <SkeletonCard
              title="Budget Management"
              content="Retain control with budget management"
              icon={IIcon.searchDollar}
              className="skeleton-card card1"
            />
            <SkeletonCard
              title="Permissions Control"
              content="Full control on how you want to structure your operations process, team members and sub-contractors."
              icon={IIcon.keyIcon}
              className="skeleton-card card2"
            />

            <SkeletonCard
              title="Site Templates"
              content="Lists of consumables for multiple sites, taking the hassle out of placing repeat orders."
              icon={IIcon.clipboard}
              className="skeleton-card card3"
            />
            <SkeletonCard
              title="Consumption Reports"
              content="Insightful reports that will help you optimize your ordering processes."
              icon={IIcon.chart}
              className="skeleton-card card4"
            />
          </div>
          {/* </div> */}
        </MarketingSectionContainer>
      </HomeSection>
    </FadeInWhenVisible>
  );
};

export default MarketingSection;
