import React, { FC, useContext } from "react";
import { Section, BannerContainer } from "./Banner.style";
import { Button, SubscribeForm } from "../index";
import { StateContext } from "../../Context/ContextProvider";
import { useHistory } from "react-router-dom";
interface IBanner {
  pageBuilder?: true;
  data?: any;
  content?: any;
}

const Banner: FC<IBanner> = ({ content, pageBuilder, data }) => {
  const { setContactForm, setCatalogue } = useContext(StateContext);
  let history = useHistory();
  const determineButtonFunction = (block: any) => {
    let returnedFunction;
    if (block?.buttonFunction) {
      if (block.buttonFunction === "downloadCatalogue") {
        returnedFunction = setCatalogue;
      }
      if (block?.buttonFunction === "enquire") {
        returnedFunction = setContactForm;
      }
      if (block?.buttonFunction === "redirectExternal") {
        returnedFunction = function () {
          window.open(block.externalURL, "_blank");
        };
      }
      if (block?.buttonFunction === "redirect") {
        returnedFunction = () => history.push(block.to);
      }
      return returnedFunction;
    } else {
      return null;
    }
  };
  let btnFunc = determineButtonFunction(data);

  return (
    <Section>
      <BannerContainer>
        <h2>{pageBuilder ? data.content : content}</h2>
        {pageBuilder && data.buttonFunction === "subscribe" ? (
          <SubscribeForm from="banner" />
        ) : pageBuilder ? (
          <>
            <Button
              type={data.buttonType}
              size="long"
              outlined={data?.outlined && data?.outlined}
              className={data.className}
              text={data.buttonText}
              onClick={btnFunc}
            />
          </>
        ) : (
          <Button
            text={"Enquire Now"}
            type={"primary"}
            size="long"
            onClick={setContactForm}
          />
        )}
      </BannerContainer>
    </Section>
  );
};

export default Banner;
