import styled, { createGlobalStyle } from "styled-components";
import { motion } from "framer-motion";

const GlobalStyle = createGlobalStyle`

* {
    box-sizing: border-box;
    margin:0;
    padding:0;
    font-family: "roboto","Raleway",sans-serif;

    p {
      line-height: 2rem;
    }
}
/* html {
  scroll-behavior: smooth;
  scroll-padding-top: 90px;
} */
`;

export const Container = styled(motion.div)`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
`;

export default GlobalStyle;
