import React, { FC } from "react";
import { SectionContainer } from "./Section.style";

interface ISection {
  className?: string;
  children?: any;
  lightBlue?: boolean;
  id?: string;
}

const Section: FC<ISection> = ({ className, children, lightBlue, id }) => {
  return (
    <SectionContainer lightBlue={lightBlue} className={className} id={id}>
      {children}
    </SectionContainer>
  );
};

export default Section;
