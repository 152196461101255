import React, { FC, useEffect, useState } from "react";
import { BlogPostContainer } from "./Blogs.style";
import { Icons, PageBuilder, Section, Seo } from "../../components";
import moment from "moment";
import { IIcon } from "../../interfaces";
import { HashLink as Link } from "react-router-hash-link";
import { useParams } from "react-router-dom";
import { sanityFetchSinglePost } from "../../utils/globalFunctions";
import LoaderComponent from "../../components/Loader/LoaderComponent";

export interface IBlogPost {
  history?: any;
  match?: any;
  location: {
    state: {
      title: string;
      content: any;
      headerImage: any;
      slug: string;
      thumbnailImage: any;
      date: string;
      tags: string[];
    };
  };
}

const BlogPost: FC<IBlogPost> = (props: any) => {
  let { id } = useParams<any>();

  const [post, setPost] = useState<any>([]);

  useEffect(() => {
    let fetchRes = sanityFetchSinglePost(id);
    fetchRes.then((res) => setPost(res));
  }, [id]);

  if (post.length === 0) {
    return <LoaderComponent />;
  }
  return (
    <BlogPostContainer>
      {post.seo && (
        <Seo
          description={post.seo.meta_description && post.seo.meta_description}
          keyword={post.seo.focus_keyword && post.seo.focus_keyword}
          title={post.seo.seo_title && post.seo.seo_title}
        />
      )}

      <Section className="back-wrapper">
        <div className="back-btn-container">
          <Link className="btn" to="/blogs#tags">
            <Icons type={IIcon.arrowLeft} className="icon" />{" "}
            <span className="back">Back</span>
          </Link>
        </div>
      </Section>
      <Section className="post-section-container">
        <h2 className="blog-title">{post.title}</h2>
        <div className="info-container">
          <div className="date">
            <Icons type={IIcon.calendar} />{" "}
            <p>{moment(post.publishedAt).format("LL")}</p>
          </div>
          <div className="tags">
            <Icons type={IIcon.tag} />
            <div className="tags-container">
              {post.tags !== 0 &&
                post.tags.map((tag: string, key: number) => (
                  <p key={key}>{tag}</p>
                ))}
            </div>
          </div>
        </div>

        <main className="blog-post-content">
          <PageBuilder pageBuilder={post.postPageBuilder} />
        </main>
      </Section>
    </BlogPostContainer>
  );
};

export default BlogPost;
