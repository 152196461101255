import styled, { css } from "styled-components";
import { Container } from "../../Theme/globalStyle";
import device from "../../utils/deviceScreenSizes";

interface ITwoSectionContainer {
  reverse?: boolean;
  double?: boolean;
  lightBlue?: boolean;
}

export const TwoSectionContainer = styled.div<ITwoSectionContainer>`
  .two-section-content-wrapper {
    @media ${device.tablet} {
      flex-direction: column-reverse;
      /* background: red; */
      @media ${device.mobileL} {
        overflow: hidden;
      }
    }
  }
  img {
    max-width: 100%;
  }
  p {
    color: ${({ theme }) => theme.colors.primary};
    padding-bottom: 1rem;
  }
  ul {
    list-style: none;
    li {
      display: flex;
      align-self: start;
      padding-top: 2px;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      line-height: 32px;
      color: ${({ theme }) => theme.colors.primary};
      span {
        align-self: start;
        padding-right: 1rem;
        padding-top: 0.4rem;
        font-size: 1.2rem;

        @media ${device.mobileL} {
          /* margin: 0 auto; */
          padding-right: 1rem;
        }
      }
    }
  }
  .left-images-container-single {
    height: 550px;
    @media ${device.tablet} {
      height: 250px;
    }
    width: 100%;
    position: relative;
    .single-container {
      width: 100%;
      height: 100%;
    }
    .top {
      position: absolute;
      top: 0;
      right: 0;
      width: 300px;
      height: 400px;
      z-index: 5;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    }
    .block-content {
      ul {
        list-style: none;
        li {
          display: flex;
          align-self: start;
          padding-top: 2px;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          line-height: 32px;

          span {
            align-self: center;
            padding-right: 1.5rem;
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .text-on-side-line {
    position: absolute;
    bottom: 0;
    width: 256px;
    height: 8px;
    background: ${(p) => p.theme.colors.secondary};
  }
  .text-on-side {
    align-self: baseline;
    h2 {
      line-height: 1;
      color: ${(p) => p.theme.colors.primary};
      font-size: 3rem;
      line-height: 4rem;
      padding-right: 2rem;
      font-weight: 300;
      padding-bottom: 1rem;
      @media ${device.tablet} {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
    h1 {
      color: ${(p) => p.theme.colors.primary};
      font-size: 3rem;
      line-height: 4rem;
      padding-right: 2rem;
      font-weight: 300;
      padding-bottom: 1rem;
      @media ${device.tablet} {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
  }
  background: ${(props) =>
    props.lightBlue ? props.theme.colors.lightBlue : props.theme.colors.white};

  ${Container} {
    display: flex;
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media ${device.tablet} {
      flex-direction: column-reverse;
      /* background: red; */
      @media ${device.mobileL} {
        overflow: hidden;
      }
    }

    ${({ reverse }) =>
      reverse &&
      css`
        flex-direction: row-reverse;
      `}

    .left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 2rem;
      position: relative;
      max-width: 620px;
      @media ${device.tablet} {
        padding-right: 0;
        margin-top: 3rem;
      }
      ${({ reverse }) =>
        reverse &&
        css`
          padding-right: 0;
        `}

      .left-images-container-double {
        position: relative;
        max-width: 550px;
        width: 100%;
        max-height: 600px;
        height: 100%;
        min-width: 385px;
        min-height: 570px;
        .bottom {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 350px;
          height: 450px;
          background: ${(p) => p.theme.colors.gray3};
          z-index: 9;
        }
        .top {
          position: absolute;
          top: 0;
          right: 0;
          width: 300px;
          height: 400px;
          background: blue;
          z-index: 5;
          background: ${(p) => p.theme.colors.gray3};
          box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);

          & > div {
            &:before {
              content: "";
              background: rgba(0, 0, 255, 0.05);
              width: 100%;
              height: 100%;
              position: absolute;
            }
          }
        }
      }

      .left-images-container {
        width: 100%;
        &-top {
          display: flex;
          max-height: 350px;
          height: 350px;

          .item {
            flex: 1;
            /* background: ${(p) => p.theme.colors.gray2}; */
            margin-right: 25px;
            /* margin-bottom: 25px; */
          }
          .item2 {
            max-height: 266px;
            height: 266px;
            align-self: flex-end;
          }
        }
        &-bottom {
          display: flex;
          max-height: 200px;
          height: 200px;
          justify-content: flex-end;
          margin-top: 25px;
          .item {
            flex: 1;
            /* background: ${(p) => p.theme.colors.gray2}; */
            margin-right: 25px;
          }
          .item3 {
            flex: 2;
            width: 175px;
            height: 175px;
            max-width: 175px;
          }
          .item4 {
            width: 200px;
            height: 200px;
            max-width: 200px;
          }
        }
      }
    }
    .right.custom-container {
      h2 {
        line-height: 1;
        font-weight: 300;
      }
    }
    .right {
      @media ${device.tablet} {
        padding: 0;
      }
      ${({ reverse }) =>
        reverse &&
        css`
          padding-right: 4rem;
        `}
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: ${(props) => props.theme.colors.primary};
      h1 {
        font-size: 3rem;

        font-weight: 500;
        line-height: 4rem;
        padding-bottom: 2rem;
      }
      h1 {
        padding-bottom: 2rem;
        font-weight: 800;
        font-size: ${(props) => props.theme.fontSizes.large};
        @media ${device.tablet} {
          font-size: 2.5rem;
          /* background: red; */
        }
      }
      h2 {
        line-height: 1;
        padding-bottom: 2rem;
        font-weight: 300;
        font-size: ${(props) => props.theme.fontSizes.large};
        @media ${device.tablet} {
          font-size: 2.5rem;
          /* background: red; */
        }
      }
      h3 {
        padding-bottom: 2rem;
        font-weight: 500;
        font-size: 1.5rem;
      }
      p {
        line-height: 32px;
        padding-bottom: 2rem;
      }
      .block-content {
        line-height: 32px;
        padding-bottom: 3rem;
      }
    }
  }
  .btn-container {
    display: flex;
    padding-top: 2rem;

    & > div {
      padding-right: 2rem;
    }
  }
  .image-gallery-container {
    padding-top: 0.5rem;
    align-items: flex-start !important;
  }
`;

interface IImageContainer {
  image: any;
  isSingleImage?: any;
}

export const ImageContainer = styled.div<IImageContainer>`
  /* background: url("https://robohash.org/test") center center;*/
  width: 100%;
  height: 100%;
  background: ${(p) => (p.image ? `url(${p.image})` : p.theme.colors.gray2)}
    no-repeat center center;
  background-size: ${(p) => (p.isSingleImage ? "contain" : "cover")};
`;
