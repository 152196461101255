import styled from "styled-components";
import device from "../../utils/deviceScreenSizes";


export const SearchContainer = styled.div`
.search-list-container {
    max-width: 80rem;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
}
.search-titles {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: #244289;

    @media ${device.tablet} {
        text-align: center;
    }
}
.total-results {
    font-weight: 700;
    font-size: 2.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: #244289;
}
.tile-listing-wrapper {
    margin-bottom: 2rem;
}
.view-more-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    .view-more-btn {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
`