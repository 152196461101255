import React from "react";
import { urlFor } from "../../utils/globalFunctions";
// import { Link } from "react-router-dom";
import { CardContainer, CardLink } from "./Card.style";
// interface IArticleCard {
//   image?: any;
//   title: string;
//   content: string;
//   to?: any;
// }

const ArticleCard = (props: any) => {
  const { data } = props;
  return (
    <>
      {data && data.slug ? (
        <CardContainer whileHover={{ scale: 1.03 }}>
          <CardLink
            to={{
              pathname: `/articles/${data.slug.current}`,
              state: { ...props },
            }}
          >
            <div
              className={`top ${
                !data.cardThumbnailImage?.asset ? "bg-gray" : ""
              }`}
            >
              <img
                src={data && urlFor(data.cardThumbnailImage.asset)}
                alt="article"
              />
            </div>
            <div className="bottom">
              <h4 className="title">{data.title}</h4>
              <p className="content article-content">{data.cardDescription}</p>
              <div className="link-container">
                <div>Continue Reading</div>
              </div>
            </div>
          </CardLink>
        </CardContainer>
      ) : (
        // </CardLink>
        <CardContainer>
          <div
            className={`top ${
              !data.thumbnailImage?.asset.url ? "bg-gray" : ""
            }`}
          >
            {data.thumbnailImage ? (
              <img src={data.thumbnailImage?.asset.url} alt="team" />
            ) : (
              <img src={data.image} alt="team" />
            )}
          </div>
          <div className="bottom">
            <h4 className="title">{data.title}</h4>
            <p className="content article-content">
              {data.cardDescription ? data.cardDescription : data.content}
            </p>
          </div>
        </CardContainer>
      )}
    </>
  );
};

export default ArticleCard;
