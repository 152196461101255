import React from "react";
import {
  TopNavbarContainer,
  TopNavbarSocial,
  TopNavbarSocialLink,
  TopNavbarInfo,
  TopNavbarInfoItem,
  TopNavDropdownHashLinks,
  Nav,
} from "./TopNavbar.style";
import Icons from "../Icons/Icons";
import { IIcon } from "../../interfaces";
import { Link } from "react-router-dom";
import SearchBar from "../SearchBar/SearchBar";
const TopNavbar = () => {
  // const scrollWithOffset = (el: any) => {
  //   const yCoordinate = el.getBoundingClientRect().top;
  //   window.requestAnimationFrame(() => {
  //     window.scrollTo({ top: yCoordinate, behavior: "smooth" });
  //   });
  // };
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const handleClick: any = (e: any) => {
    e.preventDefault();
    const element = document.getElementById("footer");
    if (element) {
      const yCoordinate =
        element.getBoundingClientRect().top + window.pageYOffset - 60;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };
  return (
    <>
      <Nav>
        <TopNavbarContainer>
          <TopNavbarSocial>
            <TopNavbarSocialLink
              to={{ pathname: "https://www.instagram.com/abcoaustralia/ " }}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
            >
              <Icons type={IIcon.instagram} />
            </TopNavbarSocialLink>
            <TopNavbarSocialLink
              to={{
                pathname:
                  "https://www.linkedin.com/company/abco-products-pty-ltd./mycompany/?viewAsMember=true",
              }}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="linkedin"
            >
              <Icons type={IIcon.linkedIn} />
            </TopNavbarSocialLink>
            <TopNavbarSocialLink
              to={{ pathname: "https://www.youtube.com/@abcoaustralia/videos" }}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Youtube"
            >
              <Icons type={IIcon.youtube} />
            </TopNavbarSocialLink>
          </TopNavbarSocial>
          <SearchBar
            className="top-navbar-search-bar"
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
          />
          <TopNavbarInfo>
            {/* <a href={`#footer`} onClick={handleClick}>
              <Icons type={IIcon.location} />{" "}
              <span className="info">Locations</span>
            </a> */}
            <TopNavDropdownHashLinks href={`#footer`} onClick={handleClick}>
              <Icons type={IIcon.location} />{" "}
              <span className="info">Locations</span>
            </TopNavDropdownHashLinks>

            <TopNavbarInfoItem>
              <Link to="/servicing">
                <Icons type={IIcon.cursor} />{" "}
                <span className="info">Servicing</span>
              </Link>
            </TopNavbarInfoItem>
            <TopNavbarInfoItem>
              <a href="tel:1800177399" className="phone">
                <Icons type={IIcon.phone} />{" "}
                <span className="info">1800 177 399</span>
              </a>
            </TopNavbarInfoItem>
            <TopNavbarInfoItem>
              <a
                href="mailto: sales@abcopro.com.au"
                target="_blank"
                rel="noreferrer"
              >
                <Icons type={IIcon.mail} />{" "}
                <span className="info">sales@abcopro.com.au</span>
              </a>
            </TopNavbarInfoItem>
          </TopNavbarInfo>
        </TopNavbarContainer>
      </Nav>
    </>
  );
};

export default TopNavbar;
