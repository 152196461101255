import React, { useEffect, useState } from "react";
import { ConsumablesContainer } from "./Consumables.style";
import { PageBuilder } from "../../components";
import { Seo } from "../../components";
import { sanityConsumables } from "../../utils/globalFunctions";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const Consumables = () => {
  // const { setCatalogue } = useContext(StateContext);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityConsumables()
      .then((res) => {
        setIsLoading(false);
        setData(res[0]);
      })
      .catch((err) => setIsLoading(false));
  }, []);
  return (
    <>
      <ConsumablesContainer>
        <Seo
          title="Abcopro | Power To Clean | Consumables"
          description="Abcopro | Power To Clean | Consumables"
          keyword="Consumables"
        />
        <Seo
          title={data?.seo?.seo_title}
          description={data?.seo?.meta_description}
          keyword={data?.seo?.focus_keyword}
        />
        {!isLoading ? (
          <PageBuilder pageBuilder={data.pageBuilder} />
        ) : (
          <LoaderComponent />
        )}
      </ConsumablesContainer>
    </>
  );
};

export default Consumables;
