import styled from "styled-components";
import device from "../../utils/deviceScreenSizes";

interface ICampaignContainer {
  bg?: any;
}

export const CampaignContainer = styled.div<ICampaignContainer>`
  .nav-wrapper {
    padding-block: 0.5rem;
  }
  .logo-wrapper {
    max-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  nav {
    display: flex;
    justify-content: space-between;
  }

  .call {
    padding-inline: 2rem;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: ${(p) => p.theme.colors.secondary};
    }
    svg {
      margin-left: 0.5rem;
    }
  }
`;
export const CampaignMain = styled.main<ICampaignContainer>`
  background: ${(p) => (p.bg ? `url(${p.bg})` : p.theme.colors.gray3)} no-repeat
    center center;
  position: relative;
  padding-block: 2rem;
  background-size: cover;
  min-height: 800px;
  &:after {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      #244289 0%,
      #244289 0.01%,
      rgba(36, 66, 137, 0) 96.35%
    );
    mix-blend-mode: multiply;
  }
  .form-component {
    @media ${device.tablet} {
      margin: 0 auto;
    }
  }
  .campaign-wrapper {
    z-index: 9;
    position: relative;
    display: flex;
    @media ${device.tablet} {
      flex-direction: column;
    }
    height: 100%;
    .block-content {
      flex: 1;
      align-self: center;
      min-height: 735px;

      @media ${device.tablet} {
        padding-bottom: 3rem;
        padding-right: 0;
        min-height: auto;
        text-align: center;
      }
      display: flex;
      align-items: center;
      height: 100%;
      color: white;
      h2 {
        font-size: 3rem;
        font-weight: 600;
        text-decoration: underline;
        line-height: 5.5rem;
        text-underline-offset: 2rem;
      }
    }
    .form-wrapper {
      flex: 1;
    }
  }
`;
