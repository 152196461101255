import React, { useEffect, useState } from "react";
import { sanityFetchSingleProduct } from "../../utils/globalFunctions";
import { useParams } from "react-router-dom";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { PageBuilder, Seo } from "../../components";
import { MachineryProductPageContainer } from "./Machinery.style";

const MachineryProductPage = () => {
  const [product, setProduct] = useState<any>();

  const { product: productSlug } = useParams<any>();
  useEffect(() => {
    let fetchRes = sanityFetchSingleProduct(productSlug);
    fetchRes.then((res) => {
      setProduct(res[0]);
    });
  }, [productSlug]);
  return (
    <MachineryProductPageContainer>
      {!product ? (
        <LoaderComponent />
      ) : (
        <>
          {product?.seo && (
            <Seo
              title={product.seo?.seo_title}
              description={product.seo?.meta_description}
              keyword={product.seo?.focus_keyword}
            />
          )}

          <PageBuilder
            pageBuilder={product.machineryPageBuilder}
            file={{
              file: product.pdfUpload,
              productName: product.productName,
              url: window.location.href,
            }}
            // file={product.pdfUpload}
          />
        </>
      )}
    </MachineryProductPageContainer>
  );
};

export default MachineryProductPage;
