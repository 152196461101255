export const searchVariants: any = {
  hidden: {
    opacity: 0,
    // display: "none",
  },
  visible: {
    display: "block",
    opacity: 1,
  },
};

export const searchInputVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.1,
    },
  },
};

export const catalogueFormVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      duration: 0.3,
    },
  },
};
export const ContactFormVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      duration: 0.3,
    },
  },
};
