import React, { useEffect, useState } from "react";
import { PageBuilder, Seo } from "../../components";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { sanityFetchEquipmentHire } from "../../utils/globalFunctions";
import { useParams } from "react-router-dom";

const PageTemplate = (props: any) => {
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let { slug } = useParams<any>();
  useEffect(() => {
    let fetchData = sanityFetchEquipmentHire();

    fetchData
      .then((res) => {
        if (!res) {
          return props.history.push("/not-found");
        }
        setIsLoading(false);
        setData(res);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [props.history, slug]);
  return (
    <div>
      <Seo
        title="Abcopro | Power To Clean | Servicing"
        description="Abcopro | Power To Clean | Servicing"
        keyword="Servicing"
      />
      {/* <PageBuilder pageBuilder={data.pageBuilder} /> */}
      {!isLoading ? (
        <PageBuilder pageBuilder={data.pageBuilder} />
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};

export default PageTemplate;
