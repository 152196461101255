import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    primary: "#244289",
    secondary: "#669933",
    gray: "#4F4F4F",
    gray2: "#828282",
    gray3: "#BDBDBD",
    gray4: "#f4f6f9",
    transparentGray: "rgba(79, 79, 79, .8)",
    white: "#fff",
    lightBlue: "#f4f6f9",
    lightBlue2: "#eaedf3",
    overlay:
      "linear-gradient(180deg, #244289 0%, #244289 0.01%, rgba(36, 66, 137, 0) 96.35%)",
  },
  fonts: ["Raleway", "Roboto"],
  fontSizes: {
    small: ".5rem",
    normal: "1rem",
    medium: "1.5rem",
    large: "3rem",
    xl: "4rem",
  },
  sizes: {
    small: ".5rem",
    normal: "1rem",
    large: "3rem",
  },
  lineHeight: {
    normal: "32px",
  },
};

const Theme = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
