import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const ButtonWrapper = styled.div`
  font-size: 1rem;
  font-weight: 800;
  white-space: nowrap;
  .primary {
    background: ${(props) => props.theme.colors.primary};

    &:hover {
      background: ${(props) => props.theme.colors.secondary};
    }
  }
  .secondary {
    border: 2px solid ${(props) => props.theme.colors.white};
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};

    &:hover {
      background: ${(p) => p.theme.colors.primary};
      color: ${(p) => p.theme.colors.white};
      border: 2px solid ${(props) => props.theme.colors.white};
    }
  }
  .secondary.outlined {
    border: 2px solid ${(props) => props.theme.colors.secondary};
    background: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.secondary};

    &:hover {
      background: ${(p) => p.theme.colors.primary};
      color: ${(p) => p.theme.colors.white};
      border: 2px solid ${(props) => props.theme.colors.white};
    }
  }
  .long {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;

export const ButtonDownload = styled.a`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  /* max-width: 260px; */
  border: 2px solid ${(props) => props.theme.colors.white};
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.primary};
  transition: 0.1s ease-in all;
  text-decoration: none;
  display: inline-block;
`;
export const ButtonMail = styled.a`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* max-width: 260px; */
  border: 2px solid ${(props) => props.theme.colors.white};
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.primary};
  transition: 0.1s ease-in all;
  text-decoration: none;
  display: inline-block;
`;
export const ButtonLink = styled(Link)`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  /* max-width: 260px; */
  border: 2px solid ${(props) => props.theme.colors.white};
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.primary};
  transition: 0.1s ease-in all;
  text-decoration: none;
  display: inline-block;
  line-height: initial;
`;
export const ButtonHashLink = styled(HashLink)`
  text-decoration: none;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  /* max-width: 260px; */
  border: 2px solid ${(props) => props.theme.colors.white};
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.primary};
  transition: 0.1s ease-in all;
`;

export const ButtonContainer = styled(motion.button)`
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  /* max-width: 260px; */
  border: 2px solid ${(props) => props.theme.colors.white};
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.disabled ? '#4e4e4e' : props.theme.colors.primary};
  transition: 0.1s ease-in all;
`;
