import styled from "styled-components";
import { Container } from "../../Theme/globalStyle";
import ContactSectionImage from "../../assets/images/building.jpg";
import ContactSectionImage2 from "../../assets/images/new-abconet-bg.png";
import device from "../../utils/deviceScreenSizes";

export const CardsContainer = styled(Container)`
  flex: 1;

  .cards-list {
    display: grid;
    grid-gap: 40px 16px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-items: center;
    margin-top: -12rem;
    padding-bottom: 2rem;
    .top {
      p {
        font-size: 1.25rem;
      }
    }

    & > div {
      border: 1px solid #bdbdbd;
    }
    @media ${device.tablet} {
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 1rem;

      & > div {
        flex: 50%;
        margin-bottom: 2rem;
      }
      @media ${device.mobileL} {
        justify-content: center;
        & > div {
          flex: 100%;
          margin-bottom: 2rem;
        }
      }
    }
  }
  .link-card {
    transition: all ease 0.2s;
    text-decoration: none;
    & > div {
      height: 100%;
    }
  }
`;
interface IHomePromisesContainer {
  type?: string;
}
export const HomePromisesContainer = styled.div<IHomePromisesContainer>`
  background: ${(props) =>
      props.type === "abconet"
        ? `url(${ContactSectionImage2})`
        : `url(${ContactSectionImage})`}
    center center;

  background-size: cover;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .btn-container {
    & > div {
      text-align: center;
    }
  }
  :after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      #244289 0%,
      #244289 0.01%,
      rgba(36, 66, 137, 0) 96.35%
    );
    mix-blend-mode: multiply;
  }
  min-height: 800px;
  /* max-width: 1300px; */
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  @media ${device.mobileL} {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .homepromises-content {
    position: relative;
    z-index: 2;
    max-width: 900px;
    margin-top: 5rem;
    margin-bottom: 5rem;
    @media ${device.mobileL} {
      margin-top: 0;
      margin-bottom: 0;
    }

    h2 {
      text-align: center;
      font-size: ${(p) => p.theme.fontSizes.large};
      color: white;
      font-weight: normal;

      @media ${device.mobileL} {
        font-size: 2rem;
        line-height: 42px;
      }

      span {
        font-weight: bolder;
      }
    }

    p {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      line-height: ${(p) => p.theme.lineHeight.normal};
      text-align: center;
      color: white;
      font-size: 1.2rem;
      @media ${device.mobileL} {
        line-height: 42px;
      }
    }

    @media ${device.tablet} {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
`;
export const Section = styled.section`
  background: ${(p) => p.theme.colors.gray4};
  min-height: 1037px;
  display: flex;
  flex-direction: column;
`;
