import styled from "styled-components";

export const AbconetContainer = styled.div`
  .info h2 {
    font-weight: 500;
  }
  .watch-video {
    margin-top: 2rem;
  }
`;
