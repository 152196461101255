import React, { FC } from "react";
import { Helmet } from "react-helmet";

interface ISeo {
  title: string;
  description: string;
  keyword: string;
  image?: any;
  url?: string;
}

const Seo: FC<ISeo> = ({ title, description, keyword, image }) => {
  const defaultMeta = {
    title: "Abcopro | The Power To Clean",
    description: "Abcopro Description",
    keyword: "Abcopro Keyword",
    image: "test",
  };

  const seo = {
    title: title ? title : defaultMeta.title,
    description: description ? description : defaultMeta.description,
    keyword: keyword ? keyword : defaultMeta.keyword,
    image: `${image || defaultMeta.image}`,
    url: `${document.location.origin}${document.location.pathname}`,
  };

  return (
    <Helmet title={seo.title} titleTemplate={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="keyword" content={seo.keyword} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />

      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};
export default Seo;
