import styled from "styled-components";
import { Container } from "../../Theme/globalStyle";
import device from "../../utils/deviceScreenSizes";

export const ConsumablesCardContainer = styled(Container)`
  @media ${device.mobileL} {
    padding-left: 0;
    padding-right: 0;
  }
  .skeleton-card-list {
    @media ${device.mobileL} {
      padding-left: 0;
      padding-right: 0;
    }
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media ${device.mobileL} {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    justify-items: center;

    .skeleton-card {
      margin: 1.4rem;
      min-height: 290px;
      min-width: 250px;

      .img-container {
        width: 45px;
        height: 45px;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      p {
        height: 100px;
      }
      .bottom {
        text-align: center;
        div {
          font-size: 1rem;
          color: ${(p) => p.theme.colors.primary};
          font-weight: 500;
        }
      }
    }
  }
  h2 {
    padding-top: 3rem;
    /* padding-bottom: 3rem; */
    text-align: center;
    font-size: ${(props) => props.theme.fontSizes.large};
    color: ${(props) => props.theme.colors.primary};
    /* max-width: 800px; */
    margin: 0 auto;
    font-weight: 500;
    text-align: center;
    @media ${device.mobileL} {
      font-size: 2rem;
    }
  }
`;

export const ConsumablesSection = styled.section`
  background: ${(p) => p.theme.colors.lightBlue};
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

export const ConsumablesContainer = styled.div`
  .download-btn {
    margin-top: 3rem;
  }
  .bottom h4 {
    text-align: left;
    font-size: 2rem;
  }
`;
export const ConsumablesArticleContainer = styled.div`
  .post-block-content {
    & > div {
      margin-top: 3rem;
      margin-bottom: 3rem;
      max-width: 800px;
      padding: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      margin: 2rem auto;

      figure {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          max-width: 100%;
          width: 100%;
        }
        img {
          max-width: 100%;
          width: 100%;
          margin-bottom: 1rem;
        }
      }

      color: ${(p) => p.theme.colors.primary};
      p {
        line-height: 2rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-weight: 500;
      }
      h1,
      h2 {
        line-height: 4rem;
      }
      h1 {
        font-size: 3rem;
        font-weight: 500;
      }
      h2 {
        font-size: 3rem;
      }
      ul {
        padding-left: 2rem;
        li {
          line-height: 2rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          font-weight: 500;
        }
      }
      @media ${device.tablet} {
        h1,
        h2 {
          line-height: 2rem;
        }
        h1 {
          font-size: 2.5rem;
        }
        h2 {
          font-size: 2rem;
        }
      }
      @media ${device.mobileL} {
        h1 {
          font-size: 2.5rem;
        }
        h2 {
          font-size: 1.5rem;
        }
      }
      .header-image-container {
        max-width: 800px;
        width: 100%;
        height: 100%;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
`;
