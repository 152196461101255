import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import device from "../../utils/deviceScreenSizes";

export const CardContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;
  max-width: 380px;
  @media ${device.tablet} {
    max-width: initial;
    & > div {
      flex: 1;
    }
    .innovation-card {
      display: flex;
      & > div {
        flex: 1;
        max-height: none;
        height: 100%;
      }
    }
    @media ${device.mobileL} {
      .innovation-card {
        flex-direction: column;
        max-height: none;
      }
    }
  }

  border-radius: 2px;
  border: 1px solid #bdbdbd;

  .blogcard-title {
    color: ${(props) => props.theme.colors.primary};
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-weight: 800;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .info-container {
    .date {
      display: flex;
      padding-bottom: 1rem;
      font-size: 1rem;
      font-weight: 800;
      color: ${(props) => props.theme.colors.primary};
      align-items: center;
    }
    span {
      align-self: center;
      margin-right: 0.5rem;
      svg {
        width: 25px;
        height: 25px;
        align-self: center;
      }
    }
    .tags {
      display: flex;
      .tags-container {
        display: flex;
        color: ${(props) => props.theme.colors.primary};
        font-weight: 800;

        p {
          margin-right: 1rem;
          padding-left: 0.65rem;
          padding-right: 0.65rem;
          background: #eaedf3;
          border: none;
          color: #244289;
        }
      }
    }
  }

  .link-container {
    display: flex;
    padding: 1.8rem;
    @media ${device.tablet} {
      padding-left: 0;
      padding-right: 0;
    }

    div {
      align-self: end;
      color: ${(p) => p.theme.colors.primary};
      font-weight: 500;
      font-size: 1.25rem;
      text-underline-offset: 3px;
      text-decoration: underline;
    }
  }

  .article-content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .top {
    display: flex;
    align-items: center;
    min-height: 300px;
    justify-content: center;
    width: 100%;
    height: 275px;
    background: ${(props) => props.theme.colors.lightBlue};
    color: ${(props) => props.theme.colors.white};
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .bg-gray {
    background: ${(props) => props.theme.colors.gray2};
  }
  .bottom {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    max-height: 190px;
    @media ${device.tablet} {
      max-height: none;
    }
    h4 {
      text-align: center;
      font-size: 1.25rem;
      padding-bottom: 0.5rem;
    }
    .team-name {
      font-size: 2rem;
      color: ${(p) => p.theme.colors.gray};
    }
    .position {
      font-size: 1.5rem;
      text-align: center;
      line-height: 32px;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .title {
      padding-bottom: 1.2rem;
    }
    h5 {
      text-align: center;
      padding-bottom: 1rem;
      font-size: 1rem;
    }
    p {
      line-height: 32px;
    }
    .team-content {
      text-align: center;
      padding-bottom: 1rem;
    }
    padding: 1.5rem;
    color: ${(props) => props.theme.colors.gray};
  }
  .linked {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .connect {
      font-size: 1.25rem;
      color: ${(p) => p.theme.colors.primary};
      font-weight: 800;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      text-decoration: underline;
    }
    a {
      display: flex;
      font-size: 3rem;
      justify-content: center;
      border-radius: 50%;
    }
  }
`;

export const CardListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin: 1rem;
    min-height: 600px;
    min-width: 366px;
    flex: 1;
  }
`;
export const SkeletonCardListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  .skeleton-cards {
    margin: 1.87rem;
    min-height: 325px;
  }
`;

export const SkeletonCardContainer = styled(motion.div)`
  background: ${(p) => p.theme.colors.gray4};
  background: ${(p) => p.theme.colors.white};
  max-width: 250px;
  border: 1px solid #bdbdbd;
  .content {
    display: flex;
    flex-direction: column;
    line-height: 32px;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    .top {
      display: flex;
      text-align: center;
      padding-top: 1.5rem;
      padding-bottom: 0;
      color: ${(p) => p.theme.colors.gray};
      flex-direction: column;
      span {
        margin: 0 auto;
        color: ${(p) => p.theme.colors.gray};
        font-size: 3rem;
      }
      p {
        padding-top: 1.7rem;
        padding-bottom: 1.7rem;
        font-weight: 800;
      }
      .img-container {
        max-width: 45px;
        max-height: 45px;
        margin: 0 auto;
      }
    }
    .bottom {
      color: ${(p) => p.theme.colors.gray};
    }
  }
`;
export const CardLink = styled(Link)`
  text-decoration: none;
`;

export const ProductCardContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;
  max-width: 380px;
  text-decoration: none;
  background: ${(props) => props.theme.colors.lightBlue};
  border-radius: 2px;
  border: 1px solid #bdbdbd;

  .top {
    background: #f2f2f2;
    max-height: 300px;
  }
  .bottom {
    background: white;
    padding: 1.5rem;
    height: 100%;

    h3 {
      font-size: 1.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-decoration: none;
      color: ${(p) => p.theme.colors.gray};
    }

    ul {
      list-style: none;
      li {
        border-bottom: 1px solid ${(p) => p.theme.colors.gray3};
        margin-bottom: 1rem;
        color: ${(p) => p.theme.colors.gray};
        .product-data {
          display: flex;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          font-size: 1rem;
          .left {
            margin-right: auto;
          }
          .right {
            font-weight: bold;
            color: ${(p) => p.theme.colors.gray};
          }
        }
      }
    }
  }
  .read-more {
    background: white;
    padding: 1.5rem;
    color: #244289;
    font-weight: 500;
    font-size: 1.25rem;
    text-underline-offset: 3px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }
`;

export const TeamCardContainer = styled.div`
  text-decoration: none;
  a {
    @media ${device.tablet} {
      text-decoration: none;
      flex-direction: row;
      flex: 100%;
      min-width: 200px;
      min-height: auto;
      display: flex;
      & > div {
        flex: 1;
      }
      .top {
        height: initial;
      }
      @media ${device.mobileL} {
        text-decoration: none;
        flex-direction: column;
        flex: 100%;
        & > div {
          height: 350px;
        }
      }
    }
  }
  .bottom {
    max-height: 450px;
    height: 330px;
    display: flex;
    flex-direction: column;
    p {
      flex: 1;
    }
  }
  h4 {
    text-decoration: none;
  }
  p {
    text-decoration: none;
  }
  .linked {
    margin-bottom: 1rem;
  }
  .linkedIn {
    font-size: 2.5rem;
  }
`;

export const ServicingCardContainer = styled.div`
  min-height: auto !important;
  min-width: auto !important;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  .top {
    padding: 1rem;
    max-height: 300px;
    display: flex;
    background: #f4f6f9;
    img {
      width: 100%;
      max-width: 268px;
      margin: 0 auto;
    }
  }
  .bottom {
    font-weight: 800;
    font-size: 1rem;
    padding: 1.5rem 1rem;

    text-align: center;
    color: ${(p) => p.theme.colors.gray};
    p {
      max-width: 250px;
      margin: 0 auto;
    }
  }
`;
