import styled from "styled-components";

export const HomeContainer = styled.div`
  .learn-more {
    margin-top: 1.5rem;
  }
  .left-layer {
    h3 {
      max-width: 500px;
      font-weight: 400;
      line-height: 4rem !important;
    }
  }
`;
