import styled from "styled-components";
import { Container } from "../../Theme/globalStyle";
import device from "../../utils/deviceScreenSizes";

export const Section = styled.section`
  background: ${(props) => props.theme.colors.primary};
`;

export const BannerContainer = styled(Container)`
  text-align: center;
  color: white;
  padding: 3rem;
  h2 {
    margin: 0 auto;
    font-size: 2rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    line-height: 48px;
    max-width: 800px;
    font-weight: 500;
    @media ${device.mobileL} {
      font-size: 1.5rem;
      line-height: 32px;
    }
  }
  .email {
    @media ${device.mobileL} {
      flex-direction: column;
      button {
        margin-top: 0.5rem;
      }
    }
  }
`;
