import React from "react";
import { FadeInWhenVisible } from "..";
import CountUp from "react-countup";
import { SummaryContainer, SummarySection } from "./Summary.style";
const Summary = () => {
  return (
    <FadeInWhenVisible>
      <SummarySection>
        <SummaryContainer>
          <div className="box" id="years">
            <h1>
              <CountUp start={0} end={50} duration={3.75} useEasing={true} />+
            </h1>
            <p>Years Of Experience</p>
          </div>
          <div className="box">
            <h1>
              {" "}
              <CountUp start={0} end={300} duration={3.75} useEasing={true} />+
            </h1>
            <p>Five Star Reviews</p>
          </div>
          <div className="box">
            <h1>
              <CountUp start={0} end={5} duration={3.75} useEasing={true} />
            </h1>
            <p>Australian Locations</p>
          </div>
          <div className="box">
            <h1>
              <CountUp
                decimals={1}
                start={0}
                end={98.5}
                duration={3.75}
                useEasing={true}
              />
              %+
            </h1>
            <p>Fill Rate on Core Lines</p>
          </div>
        </SummaryContainer>
      </SummarySection>
    </FadeInWhenVisible>
  );
};

export default Summary;
