import React, { createContext, useState, FC } from "react";

type ContextState = {
  showCatalogue: boolean;
  showContactForm: boolean;
  setCatalogue: any;
  setContactForm: any;
  catalogueData: any;
  setCatalogueData: any;
};

const contextDefaultValues: any = {
  showCatalogue: false,
  showContactForm: false,
  catalogueData: {
    file: "test",
    url: "",
    fileName: "",
    isManual: false,
  },
};

export const StateContext = createContext<ContextState>(contextDefaultValues);

const ContextProvider: FC = ({ children }) => {
  const [showCatalogue, setShowCatalogue] = useState<any>(
    contextDefaultValues.showCatalogue
  );
  const [showContactForm, setShowContactForm] = useState<any>(
    contextDefaultValues.showContactForm
  );
  const [catalogueData, setCatalogueData] = useState(
    contextDefaultValues.catalogueData
  );

  const setCatalogue = (bool: boolean) => setShowCatalogue(bool);

  const setContactForm = (bool: boolean) => setShowContactForm(bool);

  return (
    <StateContext.Provider
      value={{
        showCatalogue,
        setCatalogue,
        showContactForm,
        setContactForm,
        catalogueData,
        setCatalogueData,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default ContextProvider;
