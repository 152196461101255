import React, { FC } from "react";
import { ServicingCardContainer } from "./Card.style";

interface IServicingCard {
  data?: any;
}
const ServicingCard: FC<IServicingCard> = ({ data }) => {
  return (
    <ServicingCardContainer>
      <div className="top">
        <img src={data.cardIcon.asset.url} alt={data.title} />
      </div>
      <div className="bottom">
        <p>{data.cardTitle}</p>
      </div>
    </ServicingCardContainer>
  );
};

export default ServicingCard;
