import React, { FC, useState, useEffect } from "react";
import {
  sanityFetchSingleMachinery,
  urlFor,
} from "../../utils/globalFunctions";
import { useHistory, useParams } from "react-router-dom";
import {
  HeroSection,
  PageBuilder,
  Banner,
  TileListing,
  Seo,
} from "../../components";
import { MachineryCategoryPageContainer } from "./Machinery.style";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const MachineryCategoryPage: FC = () => {
  const [machineryCategory, setMachineryCategory] = useState<any>([]);
  const { category } = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    const handleGo404 = () => {
      history.push("/404"); // New line
    };
    let fetchRes = sanityFetchSingleMachinery(category);
    fetchRes
      .then((res) => {
        res.length > 0 ? setMachineryCategory(res[0]) : handleGo404();
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [category, history]);

  return (
    <MachineryCategoryPageContainer>
      {machineryCategory?.length !== 0 ? (
        <>
          <Seo
            title={machineryCategory?.seo?.seo_title}
            description={machineryCategory?.seo?.meta_description}
            keyword={machineryCategory?.seo?.focus_keyword}
          />
          <HeroSection
            title={machineryCategory?.hero?.heroTitle}
            subContent={machineryCategory.hero?.subContent}
            image={
              machineryCategory?.hero?.image?.asset &&
              urlFor(machineryCategory?.hero?.image?.asset)
            }
          />
          <Banner
            content={
              "Need advice on the right machine for you? Our fully trained team can contact you to advise on the best machine for your cleaning needs."
            }
          />
          <PageBuilder pageBuilder={machineryCategory.pageBuilder} />
          {machineryCategory.machinerySubcategory.map(
            (subcategory: any, key: number) => {
              return (
                <div
                  key={key}
                  id={subcategory.title}
                  style={{
                    background: subcategory.backgroundGray
                      ? "#F4F6F9"
                      : "white",
                  }}
                >
                  <TileListing
                    type="machineryProduct"
                    data={subcategory.machineryListing.sort((a: any, b: any) =>
                      a?.order > b?.order ? 1 : -1
                    )}
                    category={category}
                    subcategory={subcategory.slug.current}
                  >
                    <h2>{subcategory.title}</h2>
                  </TileListing>
                </div>
              );
            }
          )}
          <Banner
            content={
              "Need advice on the right machine for you? Our fully trained team can contact you to advise on the best machine for your cleaning needs."
            }
          />
        </>
      ) : (
        <LoaderComponent />
      )}
    </MachineryCategoryPageContainer>
  );
};

export default MachineryCategoryPage;
