import React, { FC, useState, useContext, useEffect } from "react";
import {
  Nav,
  NavbarContainer,
  NavIcon,
  NavLogo,
  NavMenu,
  NavItem,
  NavLinks,
  NavDropdown,
  NavDropdownContent,
  NavDropdownLinks,
  NavBurgerIcon,
  CtaContainer,
  SearchOverlay,
  SearchContainerWrapper,
  NavbarCatalogueImage,
  TabletNavbar,
  NavDropdownHashLinks,
  CtaContainerMobile,
} from "./Navbar.style";
import { navLinks } from "./navLinks";
import { Accordion, FloatingWidget, Icons } from "../index";
import { MenuButton } from "./MenuButton";
import Button from "../Button/Button";
import { IIcon } from "../../interfaces";
import {
  searchVariants,
  searchInputVariants,
} from "../../Theme/animationVariants";
import { AnimatePresence, motion } from "framer-motion";
import abcoLogo from "../../assets/images/AbcoLogo.svg";
import { DownloadCatalogueForm, ContactForm } from "../";
import { StateContext } from "../../Context/ContextProvider";
import {
  sanityFetchAboutUsLinks,
  sanityFetchInnovationLinks,
  sanityFetchMachineryCategoryLinks,
} from "../../utils/globalFunctions";
import { useLocation } from "react-router-dom";
import TopNavbar from "../TopNavbar/TopNavbar";
import SearchBar from "../SearchBar/SearchBar";

const Navbar: FC = () => {
  const {
    showContactForm,
    showCatalogue,
    catalogueData,
    setCatalogue,
    setCatalogueData,
  } = useContext(StateContext);
  const [searchClicked, SetSearchClicked] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [dropdownHidden, setDropdownHidden] = useState(true);
  const [machineryLinks, setMachineryLinks] = useState([]);
  const [innovationLinks, setInnovationLinks] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [aboutLinks, setAboutLinks] = useState([]);
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  useEffect(() => {
    sanityFetchMachineryCategoryLinks().then((data) => {
      let sorted = data.sort((a: any, b: any) =>
        a?.order > b?.order ? 1 : -1
      );
      let addServicing: any = {
        order: 99,
        slug: {
          current: "servicing",
        },
        subcategory: [],
        title: "Preventative Maintenance & Servicing ",
      };
      let addEquipmentHires: any = {
        order: 99,
        slug: {
          current: "equipment-hires",
        },
        subcategory: [],
        title: "Equipment Hire",
      };
      let addViewCatalogues: any = {
        order: 99,
        slug: {
          current: "https://issuu.com/abcopro.com.au",
        },
        isExternal: true,
        subcategory: [],
        title: "View Catalogues",
      };
      sorted.push(addServicing);
      sorted.push(addEquipmentHires);
      sorted.push(addViewCatalogues);
      setMachineryLinks(sorted);
    });
    sanityFetchInnovationLinks().then((data) => {
      setInnovationLinks(data);
    });
    sanityFetchAboutUsLinks().then((data) => {
      setAboutLinks(data);
    });

    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    setDropdownHidden(true);
    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  useEffect(() => {
    setDropdownHidden(true);
    let dropdownTimeout = setTimeout(() => {
      setDropdownHidden(false);
    }, 100);
    return () => {
      clearTimeout(dropdownTimeout);
    };
  }, [location]);
  const searchClickHandler = () => {
    SetSearchClicked(!searchClicked);
  };

  const showMobileNavHandler = () => {
    setShowMobileNav(!showMobileNav);
  };
  const closeMobileNavHandler = () => {
    setShowMobileNav(false);
  };

  const arrowMotion = {
    rest: {
      y: -4,
      rotate: 0,
      transition: {
        duration: 0.2,

        type: "tween",
        ease: "easeIn",
      },
    },
    hover: {
      y: 0,
      rotate: 180,
      transition: {
        duration: 0.2,
        type: "tween",
        ease: "easeOut",
      },
    },
  };
  const cartMotion = {
    rest: {
      x: 0,
      transition: {
        duration: 0.2,

        type: "tween",
        ease: "easeIn",
      },
    },
    hover: {
      x: 4,
      transition: {
        duration: 0.2,
        type: "tween",
        ease: "easeOut",
      },
    },
  };
  const machineryLinkGenerator = (link: string) => {
    if (link === "servicing") {
      return "/servicing";
    }
    if (link === "equipment-hires") {
      return "/equipment-hires";
    }
    if (link === "https://issuu.com/abcopro.com.au") {
      return "https://issuu.com/abcopro.com.au";
    }
    return `/machinery/${link}`;
  };
  const renderSanityNavLinks = (type: any, onMobile?: boolean) => {
    switch (type) {
      case "about-us":
        return aboutLinks.map((link: any, key: number) => {
          if (!onMobile) {
            return (
              <div className="dropdown-links-divider" key={key}>
                <motion.div initial="rest" whileHover="hover" animate="rest">
                  <NavDropdownLinks to={`/about-us/${link.slug.current}`}>
                    <div className="link-name">{link.title}</div>
                  </NavDropdownLinks>
                </motion.div>
              </div>
            );
          } else {
            return (
              <Accordion
                key={key}
                onClick={closeMobileNavHandler}
                to={`/about-us/${link.slug.current}`}
                title={link.title}
                className="bg-gray"
              ></Accordion>
            );
          }
        });
      case "machinery":
        return machineryLinks.map((link: any, key: number) => {
          if (!onMobile) {
            return (
              <div className="dropdown-links-divider" key={key}>
                <motion.div initial="rest" whileHover="hover" animate="rest">
                  <NavDropdownLinks
                    target={link.isExternal ? "_blank" : ""}
                    rel={link.isExternal ? "noopener noreferrer" : ""}
                    to={
                      link.isExternal
                        ? { pathname: link.slug.current }
                        : machineryLinkGenerator(link.slug.current)
                    }
                  >
                    <div
                      style={{
                        whiteSpace:
                          link.slug.current === "servicing"
                            ? "normal"
                            : "nowrap",
                      }}
                      className={`link-name`}
                    >
                      {link.title}
                    </div>
                  </NavDropdownLinks>
                </motion.div>
                {/* //@ts-ignore */}
                {link.subcategory?.map((subcategory: any, key: number) => (
                  <motion.div
                    key={key}
                    initial="rest"
                    whileHover="hover"
                    animate="rest"
                  >
                    <NavDropdownHashLinks
                      to={`/machinery/${link.slug.current}#${subcategory.title}`}
                      className="subcategory-links"
                      scroll={(el) =>
                        setTimeout(() => scrollWithOffset(el), 1000)
                      }
                    >
                      <div className="link-name">{subcategory.title}</div>
                    </NavDropdownHashLinks>
                  </motion.div>
                ))}
              </div>
            );
          } else {
            return (
              <Accordion
                key={key}
                onClick={closeMobileNavHandler}
                to={
                  link.slug.current === "servicing"
                    ? "/servicing"
                    : link.slug.current === "equipment-hires"
                    ? "/equipment-hires"
                    : `/machinery/${link.slug.current}`
                }
                title={link.title}
                link={link}
                className="bg-gray"
              >
                {link?.subcategory?.length > 0 &&
                  link?.subcategory.map((subcategory: any, key: number) => (
                    <Accordion
                      key={key}
                      onClick={closeMobileNavHandler}
                      hashLink={true}
                      scroll={(el: any) =>
                        setTimeout(() => scrollWithOffset(el), 1000)
                      }
                      to={`/machinery/${link.slug.current}#${subcategory.title}`}
                      title={subcategory.title}
                      className="bg-gray2 capitalize"
                    ></Accordion>
                  ))}
              </Accordion>
            );
          }
        });
      case "innovation":
        return innovationLinks.map((link: any, key: number) => {
          if (!onMobile) {
            return (
              <div className="dropdown-links-divider" key={key}>
                <motion.div initial="rest" whileHover="hover" animate="rest">
                  <NavDropdownLinks to={`/innovation/${link.slug.current}`}>
                    <div className="link-name">{link.title}</div>
                  </NavDropdownLinks>
                </motion.div>
              </div>
            );
          } else {
            return (
              <Accordion
                key={key}
                onClick={closeMobileNavHandler}
                to={`/innovation/${link.slug.current}`}
                title={link.title}
                className="bg-gray"
              ></Accordion>
            );
          }
        });
    }
  };

  return (
    <>
      <Nav>
        <TopNavbar />
        <NavbarContainer>
          <div className="navbar">
            <NavLogo to="/">
              <NavIcon src={abcoLogo} alt="abco-logo" />
            </NavLogo>
            <NavBurgerIcon>
              <MenuButton
                isOpen={showMobileNav}
                //@ts-ignore
                onClick={() => showMobileNavHandler()}
                strokeWidth="4"
                color="#244289"
                transition={{ ease: "easeOut", duration: 0.2 }}
                width="30"
                height="20"
              />
            </NavBurgerIcon>
            <NavMenu>
              {navLinks.map((link, i) => {
                //* Dynamic link generation
                if (link?.sublinks.length === 0) {
                  return (
                    <NavItem
                      initial="rest"
                      whileHover="hover"
                      animate="rest"
                      key={i}
                    >
                      <NavLinks className="gray-on-hover" to={link.path}>
                        {link.name}
                      </NavLinks>
                    </NavItem>
                  );
                }
                return (
                  <NavItem
                    initial="rest"
                    whileHover="hover"
                    animate="rest"
                    key={i}
                  >
                    <NavDropdown>
                      <NavLinks
                        to={link.path}
                        className="gray-on-hover navlink"
                      >
                        <div className="link-name">{link.name}</div>
                        <Icons
                          variants={arrowMotion}
                          className="icon-arrow"
                          type={IIcon.sortDown}
                        />
                      </NavLinks>
                      <NavDropdownContent
                        className={dropdownHidden ? "hide-dropdown" : ""}
                      >
                        <div className="dropdown-content">
                          <div className="filler"></div>
                          <div className="dropdown-links">
                            {link.sanityProvidedLink === false
                              ? link.sublinks.map((subLink: any, i: number) => {
                                  let linkTarget = {};
                                  if (subLink.external) {
                                    linkTarget = { target: "_blank" };
                                  }
                                  return (
                                    <div
                                      className="dropdown-links-divider"
                                      key={i}
                                    >
                                      <motion.div
                                        initial="rest"
                                        whileHover="hover"
                                        animate="rest"
                                      >
                                        <NavDropdownLinks
                                          to={
                                            subLink.external
                                              ? { pathname: subLink.path }
                                              : subLink.path
                                          }
                                          aria-label={subLink.name}
                                          {...linkTarget}
                                        >
                                          <div className="link-name">
                                            {subLink.name}
                                          </div>
                                          {/* <Icons
                                            className="icon-arrow-dropdown"
                                            variants={subLinkArrowMotion}
                                            type={IIcon.sortDown}
                                          /> */}
                                        </NavDropdownLinks>
                                      </motion.div>

                                      {/* //@ts-ignore */}
                                      {subLink?.subcategory?.length > 0 &&
                                        subLink?.subcategory.map(
                                          (subcategory: any, key: number) => (
                                            <NavDropdownLinks
                                              key={key}
                                              to={subcategory.path}
                                              className="subcategory-links"
                                            >
                                              <div className="link-name">
                                                {subcategory.name}
                                                {/* <div className="underline"></div> */}
                                              </div>
                                            </NavDropdownLinks>
                                          )
                                        )}
                                    </div>
                                  );
                                })
                              : renderSanityNavLinks(
                                  link.sanityProvidedLinkType
                                )}
                          </div>
                          {link?.catalogue && (
                            <div className="catalogue">
                              <div className="image-container">
                                <NavbarCatalogueImage
                                  whileHover={{ scale: 1.03 }}
                                  src={link.catalogue.image}
                                  alt="Catalogue"
                                  onClick={() => {
                                    if (link.catalogue.url) {
                                      window.open(link.catalogue.url, "_blank");
                                    } else if (link.catalogue.file) {
                                      setCatalogueData({
                                        file: link.catalogue.file,
                                        isManual: true,
                                      });
                                      setCatalogue(true);
                                    } else {
                                      console.log("no file or url");
                                    }
                                  }}
                                />
                              </div>
                              <div
                                className="text-container"
                                onClick={() => {
                                  if (link.catalogue.url) {
                                    window.open(link.catalogue.url, "_blank");
                                  } else if (link.catalogue?.file) {
                                    setCatalogueData({
                                      file: link.catalogue?.file,
                                      isManual: true,
                                    });
                                    setCatalogue(true);
                                  } else {
                                    console.log("no file or url");
                                  }
                                }}
                              >
                                {link?.catalogue && link.catalogue.text}
                              </div>
                            </div>
                          )}
                        </div>
                      </NavDropdownContent>
                    </NavDropdown>
                  </NavItem>
                );
              })}
            </NavMenu>
            <motion.div initial="rest" whileHover="hover" animate="rest">
              <CtaContainer>
                <Button
                  text="Shop Now"
                  to={" https://abconet.com.au/"}
                  type="secondary"
                  size="long"
                  external
                  className="shop-btn"
                >
                  <Icons variants={cartMotion} type={IIcon.cart} />
                </Button>
              </CtaContainer>
            </motion.div>
          </div>
          <AnimatePresence>
            {showMobileNav && (
              <TabletNavbar
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { height: "auto" },
                  collapsed: { height: 0 },
                }}
                transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                <div className={`tablet-nav-content `}>
                  {navLinks.map((link, key) => {
                    return (
                      <Accordion
                        key={key}
                        onClick={closeMobileNavHandler}
                        to={link.path}
                        title={link.name}
                        className="bg-white"
                      >
                        {link.sanityProvidedLink === false &&
                        link.sublinks.length > 0
                          ? link.sublinks.map((sublink: any, key: number) => (
                              <Accordion
                                key={key}
                                onClick={closeMobileNavHandler}
                                to={sublink.path}
                                title={sublink.name}
                                className="bg-gray"
                              >
                                {sublink?.subcategory?.length > 0 &&
                                  sublink?.subcategory.map(
                                    (subcategory: any, key: number) => (
                                      <Accordion
                                        key={key}
                                        onClick={closeMobileNavHandler}
                                        to={subcategory.path}
                                        title={subcategory.name}
                                        className="bg-gray2 capitalize"
                                      ></Accordion>
                                    )
                                  )}
                              </Accordion>
                            ))
                          : renderSanityNavLinks(
                              link.sanityProvidedLinkType,
                              true
                            )}
                      </Accordion>
                    );
                  })}
                </div>
                <div className="tablet-navbar-search-container">
                  <SearchBar
                    isSearchOpen={isSearchOpen}
                    setIsSearchOpen={setIsSearchOpen}
                    isMobile
                  />
                </div>
                <CtaContainerMobile>
                  <Button
                    text="Shop Now"
                    to={" https://abconet.com.au/"}
                    type="secondary"
                    external
                    className="shop-btn"
                  >
                    <Icons type={IIcon.cart} />
                  </Button>
                </CtaContainerMobile>
              </TabletNavbar>
            )}
          </AnimatePresence>
        </NavbarContainer>
        {/* Tablet View Mode */}
        <AnimatePresence exitBeforeEnter>
          {searchClicked && (
            <SearchOverlay
              searchClicked={searchClicked}
              variants={searchVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <SearchContainerWrapper variants={searchInputVariants}>
                <form className="search-container">
                  <input
                    type="text"
                    id="search-bar"
                    placeholder="What can I help you with today?"
                  />
                  <Icons type={IIcon.search} className={"searchIcon"} />
                </form>
                <div className="btn-container">
                  {/* <Button text="Search" primary /> */}
                </div>
                <Icons
                  type={IIcon.close}
                  className={"searchClose"}
                  onClick={searchClickHandler}
                />
              </SearchContainerWrapper>
            </SearchOverlay>
          )}
        </AnimatePresence>
        <ContactForm
          showContactForm={showContactForm}
          className="sliding-contact-form"
        />
        <DownloadCatalogueForm
          showCatalogue={showCatalogue}
          data={catalogueData}
        />
        {isVisible && (
          <div className="back-to-top">
            <Icons
              type={IIcon.arrowLeft}
              className={"searchClose"}
              onClick={scrollToTop}
            />
          </div>
        )}
      </Nav>
      <FloatingWidget />
    </>
  );
};

export default Navbar;
