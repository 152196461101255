import styled from "styled-components";
import { motion } from "framer-motion";

export const IconContainer = styled(motion.span)`
  display: flex;
  align-self: flex-end;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: ${(props) => (props.color ? props.color : props.theme.colors.primary)};
`;
