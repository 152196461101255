import styled from "styled-components";
import device from "../../utils/deviceScreenSizes";

export const MachineryPostContainer = styled.div`
  background: ${(p) => p.theme.colors.lightBlue};
  .product-card-listing {
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    justify-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media ${device.tablet} {
      max-width: 640px;
      @media ${device.mobileL} {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .product-card {
      margin: 1rem;
      /* min-height: 600px; */
      height: auto;
      min-width: 366px;
      flex: 1;

      @media ${device.tablet} {
        display: flex;
        width: 100%;
        min-width: none;
        max-width: 100%;
        flex-direction: row;
        min-height: none;

        .bottom {
          height: 100%;
        }
        @media ${device.mobileL} {
          flex-direction: column;
          margin: 0;
          min-width: 0;
          .top {
            max-width: 300px;
          }
        }
      }

      a {
        text-decoration: none;
        color: ${(p) => p.theme.colors.gray};
      }
      h3 {
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 1.2rem;
      }
      ul {
        list-style: none;
        li {
          padding-top: 1rem;
          padding-bottom: 1rem;
          font-weight: 500;
        }
      }
      /* .link-container {
        margin-top: 4rem;
      } */
      .bottom {
        height: 100%;
        min-height: 300px;
        background: white;
      }
    }
    .loader-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const MachineryCategoryPageContainer = styled.div`
  .machinery-section-container {
    padding-top: 4rem;
    padding-bottom: 4rem;
    h2 {
      font-size: 3rem;
      color: ${(p) => p.theme.colors.primary};
      text-align: center;
      font-weight: 500;
      margin-bottom: 3rem;
    }
    .machinery-cardlist-container {
      display: flex;
      /* justify-content: space-between; */
      flex-wrap: wrap;
    }
    .product-card-machinery {
      margin: 1rem;
      height: 95%;
      min-height: 650px;
      min-width: 366px;
      flex: 1;
      img {
        display: flex;
        align-items: center;
        min-height: 300px;
        justify-content: center;
        width: 100%;
        height: 275px;
        background: #f4f6f9;
        color: #fff;
      }
    }
  }
`;
export const MachineryProductPageContainer = styled.div`
  position: relative;
  @media ${device.tablet} {
    padding-left: 2rem;
    padding-right: 2rem;
    .back-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .back-btn-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: absolute;
    z-index: 9;
    .btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: ${(p) => p.theme.colors.gray2};
    }
    .icon {
      color: ${(p) => p.theme.colors.gray2};
      font-size: 1.5rem;
    }
    .back {
      font-weight: 800;
    }
  }
`;
