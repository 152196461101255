import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import ReactDOM from "react-dom";
import { loadComponents, getState } from "loadable-components";
import App from "./App";
import { hydrate, render } from "react-dom";
import TagManager from "react-gtm-module";

const rootElement = document.getElementById("root");

declare global {
  interface Window {
    snapSaveState: any;
  }
}
window.snapSaveState = () => getState();

const tagManagerArgs = {
  gtmId: "G-MJ02RYY9C2",
};

TagManager.initialize(tagManagerArgs);

loadComponents()
  .then(() =>
    hydrate(
      <Router>
        <App />
      </Router>,
      rootElement
    )
  )
  .catch(() =>
    render(
      <Router>
        <App />
      </Router>,
      rootElement
    )
  );

// if (rootElement!.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }

// ReactDOM.render(
//   <React.Fragment>
//     <App />
//   </React.Fragment>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
