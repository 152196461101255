import styled, { css } from "styled-components";
import { IHeroWrapper } from "../../interfaces";
import { Container } from "../../Theme/globalStyle";
import device from "../../utils/deviceScreenSizes";

export const HeroImage = styled.div`
  background: rgba(36, 66, 137, 0.2);
  width: 100%;
  min-height: 500px;
`;

export const HeroSectionContainer = styled(Container)`
  display: flex;
  flex-direction: row;

  .left {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${(props) => props.theme.colors.primary};
    background: ${(props) => props.theme.colors.white};
    h2 {
      font-size: ${(props) => props.theme.fontSizes.large};
      padding-bottom: 2rem;
      font-weight: 800;
    }
  }
  .right {
    flex: 3;
  }
`;
interface ITabletBackgroundContainer {
  bg?: any;
  reverse?: boolean;
}

export const TabletBackgroundContainer = styled.div<ITabletBackgroundContainer>`
  /*Up-left pointing*/
  min-height: 600px;
  background: ${(p) => (p.bg ? `url(${p.bg})` : p.theme.colors.gray3)} no-repeat
    ${(p) => (p.reverse ? "left" : "right")} bottom;
  position: relative;
  background-size: cover;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(36, 66, 137, 0.2);
  }
  .triangle-up-left {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 200px 768px 0 0;
    border-color: #ffffff transparent transparent transparent;
    position: relative;
    z-index: 9;
    @media ${device.laptop} {
      border-width: 250px 1024px 0 0;
    }
    @media ${device.custom_640} {
      border-width: 100px 768px 0 0;
    }
  }
  @media ${device.mobileL} {
    min-height: 400px;
  }
`;

export const HeroSectionWrapper = styled.div<IHeroWrapper>`
  .bold {
    font-weight: 800 !important;
  }
  overflow: hidden;
  display: flex;
  background: ${(p) => (p.bg ? `url(${p.bg})` : p.theme.colors.gray3)} no-repeat
    center center;
  background-size: cover;
  width: 100%;
  min-height: ${(p) => (p.large ? `600px` : `600px`)};
  bottom: 0;
  position: relative;

  .james {
    font-weight: 800;
  }
  .subtitle-big {
    font-size: 3rem;
  }
  @media ${device.laptop} {
    min-height: auto;
    flex-direction: column;
    background: white;
    .top-layer {
      line-height: 32px;
      background: white;
      color: ${(props) => props.theme.colors.primary};
      line-height: 32px;
      padding-bottom: 0;
      max-width: 700px;
      padding-top: 2rem;
      padding-inline: 2rem;
      h1 {
        padding-bottom: 1rem;
        font-weight: 800;
        font-size: 3rem;
        line-height: 4rem;
      }
      h2 {
        line-height: 3rem;
        text-transform: capitalize;
        font-size: ${(props) => props.theme.fontSizes.large};
        padding-bottom: 1rem;
        font-weight: 300;
      }
      h3 {
        font-size: 2rem;
        line-height: 48px;
        padding-bottom: 2rem;
      }
      p {
        /* font-size: 2rem; */
        /* line-height: 64px; */
        padding-bottom: 2rem;
      }
    }
  }
  @media ${device.tablet} {
    min-height: auto;
    flex-direction: column;

    .top-layer {
      padding: 4rem 2rem;
      line-height: 32px;
      background: white;
      color: ${(props) => props.theme.colors.primary};
      line-height: 32px;
      padding-bottom: 0;

      h1 {
        padding-bottom: 1rem;
      }
      h2 {
        line-height: 3rem;
        text-transform: capitalize;
        font-size: ${(props) => props.theme.fontSizes.large};
        padding-bottom: 1rem;
        font-weight: 300;
      }
      h3 {
        font-size: 2rem;
        line-height: 48px;
        padding-bottom: 2rem;
      }
      p {
        /* font-size: 2rem; */
        /* line-height: 64px; */
        padding-bottom: 2rem;
      }
      @media ${device.mobileL} {
        h2 {
          font-size: 1.8rem;
          line-height: 32px;
        }
        h3 {
          font-size: 1.5rem;
          line-height: 32px;
          padding-bottom: 2rem;
          font-weight: 500;
        }
        & > div {
          button {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
          }
        }
        p {
          /* font-size: 2rem; */
          /* line-height: 64px; */
          padding-bottom: 2rem;
        }
      }
    }
  }

  .left-layer {
    display: flex;
    flex: ${(p) => (p.reverse ? "4.7" : "4")};
    ${({ reverse }) =>
      reverse &&
      `
      position: relative;
      `}
    flex-direction: column;
    justify-content: center;
    color: ${(props) => props.theme.colors.primary};
    background: ${(props) =>
      props.reverse ? "transparent" : props.theme.colors.white};

    h1 {
      padding-bottom: 1rem;
      font-weight: 800;
      font-size: ${(props) => props.theme.fontSizes.large};
      line-height: 4rem;
    }
    h2 {
      font-size: ${(props) => props.theme.fontSizes.large};
      padding-bottom: 2rem;
      font-weight: 300;
    }
    h3 {
      font-weight: 300;
    }

    .triangle-left {
      ${({ reverse }) =>
        reverse &&
        `
      position: absolute;
      `}
      width: 0;
      height: 0;
      top: 0;
      border-style: solid;
      border-width: ${(p) => (p.large ? `360px` : `360px`)} 120px 0 0;
      border-color: #ffffff transparent transparent transparent;
      opacity: 0.5;
    }
    .triangle-right {
      position: absolute;
      width: 0;
      height: 0;
      right: 0;
      bottom: 0;
      border-style: solid;
      border-width: 0 0 600px 120px;
      border-color: transparent transparent ${(p) => p.theme.colors.white}
        transparent;
    }
    .triangle-left-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: ${(p) => (p.large ? `360px` : `360px`)} 120px 0 0;
      border-color: ${(props) => props.theme.colors.lightBlue} transparent
        transparent transparent;
    }
    .left-layer-content {
      position: absolute;
      /* position: relative; */
      /* width: 120%; */
      z-index: 1;
      width: 100%;

      .container {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 30px;
        padding-right: 30px;
        .info {
          max-width: 500px;
          line-height: 32px;

          h2 {
            line-height: 64px;
            /* text-transform: capitalize; */
          }
          p {
            font-size: 1.2rem;
            padding-bottom: 1rem;
          }
          h3 {
            font-size: 2rem;
            line-height: 48px;
            padding-bottom: 2rem;
          }

          ${({ large }) =>
            large &&
            css`
              h2 {
                padding-bottom: 0;
              }
              p {
                font-size: ${(p) => p.theme.fontSizes.large};
                line-height: 64px;
                padding-bottom: 3rem;
              }
            `}
        }
      }
    }
  }
  .right-layer {
    ${({ reverse }) =>
      reverse &&
      css`
        display: flex;
        position: relative;
        flex: 4;
        flex-direction: column;
        justify-content: center;
        color: ${(props) => props.theme.colors.primary};
        h1 {
          padding-block: 2rem;
          font-weight: 800;
          font-size: ${(props) => props.theme.fontSizes.large};
          line-height: 4rem;
        }
        h2 {
          font-size: ${(props) => props.theme.fontSizes.large};
          padding-bottom: 2rem;
          font-weight: 300;
        }
        .container {
          max-width: 1300px;
          margin-right: auto;
          padding-left: 30px;
          padding-right: 30px;
          button {
            margin-block: 2rem;
          }
          .info {
            max-width: 600px;
            line-height: 32px;

            h2 {
              line-height: 64px;
              text-transform: capitalize;
              font-weight: 400;
            }
            p {
              font-size: 1rem;
            }

            ${({ large }) =>
              large &&
              css`
                h2 {
                  padding-bottom: 1rem;
                }
                p {
                  font-size: ${(p) => p.theme.fontSizes.large};
                  line-height: 64px;
                  padding-bottom: 3rem;
                }
              `}
          }
        }
        .triangle-right-overlay {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 360px 120px;
          border-color: transparent transparent
            ${(props) => props.theme.colors.white} transparent;
        }
      `}
    flex: 4.7;
    position: relative;
    background: ${(props) =>
      props.reverse ? props.theme.colors.white : "rgba(36, 66, 137, 0.2)"};
    /* background: transparent; */
    .triangle-left {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: ${(p) => (p.large ? `600px` : `600px`)} 166px 0 0;
      border-color: #ffffff transparent transparent transparent;
    }
    .triangle-right {
      position: absolute;
      width: 0;
      height: 0;
      right: 0;
      bottom: 0;
      border-style: solid;
      border-width: 0 0 ${(p) => (p.large ? `360px` : `360px`)} 120px;
      border-color: transparent transparent rgba(255, 255, 255, 0.5) transparent;
    }
  }
`;
