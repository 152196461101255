import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Footer,
  PageBuilder,
  Section,
  Seo,
  Testimonials,
} from "../../components";
import { sanityFetchSingleCampaign } from "../../utils/globalFunctions";
import Abco from "../../assets/images/abco.png";
import Enviroplus from "../../assets/images/enviroplus-logo.png";
import Puregiene from "../../assets/images/puregiene.png";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { CampaignContainer, CampaignMain } from "./Campaign.style";
import Forms from "../../components/Forms/Forms";
import { FaPhone } from "react-icons/fa";
import MultiStepForm from "../../components/Forms/MultiStepForm";
import SingleForm from "../../components/Forms/SingleForm";

const Campaign = (props: any) => {
  const testimonials = [
    {
      content:
        "“ I really enjoy working with Abco in Preston's, their operations team is phenomenal in their service and everything is always done with the best attitude ever!!!”",
      fullName: "Sherbrook Pridham",
      company: "Company 123",
    },
    {
      content:
        "“Great company to do business with, lovely staff and very professional!”",
      fullName: "Sarah Clark",
      company: "Company 123",
    },
    {
      content:
        "“Abco is a good company for cleaning products highly recommend.” ",
      fullName: "Amar Batth",
      company: "Company 123",
    },
    {
      content:
        "“It's always a pleasure to deal with the whole team at Abco Prestons , They are efficient and quick to respond to any queries we ever have. Trusted and recommended..!!!” ",
      fullName: "Paula F Lomu",
      company: "Company 123",
    },
    {
      content:
        "“Awesome experience with the service at ABCO warehouse guys in NSW, very friendly and helpful.” ",
      fullName: "Eddie Gale",
      company: "Company 123",
    },
  ];
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let { slug } = useParams<any>();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let fetchData = sanityFetchSingleCampaign(slug, signal);
    fetchData
      .then((res) => {
        if (!res) {
          return props.history.push("/not-found");
        }
        setIsLoading(false);
        setData(res[0]);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    return () => {
      controller.abort();
    };
  }, [props.history, slug]);

  let renderedLogo;
  switch (data.campaignProduct) {
    case "abco":
      renderedLogo = Abco;
      break;
    case "enviroplus":
      renderedLogo = Enviroplus;
      break;
    case "puregiene":
      renderedLogo = Puregiene;
      break;
    default:
      renderedLogo = Abco;
      break;
  }
  return (
    <CampaignContainer>
      <Seo
        title={data?.seo?.seo_title}
        description={data?.seo?.meta_description}
        keyword={data?.seo?.focus_keyword}
      />
      {!isLoading ? (
        <>
          <div className="nav-wrapper">
            <Section>
              <nav>
                <div className="logo-wrapper">
                  <img src={renderedLogo} alt="product logo" />
                </div>
                <div>
                  <Button text="Call Us Now" type="custom" className="call">
                    <FaPhone />
                  </Button>
                </div>
              </nav>
            </Section>
          </div>
          <CampaignMain bg={data && data.campaignImage?.asset.url}>
            <Section>
              <div className="campaign-wrapper">
                <div className="block-content">
                  {/* <BlockContent blocks={data.description} /> */}
                  <h2>{data && data.description}</h2>
                </div>
                <div className="form-wrapper">
                  {data?.formBuilder?.length === 1 ? (
                    <SingleForm
                      data={data?.formBuilder[0]}
                      campaignTitle={data.title}
                      className="form-component"
                    />
                  ) : data?.formBuilder?.length > 1 ? (
                    <MultiStepForm
                      data={data.formBuilder}
                      campaignTitle={data.title}
                    />
                  ) : (
                    <Forms
                      data={data?.formBuilder}
                      campaignTitle={data.title}
                      className="form-component"
                    />
                  )}
                </div>
              </div>
            </Section>
          </CampaignMain>
          {data?.pageBuilderTestimonial ? (
            <PageBuilder pageBuilder={[data.pageBuilderTestimonial]} />
          ) : (
            <Testimonials data={testimonials} />
          )}
        </>
      ) : (
        <LoaderComponent />
      )}
      <Footer />
    </CampaignContainer>
  );
};

export default Campaign;
