import styled from "styled-components";
import { Container } from "../../Theme/globalStyle";

interface ISectionContainer {
  lightBlue?: boolean;
}

export const SectionContainer = styled(Container)<ISectionContainer>`
  background: ${(p) => p.lightBlue && p.theme.colors.lightBlue};
`;
