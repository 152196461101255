import React, { FC, useRef } from "react";
import Slider from "react-slick";
import { Icons } from "..";
import { IIcon } from "../../interfaces";
import { urlFor } from "../../utils/globalFunctions";
import { GalleryContainer, GalleryArrows } from "./Gallery.style";

interface IGallery {
  images: any[];
}

const Gallery: FC<IGallery> = ({ images }) => {
  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    adaptiveHeight: true,
    arrows: false,
    // variableWidth: true,
  };
  const sliderRef = useRef<any>(null);

  function NextArrow(props: any) {
    const { className, style, onClick, children } = props;
    return (
      <GalleryArrows
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        {children}
      </GalleryArrows>
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick, children } = props;
    return (
      <GalleryArrows
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        {children}
      </GalleryArrows>
    );
  }

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };
  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <GalleryContainer>
      <Slider
        className="gallery-slider skeleton-image-loader"
        {...settings}
        ref={sliderRef}
      >
        {images &&
          images.map((img, key) => (
            <div className="gallery-card" key={key}>
              <img
                src={img && urlFor(img).maxWidth(500).maxHeight(500)}
                alt="gallery"
              />
            </div>
          ))}
      </Slider>
      <div className="controls-container">
        <div className="arrow-container">
          <PrevArrow onClick={prevSlide} className="prev">
            <Icons type={IIcon.arrowLeft} />
          </PrevArrow>
        </div>
        <div className="arrow-container">
          <NextArrow onClick={nextSlide} className="next">
            <Icons type={IIcon.arrowLeft} />
          </NextArrow>
        </div>
      </div>
    </GalleryContainer>
  );
};

export default Gallery;
