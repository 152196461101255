import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sanityFetchLandingPageList } from "../../utils/globalFunctions";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { LandingContainer } from "./Landing.style";
import { Button, PageBuilder, Section } from "../../components";
import { FaPhone } from "react-icons/fa";
import Abco from "../../assets/images/abco.png";

const Landing = () => {
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let { slug } = useParams<any>();
  useEffect(() => {
    let fetchData = sanityFetchLandingPageList(slug);
    fetchData
      .then((res) => {
        setData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [slug]);

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <LandingContainer>
      <div className="nav-wrapper">
        <Section>
          <nav>
            <div className="logo-wrapper">
              <img src={Abco} alt="product logo" />
            </div>
            <div>
              <a href="tel:1800177399" style={{ textDecoration: "none" }}>
                <Button text="Call Us Now" type="custom" className="call">
                  <FaPhone />
                </Button>
              </a>
            </div>
          </nav>
        </Section>
      </div>
      {data && <PageBuilder pageBuilder={data.landingPageBuilder} />}
    </LandingContainer>
  );
};

export default Landing;
