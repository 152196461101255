import image1 from "../../assets/images/servicing-vehicle.jpg";
import image2 from "../../assets/images/technician.jpg";
import image3 from "../../assets/images/imop-parts.png";
import styled from "styled-components";

import battery from "../../assets/svg/Battery icon.svg";
import cogs from "../../assets/svg/cogs icon.svg";
import wrench from "../../assets/svg/wrench.svg";
import money from "../../assets/svg/money bag.svg";
import safety from "../../assets/svg/safety icon.svg";
import resale from "../../assets/svg/resale.svg";

export const ServicingtwoSectionContent = {
  title: "Why Choose Abco Servicing",
  doubleImageList: [image2, image1],
  content: `It is a priority for us to ensure that our customers cleaning services remain uninterrupted and that we can support equipment breakdowns fast so you can get back to work.
<br/>
<br/>
  When you buy machinery from Abco, we offer planned servicing to protect your investment and keeping your equipment running optimally. Just like you’d service a car, it is also crucial to service your machines to ensure their longevity and performance!
  `,
};
export const ServicingBenefits = {
  title: "Why choose our Service Team?",
  content: `
  When you buy cleaning equipment from us, it is a priority for us to ensure that our customers cleaning services remain uninterrupted and that we can support equipment breakdowns fast so you can get back to work.
  <br />
  <br />
  You can log your own service, call through our online portal, email or call us. Each booking is confirmed and you can track your technician’s progress and status of the job.
  `,
};
export const ServicingBenefitsContent = {
  title: "Key benefits of a planned service ",
  image: [image3],
  content: `<ul>
    <li>Improve reliability of machines on site</li>
    <li>Reduce risks of machine break down</li>
    <li>Reduce risks of cleaning downtime</li>
    <li>Lower cost of unexpected repairs</li>
    <li>OHS (health & Safety) compliance</li>
    <li>Regular battery checks improve life span</li>
    <li>Protect machine warranty</li>
  </ul>`,
};
export const ServicingContainer = styled.div`
  .book-btn {
    margin-top: 2rem;
  }
  .post-block-content {
    margin: 0 auto;
    max-width: 1300px;
    padding: 3rem;
    h2 {
      color: #244289;
      font-size: 3rem;
      line-height: 4rem;
      padding-right: 2rem;
      font-weight: 300;
      padding-bottom: 1rem;
    }
    p {
      color: #244289;
      padding-bottom: 1rem;
    }
    img {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
`;
export const ServiceListing = [
  {
    title: "Prolongs The Life Of Equipment",
    image: battery,
  },
  {
    title: "Improves The Machine’s Productivity",
    image: cogs,
  },
  {
    title: "Reduces Repairs And Breakdowns",
    image: wrench,
  },
  {
    title: "Minimises Expenses Overtime",
    image: money,
  },
  {
    title: "Ensures The Safety Of Operators",
    image: safety,
  },
  {
    title: "Maintains Trade-In Value for Resale",
    image: resale,
  },
];
