import React, { FC } from "react";
import { TestimonialsContainer, TestimonialArrows } from "./Testimonials.style";
import Quote from "./Quote";
import Slider from "react-slick";
import { ITestimonials } from "../../interfaces";
import { Section, FadeInWhenVisible } from "..";
import useWindowDimensions from "../../utils/useWindowDimensions";

const Testimonials: FC<ITestimonials> = ({ data }) => {
  const { width } = useWindowDimensions();

  let isTablet = width <= 768;
  let isLaptop = width <= 1000;
  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <TestimonialArrows
        className={className}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <TestimonialArrows
        className={className}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  }
  let settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: isTablet ? 1 : isLaptop ? 2 : 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow className="next-arrow" />,
    prevArrow: <PrevArrow className="prev-arrow" />,
  };
  return (
    <FadeInWhenVisible>
      <TestimonialsContainer>
        <Section className="testimonial-section">
          <h2>Over 300 5-star Google Reviews!</h2>
          <Slider {...settings}>
            {data.map((quote, key) => (
              <Quote
                key={key}
                content={quote.content}
                fullName={quote.fullName}
                company={quote.company}
              />
            ))}
          </Slider>
        </Section>
      </TestimonialsContainer>
    </FadeInWhenVisible>
  );
};

export default Testimonials;
