import React, { FC } from "react";
import { Link } from "react-router-dom";
import { urlFor } from "../../utils/globalFunctions";
import { CardContainer } from "./Card.style";
import { HashLink } from "react-router-hash-link";
import { FadeInWhenVisible } from "..";

interface IProductCard {
  data: any;
  className?: string;
}

const MachineryCard: FC<IProductCard> = (props: any) => {
  return (
    <FadeInWhenVisible>
      <CardContainer className={props.className}>
        <div className={`top ${!props.data.thumbnailImage ? "bg-gray" : ""}`}>
          <img
            src={props.data && urlFor(props.data.thumbnailImage)}
            alt="team"
          />
        </div>
        <div className="bottom">
          <Link to={`/machinery/${props.data.slug.current}`}>
            <h3>{props.data.title}</h3>
          </Link>
          <ul>
            {props.data.machinerySubcategory.map(
              (category: any, key: number) => (
                <li key={key}>
                  <HashLink
                    to={`/machinery/${props.data.slug.current}#${category.title}`}
                  >
                    {category.title}
                  </HashLink>
                </li>
              )
            )}
          </ul>
        </div>
      </CardContainer>
    </FadeInWhenVisible>
  );
};

export default MachineryCard;
