import React, { FC, useEffect, useState } from "react";
import { PageBuilder, Seo } from "../../components";
import { sanityFetchPage } from "../../utils/globalFunctions";
import { ArticleContainer } from "./Articles.style";
import { useParams } from "react-router-dom";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const Articles: FC = (props: any) => {
  let { slug } = useParams<any>();
  const [article, setArticle] = useState<any>([]);
  useEffect(() => {
    let fetchRes = sanityFetchPage(slug);

    fetchRes.then((res) => {
      setArticle(res);
    });
  }, [slug]);

  if (article.length === 0) {
    return <LoaderComponent />;
  }

  return (
    <ArticleContainer>
      <Seo
        description={article?.seo?.meta_description}
        keyword={article?.seo?.focus_keyword}
        title={article?.seo?.seo_title}
      />
      <PageBuilder pageBuilder={article.pageBuilder} />
    </ArticleContainer>
  );
};

export default Articles;
