import styled from "styled-components";
import device from "../../utils/deviceScreenSizes";

export const TechnicalSpecificationContainer = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  @media ${device.mobileL} {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  background: ${(p) => p.theme.colors.white};
  h2 {
    text-align: center;
    font-size: 3rem;
    font-weight: 500;
    color: ${(p) => p.theme.colors.gray};
    padding-bottom: 2rem;
    @media ${device.mobileL} {
      font-size: 2rem;
    }
  }
  ul {
    list-style: none;
    color: ${(p) => p.theme.colors.gray};
    columns: 2;

    @media ${device.tablet} {
      columns: 1;
      @media ${device.mobileL} {
        columns: 1;
      }
    }
    column-gap: 5rem;
    li {
      font-size: 1.2rem;
      display: flex;
      padding-top: 1rem;
      padding-bottom: 0.7rem;
      margin-bottom: 0.5rem;
      border-bottom: 1.8px solid #bdbdbd;

      .specification-description {
        /* margin-right: auto; */
        /* flex: 1; */
        font-size: 1.3rem;
        @media ${device.mobileL} {
          font-size: 1rem;
        }
      }
      .specification-value {
        /* flex: 1; */
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        @media ${device.mobileL} {
          font-size: 1rem;
        }
      }
      .lines {
        /* border-bottom: 1px solid ${(p) => p.theme.colors.gray}; */
        flex: 2;
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
`;
