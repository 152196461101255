import React, { FC } from "react";
import { GallerySectionContainer } from "./Gallery.style";
import { FadeInWhenVisible, PageBuilder, Section, Icons, Gallery } from "../";
import BlockContent from "@sanity/block-content-to-react";
import { IIcon } from "../../interfaces";
// import Slider from "react-slick";

interface IGallerySection {
  data: {
    content: any[];
    images: any[];
    backgroundGray: boolean;
    imageRight: boolean;
  };
}
//  Will refactor later

const GallerySection: FC<IGallerySection> = (props: any) => {
  const serializers = {
    listItem: (props: any) =>
      props.type === "bullet" ? (
        <li>{props.children}</li>
      ) : (
        <li>
          <Icons type={IIcon.check} />
          {props.children}
        </li>
      ),
    ignoreUnknownTypes: true,
    unknownType: (props: any) => {
      switch (props.node._type) {
        case "pageBuilderButtonList":
          return <PageBuilder pageBuilder={[props.node]} />;
        // break;
      }
    },
  };
  return (
    <FadeInWhenVisible>
      <GallerySectionContainer
        reverse={props.reverse}
        lightBlue={props.data.backgroundGray}
      >
        <Section className="gallery-section">
          <div className={`left image-gallery-container`}>
            <div className="left-images-container image-gallery">
              <Gallery images={props.data.images} />
            </div>
          </div>
          <div className="right">
            <BlockContent
              blocks={props.data.content}
              // @ts-ignore
              serializers={serializers}
            />

            {props.children}
          </div>
        </Section>
      </GallerySectionContainer>
    </FadeInWhenVisible>
  );
};

export default GallerySection;
