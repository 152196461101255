import React, { FC } from "react";
import {
  ButtonContainer,
  ButtonWrapper,
  ButtonLink,
  ButtonHashLink,
  ButtonDownload,
  ButtonMail,
} from "./Button.style";

// export interface IButton {
//   primary?: boolean | string;
//   secondary?: boolean | string;
//   long?: boolean;
//   main?: boolean | string;
//   text?: string;
//   size?: string;
//   outlined?: boolean;
//   hideOnMobile?: boolean;
//   onClick?: any;
//   className?: string;
// }
export interface IButton {
  type: "primary" | "secondary" | "custom";
  buttonType?: any;
  text: string;
  size?: "long";
  disabled?: boolean;
  outlined?: true;
  hideOnMobile?: true;
  childrenLeft?: boolean;
  onClick?: any;
  className?: string;
  to?: any;
  external?: true;
  children?: any;
  id?: any;
  toSection?: string;
  file?: any;
  mailTo?: string;
  subscribe?: boolean;
  block?: any;
}

const Button: FC<IButton> = (props) => {
  let buttonAttr = {};
  let outlined = props.outlined ? "outlined" : "";
  let hideOnMobile = props.hideOnMobile ? "hideOnMobile" : "";
  if (props.external) {
    buttonAttr = {
      target: "_blank",
      rel: "noopener noreferrer",
    };
  }
  return (
    <ButtonWrapper>
      {props.toSection ? (
        <ButtonHashLink
          scroll={(el: any) => el.scrollIntoView({ behavior: "smooth" })}
          to={props.toSection}
          className={`${props.className ? props.className : ""} ${props.type} ${
            props.size ? props.size : ""
          } ${outlined} ${hideOnMobile}`}
          aria-label={props.text}
        >
          {props.text}
          {props.children}
        </ButtonHashLink>
      ) : props.to ? (
        <ButtonLink
          to={props.external ? { pathname: props.to } : props.to}
          {...buttonAttr}
          className={`${props.className ? props.className : ""} ${props.type} ${
            props.size ? props.size : ""
          } ${outlined} ${hideOnMobile}`}
          aria-label={props.text}
        >
          {props.text}
          {props.children}
        </ButtonLink>
      ) : props.file ? (
        <ButtonDownload
          onClick={props.onClick}
          className={`${props.className ? props.className : ""} ${props.type} ${
            props.size ? props.size : ""
          } ${outlined} ${hideOnMobile}`}
          aria-label={props.text}
        >
          {props.text}
          {props.children}
        </ButtonDownload>
      ) : props.mailTo ? (
        <ButtonMail
          onClick={props.onClick}
          className={`${props.className ? props.className : ""} ${props.type} ${
            props.size ? props.size : ""
          } ${outlined} ${hideOnMobile}`}
          aria-label={props.text}
          target="_blank"
          href={`mailto: ${props.mailTo}`}
        >
          {props.text}
          {props.children}
        </ButtonMail>
      ) : (
        <ButtonContainer
          onClick={props.onClick}
          className={`${props.className ? props.className : ""} ${
            !props.disabled && props.type
          } ${props.size ? props.size : ""} ${outlined} ${hideOnMobile}`}
          disabled={props.disabled}
          aria-label={props.text}
          id={props.id}
          type={props.buttonType}
        >
          {props.childrenLeft && props.children}
          {props.text}
          {!props.childrenLeft && props.children}
        </ButtonContainer>
      )}
    </ButtonWrapper>
  );
};

export default Button;
