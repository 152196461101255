const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  custom_797: "797px",
  custom_545: "545px",
  // tablet: "768px",
  laptop: "1024px",
  custom_1268: "1268px",
  custom_640: "640px",
  laptopL: "1440px",
  desktop: "2560px",
};

const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  custom_1268: `(max-width: ${size.custom_1268})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
  custom_797: `(max-width: ${size.custom_797})`,
  custom_545: `(max-width: ${size.custom_545})`,
  custom_640: `(max-width: ${size.custom_640})`,
};

export default device;
