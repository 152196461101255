import React from "react";
import { FormFieldContainer } from "../../components/Forms/Forms.style";

type FieldSelectProps = {
  field: any;
  selectedValue: any;
  isConditional: any;
  index: any;
  register: any;
  errors: any;
};
const FieldSelect: React.FC<FieldSelectProps> = ({
  field,
  selectedValue,
  isConditional,
  index,
  register,
  errors,
}) => {
  //   const { register, errors } = data;
  return (
    <div key={index}>
      <FormFieldContainer className="form-field-container">
        <label htmlFor={field.fieldLabel}>
          {field.fieldLabel}
          {field.fieldRequired && "*"}
        </label>
        <div className="custom-select">
          <select
            id={field.fieldName}
            placeholder={field.fieldPlaceholder}
            {...register(field.fieldName, {
              required: field.fieldRequired ? true : false,
              min: field.fieldType === "number" ? 1000000 : 0,
              pattern:
                field.fieldType === "email"
                  ? {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    }
                  : undefined,
            })}
          >
            <option value="">--Please Select--</option>
            {field.optionList.map((option: any, key: number) => (
              <option key={key} value={option.value}>
                {option.title}
              </option>
            ))}
          </select>
        </div>
      </FormFieldContainer>
      {field.conditionalFields &&
        field.conditionalFields.map((conditionalField: any, index: number) => {
          if (
            selectedValue === conditionalField.selectOptionValue &&
            conditionalField.fieldType !== "select"
          ) {
            return (
              <FormFieldContainer key={index} className="form-field-container">
                <label htmlFor={conditionalField.fieldLabel}>
                  {conditionalField.fieldLabel}
                  {conditionalField.fieldRequired && "*"}
                </label>
                <input
                  type={conditionalField.fieldType}
                  id={conditionalField.fieldName}
                  placeholder={conditionalField.fieldPlaceholder}
                  {...register(conditionalField.fieldName, {
                    // onChange: (e) => (formData[field.fieldName] = e.target.value),
                    required: conditionalField.fieldRequired ? true : false,
                  })}
                />
                {errors[conditionalField.fieldName] && (
                  <span>{conditionalField.fieldLabel} is required</span>
                )}
              </FormFieldContainer>
            );
          }
          return null;
        })}
    </div>
  );
};

export default FieldSelect;
