import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import LoaderComponent from "../../components/Loader/LoaderComponent";
import { Tile, Seo, Button } from "../../components";
import client from "../../client";
import { SEARCH_QUERY } from "../../utils/globalFunctions";
import { SearchContainer } from "./Search.style";
import { TileListingContainer } from "../../components/TileListing/TileListing.style";
const Search = () => {
  const params: any = useParams();
  const searchTerm = params.searchTerm;
  const [results, setResults]: any = useState();
  const [isLoading, setIsLoading] = useState(false);
  //   const [newSearchTerm, setNewSearchTerm] = useState(searchTerm);
  const [mediaElementIndex, setMediaElementIndex] = useState(8);
  const [machineryElementIndex, setMachineryElementIndex] = useState(8);

  const loadMoreMedia = () => {
    setMediaElementIndex(mediaElementIndex + mediaElementIndex);
  };
  const loadMoreMachinery = () => {
    setMachineryElementIndex(machineryElementIndex + machineryElementIndex);
  };

  const sortByType = (data: any) => {
    const result: any = {};

    data.forEach((item: any) => {
      if (!result[item._type]) {
        result[item._type] = [];
      }
      result[item._type].push(item);
    });
    return result;
  };
  function sortByName(arr: any, searchTerm: any, type: string) {
    if (type === "post") {
      return arr.sort((a: any, b: any) => {
        const distanceA = similarity(searchTerm, a.title);
        const distanceB = similarity(searchTerm, b.title);
        return distanceB - distanceA;
      });
    }
    if (type === "machinery") {
      return arr.sort((a: any, b: any) => {
        const distanceA = similarity(searchTerm, a.productName);
        const distanceB = similarity(searchTerm, b.productName);
        return distanceB - distanceA;
      });
    }
  }

  function similarity(a: any, b: any) {
    // let similarity = 0;
    // for (let i = 0; i < a.length && i < b.length; i++) {
    //   if (a[i] === b[i]) {
    //     similarity++;
    //   }
    // }
    // return similarity;
    return b.toLowerCase().includes(a.toLowerCase()) ? 1 : 0;
  }

  useEffect(() => {
    if (searchTerm) {
      setIsLoading(true);
      const fetchResults = async () => {
        const params = { searchTerm };
        const res = await client.fetch(SEARCH_QUERY, params);
        let sortedData = sortByType(res);
        setResults(sortedData);
      };
      fetchResults().then(() => {
        setIsLoading(false);
      });
    }
  }, [searchTerm]);

  if (isLoading) {
    return <LoaderComponent />;
  }
  let slicedPostList;
  if (results?.post) {
    slicedPostList = results?.post.slice(0, mediaElementIndex);
  }
  let slicedMachineryList;
  if (results?.machinery) {
    slicedMachineryList = results?.machinery.slice(0, machineryElementIndex);
  }

  return (
    <SearchContainer>
      <Seo
        keyword={"Search"}
        title={"Search Page"}
        description={"Search Page"}
      />
      <div className="search-list-container max-w-7xl mx-auto px-4">
        <div className="tablet:flex-row flex-col flex justify-between tablet:items-center">
          <div className="max-w-[830px] w-full tablet:w-auto tablet:py-16 pt-16 pb-4 tablet:flex-1 pr-4">
            {/* <BasicSearch
              newSearchTerm={newSearchTerm}
              setNewSearchTerm={setNewSearchTerm}
            /> */}
          </div>
          <p className="total-results">
            Showing{" "}
            {(results?.post?.length > 0 ? results?.post?.length : 0) +
              (results?.machinery?.length > 0
                ? results?.machinery?.length
                : 0)}{" "}
            results
          </p>
        </div>
        <h4 className="search-titles">Machinery</h4>
        {results?.machinery && results?.machinery?.length > 0 ? (
          <div className="tile-listing-wrapper">
            <TileListingContainer>
              {sortByName(slicedMachineryList, searchTerm, "machinery").map(
                (media: any, key: number) => {
                  // * This is working BUT the issue: Breaking the layout on tablet and phone screen
                  return (
                    <Tile
                      key={key}
                      isSearch
                      category={
                        media.machinerySubcategory.mainCategory.slug.current
                      }
                      subcategory={media.machinerySubcategory.slug.current}
                      data={media}
                      type={"machineryProduct"}
                    />
                  );
                }
              )}
            </TileListingContainer>
            {machineryElementIndex < results?.machinery?.length && (
              <div className="view-more-btn-container">
                <Button
                  text="View more +"
                  type="primary"
                  className="view-more-btn"
                  onClick={loadMoreMachinery}
                />
              </div>
            )}
          </div>
        ) : (
          <p className="font-general-sans py-8">No Result</p>
        )}
        <h4 className="search-titles">Articles</h4>
        {slicedPostList?.length > 0 ? (
          <div className="tile-listing-wrapper">
            <TileListingContainer>
              {sortByName(slicedPostList, searchTerm, "post").map(
                (media: any, key: number) => {
                  // * This is working BUT the issue: Breaking the layout on tablet and phone screen
                  return <Tile key={key} data={media} type={"blog"} />;
                }
              )}
            </TileListingContainer>
            {mediaElementIndex < results?.post?.length && (
              <div className="view-more-btn-container">
                <Button
                  text="View more +"
                  type="primary"
                  className="view-more-btn"
                  onClick={loadMoreMedia}
                />
              </div>
            )}
          </div>
        ) : (
          <p style={{ marginBottom: "2rem" }}>No Result</p>
        )}
      </div>
    </SearchContainer>
  );
};

export default Search;
