import styled from "styled-components";
import leftArrow from "../../assets/images/left-arrow.png";
import rightArrow from "../../assets/images/right-arrow.png";
import device from "../../utils/deviceScreenSizes";

export const TestimonialsContainer = styled.div`
  @media ${device.tablet} {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  min-height: 490px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.colors.lightBlue};
  padding-top: 4rem;
  padding-bottom: 4rem;
  .testimonial-section {
    color: ${(p) => p.theme.colors.primary};
    text-align: center;
    h2 {
      font-size: 3rem;
      margin-bottom: 3rem;
      font-weight: 500;

      @media ${device.mobileL} {
        font-size: 2.5rem;
        margin-bottom: 1rem;
      }
    }
    .slick-track {
      display: flex;
    }
    .slick-slide {
      height: inherit !important;

      & > div {
        height: 100%;
      }
    }
    .slick-prev {
      left: 0;
      top: 0;
      transform: translate(-50%, 300%);
      z-index: 10;
    }
    .slick-next {
      right: 0;
      top: 0;
      transform: translate(50%, 300%);
      z-index: 10;
    }
    .slick-prev:before {
      /* background-color: blue !important; */
      width: 20px;
      height: 20px;
      content: "" !important;
      position: absolute;
      background-image: url(${leftArrow});
      background-repeat: no-repeat;
      vertical-align: middle;
      background-size: contain;
      opacity: 1 !important;
      top: 28%;
      left: 32%;
    }
    .slick-next:before {
      /* background-color: blue !important; */
      width: 20px;
      height: 20px;
      content: "" !important;
      position: absolute;
      background-image: url(${rightArrow});
      background-repeat: no-repeat;
      vertical-align: middle;
      background-size: contain;
      opacity: 1 !important;
      top: 28%;
      left: 32%;
    }
  }
`;

export const QuoteContainer = styled.div`
  max-width: 300px;
  background: white;
  border-radius: 2.5px;
  border: 1px solid #bdbdbd;
  margin: 0 auto;
  padding: 1rem;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #4f4f4f;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  img {
    padding-top: 0.5rem;
    width: 266px;
    height: 75px;
  }
  .quote-title {
    font-weight: 600;
    color: #4f4f4f;
    margin-block: 1rem;
    font-size: 1.25rem;
  }
  .info {
    font-size: 1.25rem;
    font-weight: 300;
    margin-top: auto;
  }

  .quote-content {
    margin-bottom: 1.5rem;
    /* font-style: italic; */
    font-weight: 500;

    @media ${device.tablet} {
      font-size: 1.5rem;

      @media ${device.mobileL} {
        font-size: 1.2rem;
        line-height: 32px;
      }
    }
  }
  @media ${device.mobileL} {
    font-size: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const TestimonialArrows = styled.div`
  display: block;
  opacity: 0.5;
  width: 48px;
  height: 48px;
  background: ${(p) => p.theme.colors.primary};
  transition: 0.3s all ease;

  &:hover {
    /* background: ${(p) => p.theme.colors.secondary}; */
    background: ${(p) => p.theme.colors.primary};
    opacity: 1;
  }
`;
