import styled from "styled-components";
import device from "../../utils/deviceScreenSizes";

export const TileListingContainer = styled.div`
  display: grid;
  grid-gap: 40px 16px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  @media ${device.tablet} {
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  @media ${device.mobileL} {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const TileListingWrapper = styled.div`
  .tile-listing-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
    h2 {
      padding-bottom: 3rem;
      text-align: center;
      font-size: ${(props) => props.theme.fontSizes.large};
      color: ${(props) => props.theme.colors.primary};
      max-width: 800px;
      margin: 0 auto;
      font-weight: 500;
      text-align: center;

      @media ${device.tablet} {
        font-size: 2.5rem;

        @media ${device.mobileL} {
          font-size: 2rem;
        }
      }
    }
  }
`;
