import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Button/Button";
import { FormContainer } from "./Forms.style";
import { FormFieldContainer } from "../FormField/FormField.style";
import LoaderComponent from "../Loader/LoaderComponent";
import { FaPaperPlane } from "react-icons/fa";
import { useToken } from "../../utils/customHooks";

interface IForms {
  className?: string;
  data?: any;
  campaignTitle: string;
  onSubmit?: (data: any) => void;
}
interface MyWindow extends Window {
  dataLayer: any;
}
declare let window: MyWindow;
const Forms: FC<IForms> = (props: IForms) => {
  const getCurrentUrlWithoutProtocol = () => {
    var currentUrl = window.location.href;
    return currentUrl.replace(/^https?:\/\//i, "");
  };
  let currentUrl = getCurrentUrlWithoutProtocol();

  const { token } = useToken();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ mode: "all" });
  const onSubmit = (dataForm: any) => {
    setIsSubmitting(true);
    dataForm.url = currentUrl;
    let commentContent: any = `Campaign Name: ${props.campaignTitle} \n`;
    for (var key in dataForm) {
      if (dataForm.hasOwnProperty(key)) {
        commentContent += `${key} : ${dataForm[key]} \n`;
      }
    }
    let newData: any = {
      email: dataForm.email ? dataForm.email : "",
      firstname: dataForm.firstname ? dataForm.firstname : "",
      lastname: dataForm.lastname ? dataForm.lastname : "",
      memo: commentContent,
      phone: dataForm.phone ? dataForm.phone : "",
      website: "1",
      custbody_esc_campaign_category: -4,
      custbodyabco_campaign_name: props.campaignTitle,
      url: currentUrl,
      customerType: dataForm.customerType === "Private" ? 1 : 2,
      requestDemo: dataForm.requestDemo ? true : false,
    };

    if (token !== null) {
      if (token["utm_campaign"]) {
        newData["custbodyabco_campaign_name"] = token["utm_campaign"];
        newData["custbody_esc_campaign_category"] = -11;
      }
      if (token["gclid"]) {
        newData["custbody_esc_campaign_category"] = -15;
      }
    }

    fetch(
      // "https://6763524-sb2.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524_SB2&h=feb67c19fa1747d29813&action=createOpportunity",
      "https://6763524.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524&h=8daaa23e3101d43855eb&action=createOpportunity",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "User-Agent":
            "Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36",
        },
        body: JSON.stringify(newData),
      }
    )
      .then((res) => {
        setIsSubmitting(false);
        setIsSubmitted(true);
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "form_submission",
            form_name: "download-form",
          });
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <FormContainer className={props.className}>
      <h2>Get in touch with our team today</h2>
      <p>Fill out the form below and we will contact you shortly</p>
      {!isSubmitting && !isSubmitted ? (
        <form className="campaign-form" onSubmit={handleSubmit(onSubmit)}>
          <FormFieldContainer className="form-field-container">
            <label htmlFor="firstname">First Name*</label>
            <input
              type="text"
              placeholder="John"
              {...register("firstname", {
                required: true,
              })}
            />
            {errors["firstname"] && <span>First Name is required</span>}
          </FormFieldContainer>
          <FormFieldContainer className="form-field-container">
            <label htmlFor="lastname">Last Name*</label>
            <input
              type="text"
              placeholder="Doe"
              {...register("lastname", {
                required: true,
              })}
            />
            {errors["lastname"] && <span>Last Name is required</span>}
          </FormFieldContainer>
          <FormFieldContainer className="form-field-container">
            <label htmlFor="company">Company*</label>
            <input
              type="text"
              placeholder="Abco Products"
              {...register("company", {
                required: true,
              })}
            />
            {errors["company"] && <span>Company is required</span>}
          </FormFieldContainer>
          <FormFieldContainer className="form-field-container">
            <label htmlFor="role">Role*</label>
            <input
              type="text"
              placeholder="Manager"
              {...register("role", {
                required: true,
              })}
            />
            {errors["role"] && <span>Role is required</span>}
          </FormFieldContainer>
          <FormFieldContainer className="form-field-container">
            <label htmlFor="email">Email Address*</label>
            <input
              type="email"
              placeholder="example@address.com"
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
            />
            {errors["email"] && (
              <>
                {errors["email"].type === "min" ? (
                  <span>Email must be 7 or more digits</span>
                ) : errors["email"]?.message ? (
                  <span>Email is not valid</span>
                ) : (
                  <span>Email is required</span>
                )}
              </>
            )}
          </FormFieldContainer>
          <FormFieldContainer className="form-field-container">
            <label htmlFor="phone">Contact number*</label>
            <input
              type="number"
              placeholder="61 + 0000 000 000"
              {...register("phone", {
                required: true,
                min: 1000000,
              })}
            />
            {errors["phone"] && <span>Contact Number is required</span>}
          </FormFieldContainer>
          <FormFieldContainer className="form-field-container">
            <label htmlFor={"states"}>States*</label>
            <div className="custom-select">
              <select
                id={"states"}
                placeholder={"States"}
                {...register("states", {
                  required: true,
                })}
              >
                <option value="">--Please Select--</option>

                <option value="New South Wales">New South Wales</option>
                <option value="Northern Territory">Northern Territory</option>
                <option value="Queensland">Queensland</option>
                <option value="South Australia">South Australia</option>
                <option value="Tasmania">Tasmania</option>
                <option value="Victoria">Victoria</option>
                <option value="Western Australia">Western Australia</option>
              </select>
              <span className="custom-arrow"></span>
            </div>
            {errors["states"] && <span>States is required</span>}
          </FormFieldContainer>

          <FormFieldContainer className="form-field-container">
            <label htmlFor={"customerType"}>Customer Type</label>
            <div className="custom-select">
              <select
                id="customerType"
                placeholder="Customer Type"
                {...register("customerType", {
                  required: true,
                })}
              >
                <option value="">--Please Select--</option>
                <option value={"Private"}>Private Customer</option>
                <option value={"Business"}>Business Customer</option>
              </select>
              <span className="custom-arrow"></span>
            </div>
            {errors.customerType && <span>Customer Type is required</span>}
          </FormFieldContainer>
          <FormFieldContainer className="form-field-container">
            <div className="checkbox">
              <input
                type="checkbox"
                id="requestDemo"
                {...register("requestDemo")}
              />
              <label htmlFor="requestDemoCallback">
                Request Demo or Call Back
              </label>
            </div>
          </FormFieldContainer>
          <FormFieldContainer className="form-field-container">
            <label htmlFor="message">Message</label>
            <input
              type="text"
              placeholder="Your message"
              {...register("message")}
            />
          </FormFieldContainer>
          <Button text="Submit" type="secondary" className="campaign-submit">
            <FaPaperPlane style={{ marginLeft: "1rem" }} />
          </Button>
        </form>
      ) : (
        <LoaderComponent />
      )}
      {isSubmitted && (
        <div>
          <div className="thank-you-step">
            <h1>Thank You</h1>
            <h2>We'll be in touch Shortly</h2>
          </div>
        </div>
      )}
    </FormContainer>
  );
};

export default Forms;
