import { useState, useEffect, useRef } from "react";
import { Container } from "../../Theme/globalStyle";
import abcoLogo from "../../assets/images/abco-logo2.png";
import {
  OnlineAccountApplicationContainer,
  OnlineAccountFormContainer,
} from "./OnlineAccountApplication.style";
import { sanityFetchOnlineApplication } from "../../utils/globalFunctions";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { useForm } from "react-hook-form";
import { Button, Seo } from "../../components";
import { FaPaperPlane, FaPlus } from "react-icons/fa";
import SignatureCanvas from "react-signature-canvas";
// import jsPDF from "jspdf";
import TermsAndConditions from "./TermsAndConditions";
import SignatureTabs from "./SignatureTabs";
import html2pdf from "html2pdf.js";
import FieldSelect from "./FieldSelect";
import { useHistory } from "react-router-dom";
import CustomFormField from "./CustomFormField";
import FieldCheckBox from "./FieldCheckBox";

const OnlineAccountApplication = () => {
  const history = useHistory();
  const [data, setData] = useState<any>([]);
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);
  // const [htmlForm, setHtmlForm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [checkedStatus, setCheckedStatus]: any = useState({});
  const [canvasEmpty, setCanvasEmpty] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isDrawingMode, setIsDrawingMode] = useState(true);
  const [typedSignature, setTypedSignature] = useState("");
  const [shownButtonsIndices, setShownButtonsIndices] = useState<Set<number>>(
    new Set()
  );
  const [addClicked, setAddClicked] = useState(false);
  // const [htmlForm, setHtmlForm] = useState("");
  const [selectedFont, setSelectedFont] = useState({
    value: "Pacifico",
    label: "Pacifico",
  });

  const handleAccept = () => {
    console.log("Terms and conditions accepted.");
    setAcceptedTerms(!acceptedTerms);
  };
  useEffect(() => {
    const signatureProvided = !canvasEmpty || typedSignature !== "";
    setButtonDisabled(!(acceptedTerms && signatureProvided));
  }, [canvasEmpty, acceptedTerms, typedSignature]);

  const handleCanvasChange = () => {
    if (sigCanvasRef.current) {
      setCanvasEmpty(sigCanvasRef.current.isEmpty());
    }
  };

  const clearSignature = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
      setCanvasEmpty(true);
    }
    setTypedSignature("");
  };

  function formatData(data: any, formSchema: any) {
    let formattedData: any = [];

    formSchema.formSet.forEach((formFieldSet: any) => {
      let formSetData: any = {};
      formFieldSet.formFieldList.forEach((formField: any) => {
        if (formField._type === "formField") {
          const fieldName = formField.fieldName;
          if (data[fieldName]) {
            formSetData[fieldName] = {
              label: formField.fieldLabel,
              value: data[fieldName],
            };
          }

          if (formField.conditionalFields) {
            formField.conditionalFields.forEach((conditionalField: any) => {
              const conditionalFieldName = conditionalField.fieldName;
              if (data[conditionalFieldName]) {
                formSetData[conditionalFieldName] = {
                  label: conditionalField.fieldLabel,
                  value: data[conditionalFieldName],
                };
              }
            });
          }
        }
      });
      formattedData.push({ [formFieldSet.title]: formSetData });
    });

    return formattedData;
  }

  const generatePDF = async (formData: any) => {
    // Create a new jsPDF instance
    let formatted = formatData(formData, data.dynamicFormBuilder2);
    // const pdf = new jsPDF();

    const response = await fetch(abcoLogo);
    const imgContent = await response.blob();
    let applicantName = "";
    let customerNumber = "";
    let tradingName = "";
    let signeeEmail = "";
    let signatureDataURL = "";
    if (sigCanvasRef.current) {
      const signatureCanvas = sigCanvasRef.current.getCanvas();
      signatureDataURL = signatureCanvas.toDataURL("image/png");
    }

    // Generate form data HTML
    const formDataHTML = Object.values(formatted)
      .map((section: any) => {
        const sectionTitle = Object.keys(section)[0];
        const fields = section[sectionTitle];
        console.log("fields", fields);
        const validFields = Object.values(fields).filter(
          (field: any) =>
            field.label && field.value !== undefined && field.value !== ""
        );
        const fullNameField: any = validFields.find((field: any) => {
          return field.label === "Applicant Full Name";
        });
        const customerNumberField: any = validFields.find((field: any) => {
          return field.label === "Abco Customer Number";
        });
        const tradingNameField: any = validFields.find((field: any) => {
          return field.label === "Trading Name";
        });
        const signeeEmailField: any = validFields.find((field: any) => {
          return field.label === "Applicant Email";
        });
        if (fullNameField) {
          applicantName = fullNameField.value;
        }
        if (signeeEmailField) {
          signeeEmail = signeeEmailField.value;
        }
        if (customerNumberField) {
          customerNumber = customerNumberField.value;
        }
        if (tradingNameField) {
          tradingName = tradingNameField.value;
        }
        if (validFields.length === 0) return null;

        const fieldHTML = validFields
          .map(
            (field: any) => `
              <div class='formfield-container'><p style="max-width:250px" class='label'>${field.label}</p> <p class='value'>${field.value}<p></div>
            `
          )
          .join("");

        return `
          <div class='formset-container'>
            <h2 class='section-title'>${sectionTitle}</h2>
            ${fieldHTML}
          </div>
        `;
      })
      .join("");

    const termsAndConditionsHTML = `
			<div class='terms-conditions'>
    <div class="terms-and-conditions">
        <div class="terms-container" onScroll={handleScroll} ref={termsRef}>
            <h4>TERMS AND CONDITIONS</h4>
            <p class="bold">GENERAL</p>
            <p class="bold">Application</p>
            <ol>
                <li>
                    These Terms and Conditions (‘T&C’) apply to all contracts between Abco Products Pty Ltd (ACN 147 347 019) (‘Abco’) and the customer (‘Customer’) of any:
                    <ol>
                        <li>Goods or products <span class="bold">(‘Goods’)</span>; or</li>
                        <li>Rent of products <span class="bold">(‘Rental’)</span>; or</li>
                        <li>Service agreement <span class="bold">(‘Service’)</span>; or</li>
                        <li>Training service <span class="bold">(‘Training’)</span>; or</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Interpretation</span>
                    In these T&C:
                    <ol>
                        <li><span class="bold">ACL</span> means the Australian Consumer Law which is contained in Schedule 2 of the Competition and Consumer Act 2010 (Cth);</li>
                        <li><span class="bold">Business Day</span> means a day other than a Saturday, Sunday or Public Holiday in the State or Territory where the Goods and Services are supplied.</li>
                        <li><span class="bold">CCA</span> means the Competition and Consumer Act 2010 (Cth);</li>
                        <li><span class="bold">Customer</span> means a person who acquires Goods and Services from Abco;</li>
                        <li><span class="bold">Customer Order</span> means any order or instruction given by the Customer to Abco for the supply of Goods and Services, including, without limitation, any document entitled “Purchase Order” or any other document containing the description of the required Goods and Services, to be supplied by Abco to the Customer;</li>
                        <li><span class="bold">Dollars</span> means Australian Dollars (AUD$);</li>
                        <li><span class="bold">Event of Default</span> means the occurrences set out in clause 50;</li>
                        <li><span class="bold">Goods and Services</span> means any item or service of whatsoever nature supplied or to be sold or rented by Abco to the Customer;</li>
                        <li><span class="bold">GST</span> means the tax payable on certain goods within the meaning of the A New Tax System (Goods and Services Tax) Act 1999 (Cth);</li>
                        <li><span class="bold">Metropolitan Area</span> means the area which, in Abco’s opinion, is within the metropolitan area of Adelaide, Brisbane, Melbourne, Perth or Sydney.</li>
                        <li><span class="bold">Special Order</span> means items that are not normally kept in stock by Abco and may be subject to additional costs.</li>
                        <li><span class="bold">Payment</span> means any payment by the Customer for Goods and Services.</li>
                        <li><span class="bold">Payment Terms</span> means Terms that have been agreed with Abco.</li>
                        <li><span class="bold">PPSA</span> means the Personal Property Securities Act 2009 (Cth);</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Formation of contract</span>
                    <ol>
                        <li>Abco expressly rejects and is not bound nor is any contract formed between the parties by any conditional order made by the Customer.</li>
                        <li>A binding contract is formed when Abco receives each and any Purchase Order and either sends a written acceptance to the Customer or supplies the Goods and Services pursuant to a Purchase Order, whichever occurs first and that contract will be bound by these T&C.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Orders and prices</span>
                    <ol>
                        <li>Abco reserves the right to accept or decline, in whole or in part, any Purchase Order for Goods and Services placed by the Customer.</li>
                        <li>The Customer waives any claim for shortage of any Goods and Services supplied by Abco if the Customer has not lodged a written claim with Abco within 7 days from the date of receipt of the Goods and Services by the Customer.</li>
                        <li>Unless otherwise stated, all prices for Goods and Services are exclusive of all applicable taxes and charges. Where the Goods and Services supplied are subject to GST, the Customer is liable to pay any applicable amount of GST at the same time as Payment is made.</li>
                        <li>The prices of any Goods and Services and any related specifications, are indicated by the price and specifications contained on Abco’s website and/or any price-list, pamphlet or brochure or any information provided by Abco to the Customer and are subject to change at the absolute discretion of Abco without notice to the Customer. The final price shall be that contained in any final quotation or document supplied to the Customer in direct relation to the Goods and Services.</li>
                        <li>The Customer acknowledges and warrants that they are purchasing the Goods and Services for resale or commercial purposes and not for personal, household, or domestic use.</li>
                        <li>Orders must be made using Abco Code Number, as stated in the Pricelist of Abco or on the website of Abco. Abco will not accept responsibility for wrongly supplied products where codes are incorrect or not quoted when placing orders.</li>
                        <li>Goods and Services ordered are the responsibility of the Customer to ensure the correct order is placed. Abco will endeavour to clarify the order but will not be liable for any costs/damage whatsoever for incorrect orders being placed.</li>
                        <li>Orders placed for collection by the Customer will be returned to stock if not collected within 60 days of being notified the order is ready for collection. Any orders returned to stock will, where the Customer has paid, will attract a credit but no refund will be offered. A restocking fee may be charged and deducted from any credit given.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Delivery</span>
                    <ol>
                        <li>All orders are subject to delivery fees payable by the Customer, unless agreed otherwise by Abco.</li>
                        <li>Abco will endeavour to dispatch all Goods and Services within any timeframe stipulated in a Purchase Order; however, it will not be responsible for any loss or damage arising directly or indirectly from the transit of the Goods and Services arranged by the Customer or the receiver of the Goods and Services.</li>
                        <li>Any time or date provided by Abco for completion, delivery, and/or dispatch is an estimate only and does not constitute a representation, or term of the contract, nor shall it be part of the description of the Goods and Services and is not of the essence of the contract. All branded pallets remain the responsibility of the Customer and the freight company.</li>
                        <li>Pallets must be returned to Abco or exchanged in the same condition as received. Chep or Loscam pallets not returned will have a replacement cost charged to the Customer as a debt owing to Abco.</li>
                        <li>Abco is deemed to have delivered the Goods and Services (whichever occurs first) when they are:
                            <ol>
                                <li>collected by the Customer; or</li>
                                <li>made available for unloading at the Customer’s nominated delivery address.</li>
                            </ol>
                        </li>
                        <li>Deliveries outside the Metropolitan area, unless at the Customer’s expense, will be by carrier selected by Abco. At the sole discretion of Abco, the Customer may nominate a preferred carrier.</li>
                        <li>The Customer is liable to pay for deliveries outside the Metropolitan area subject to the application of the published freight rate or as agreed between Abco and the Customer.</li>
                        <li>It is the Customer’s responsibility to ensure the Goods and Services are suitable and sufficient for the Customer’s purpose. Abco bears no liability for incorrect selection by the Customer and does not warrant that the Goods and Services are suitable for the Customer’s purpose.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Payment</span>
                    <ol>
                        <li>Time for Payment for Goods and Services supplied by Abco is of the essence.</li>
                        <li>Unless otherwise agreed in writing by Abco, Payment for the Goods and Services must be made by the Customer in accordance with invoices issued by Abco to the Customer. Abco may, at its absolute discretion, require the Customer to pay a deposit. Where a deposit is required, this must be paid by the Customer prior to supply by Abco.</li>
                        <li>Where no payment terms have been agreed in writing by Abco, payment in full will be required prior to the dispatch of any Goods and Services except where only a Service is provided, payment will be due and payable in full at the completion of the Service.</li>
                        <li>At the end of each month, the Customer will receive a statement of account (<span class="bold">Statement</span>), which indicates the total amount payable. Subject to the agreed Payment Terms between the parties, the balance of the <span class="bold">Statement</span> is payable in full and shall only be treated as being paid once the Payment has been received as cleared funds by Abco.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Billing</span>
                    <ol>
                        <li>The Customer acknowledges and agrees that where a quote is requested, a quotation fee may be payable if the job does not proceed.</li>
                        <li>The Customer acknowledges and agrees there may be additional fees payable in addition to the quoted price, including but not limited to sundries and environmental levies.</li>
                        <li>The Customer acknowledges and agrees that additional charges, including but not limited to freight delivery, may be payable for non-stock items.</li>
                        <li>The Customer acknowledges and agrees there may be additional charges incurred for the assembly of Goods and Services purchased.</li>
                        <li>Where the Customer has provided budget data, Abco will not be liable for any errors in the Customer’s budget data, and the Customer acknowledges and agrees that where their order exceeds their budget, Abco will not be liable for the excess and agrees to pay Abco the invoiced amount.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Risk and retention of title</span>
                    <ol>
                        <li>Unless otherwise provided in these T&C, the risk in the Goods and Services supplied by Abco will pass to the Customer on delivery of the Goods and Services.</li>
                        <li>The Customer agrees that title to Goods and Services is retained by Abco:
                            <ol>
                                <li>until it receives Payment in full for the Goods and Services; or</li>
                                <li>at all times for Goods and Services that are rented; and</li>
                                <li>until all other monies owing by the Customer to Abco are paid in full.</li>
                            </ol>
                        </li>
                        <li>Where the Goods and Services comprise a number of items, the title of Abco in all items comprising the Goods and Services will not pass to the Customer until Payment in full is received for all items and all other monies owed by the Customer have been paid in cleared funds.</li>
                        <li>Prior to title in the Goods and Services passing to the Customer, the Customer agrees that:
                            <ol>
                                <li>it acts as bailee of the Goods and Services until such time as title in them passes to the Customer and that this bailment continues for each item of the Goods and Services;</li>
                                <li>it must keep the Goods and Services in good and merchantable condition and fully insure the Goods and Services against loss or damage however caused;</li>
                                <li>it must not sell the Goods and Services except with the prior written consent of Abco;</li>
                                <li>it must not create any encumbrance over the Goods and Services which is inconsistent with Abco’s title and ownership of the Goods and Services.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <span class="bold">No sale by description</span>
                    <ol>
                        <li>All descriptions, specifications and illustrations or any other information in relation to the Goods and Services contained in catalogues, price lists or other advertising material of Abco or elsewhere are approximations only and intended by Abco to be a general description for information and identification purposes and do not create a sale by description.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Returns and cancellations</span>
                    <ol>
                        <li>Any cancelled Purchase orders, except Special Orders (Special Orders are not returnable), will result in the Customer being liable for the greater of a $30.00 processing fee or 10% of the order price for the costs of restocking. Special Orders cannot be cancelled or returned and the full amount is payable unless Abco exercises its sole discretion to waive or discount the amount payable. The Customer must bear any costs associated with the return of Goods and Services, including freight costs which must be paid by the Customer prior to returning the Goods or Services.</li>
                        <li>Except as provided otherwise by the ACL, returns of all Goods and Services are subject to prior approval of Abco at its absolute discretion upon a written request by the Customer made within 7 days from the date of delivery of Goods and Services. Goods must be unused, undamaged and in their original packaging and will be subject to inspection by Abco quality control team who will have the final say as to the condition of the Goods and Services.</li>
                        <li>Servicing of Goods and Services may require order of parts. Any specially ordered parts required for the service cannot be cancelled once ordered. Any service that is cancelled prior to completion will incur the costs of the portion of the job completed and the parts used or ordered.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Warranties and liability</span>
                    <ol>
                        <li>The Customer must inspect all Goods and Services immediately on delivery and must notify Abco in writing of any inaccuracies, short supply or fault, damage or defect in the goods or rental items within 7 days of the delivery. The Customer will be deemed to have accepted the Goods and Services thereafter.</li>
                        <li>If the Customer fails to notify Abco under clause 38, Abco will not be liable for any loss or damage arising out of or resulting from such inaccuracies or short supply of goods or rental items.</li>
                        <li>The Customer acknowledges that any manufacturer warranty may be voided if the manufacturer’s recommended maintenance programs on the goods or rental items are not complied with.</li>
                        <li>Certain guarantees cannot be excluded under the ACL. You are entitled to a replacement or refund for a major failure and compensation for any other reasonably foreseeable loss or damage. You are also entitled to have the goods repaired or replaced if the Goods and Services fail to be of acceptable quality and the failure does not amount to a major failure.</li>
                        <li>Abco makes no express warranties or representations in relation to the fitness for purpose of the Goods and Services.</li>
                        <li>Abco will not be liable in any way whatsoever for the consequence of any representation or conduct made in connection with the Goods and Services whether by its employees, agents or sub-contractors or otherwise to the Customer or any third parties in relation to fitness for purpose. The Customer agrees that all such representations and/or advice are/is accepted or relied upon by the Customer entirely at the Customer’s risk.</li>
                        <li>Abco’s liability to the Customer or any third parties (whether arising under statute, contract, tort (including negligence), equity or otherwise) for any defect in the Goods and Services, or the quality of the Goods and Services, will in all cases be limited to the cost of repair or replacement of such Goods and Services.</li>
                        <li>Subject to clause 47, to the extent permitted by statute, all warranties, T&C and guarantees (whether express, implied or applied, and whether given by Abco, the manufacturer or a third party) pertaining to or in connection with the Goods and Services and any obligation of Abco to replace or refund any Goods and Services again are excluded.</li>
                        <li>Abco will not be liable for the following:
                            <ol>
                                <li>any consequential loss or damage of any kind suffered by the Customer as a result of the supply of the Goods and Services;</li>
                                <li>any loss of profits, loss of anticipated profits, loss of business, loss of reputation or goodwill, business interruption of the Customer;</li>
                                <li>failure of Abco to perform any of its obligations if such a delay or failure is due to anything beyond Abco’s reasonable control;</li>
                                <li>any losses caused directly or indirectly by any failure or breach by the Customer; and/or</li>
                                <li>any loss relating to the choice of the goods and how they meet the Customer’s purpose;</li>
                                <li>any losses caused by unavailability or outage of Abco’s website, inaccuracies in the Abco website or use of Abco’s website or App generally;</li>
                                <li>in any circumstances, any claim that exceeds the total amount of the price paid for the Goods and Services.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Indemnity and Waiver</span>
                    <ol>
                        <li>The Customer agrees to indemnify and forever hold Abco harmless from and against all liabilities, losses, damages, costs or expenses incurred or suffered by Abco, and from and against all actions, proceedings, claims or demands made against Abco, arising:
                            <ol>
                                <li>as a result of the Customer’s failure to comply with any laws, rules, standards or regulations applicable in relation to the Goods and Services or the use of the goods or rental items;</li>
                                <li>as a result of any other negligence or other breach of duty by the Customer in connection with the Goods and Services;</li>
                                <li>directly or indirectly from the Customer’s conduct; and/or</li>
                                <li>as a result of any breach of these T&C by the Customer.</li>
                            </ol>
                        </li>
                        <li>Any failure of Abco to enforce any of its rights under these T&C does not operate as a waiver of those rights.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Default and termination</span>
                    <ol>
                        <li>In the event of default by the Customer, Abco may terminate the Contract after providing 5 Business Days’ written notice to the Customer to remedy the default or breach.</li>
                        <li>Each of the following occurrences constitutes an event of default:
                            <ol>
                                <li>the Customer breaches or is alleged to have breached these T&C for any reason (including, but not limited to, defaulting on any Payment);</li>
                                <li>the Customer, being a natural person, commits an act of bankruptcy;</li>
                                <li>the Customer, being a corporation, is subject to:
                                    <ol>
                                        <li>a petition being presented, an order being made or a meeting being called to consider a resolution for the Customer to be wound up, deregistered or dissolved;</li>
                                        <li>a receiver, receiver and manager or an administrator under Part 5.3A of the Corporations Act 2001 (Cth) being appointed to all or any part of the Customer's property and undertaking; or</li>
                                        <li>the entering of a scheme of arrangement (other than for the purpose of restructuring);</li>
                                        <li>the Customer purports to assign its rights under these T&C without Abco's prior written consent;</li>
                                        <li>the Customer ceases/threatens to cease conduct of its business in the normal manner.</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>On the occurrence of an event of default, all invoices issued by Abco to the Customer will become immediately due and payable as a debt to Abco.</li>
                        <li>Where an event of default occurs, until Payment in full has been received by Abco, Abco may:
                            <ol>
                                <li>terminate any or all orders and credit arrangements (if any) with the Customer;</li>
                                <li>refuse to supply Goods and Services;</li>
                                <li>repossess any Goods and Services supplied to the Customer, the Payment for which has not been received;</li>
                                <li>retain (where applicable) all money paid on account of Goods and Services or otherwise;</li>
                                <li>demand interest on unpaid Payments at Commonwealth Bank maximum overdraft rate for sums up to $50,000.00 and such interest shall be recoverable by Abco as a debt from the Customer.</li>
                            </ol>
                        </li>
                        <li>Subject to clause 52 and the PPSA, if the Customer fails to pay any amount of the Customer’s Payment(s) when it/they is/are due to Abco, Abco may without notice and without prejudice to any of its other rights and remedies recover and/or resell the goods. For that purpose, the Customer:
                            <ol>
                                <li>authorises Abco (without prior notice to the Customer) to enter any premises where Goods and Services, the title in which remains in Abco (‘Abco’s Property’) may be situated and to use such reasonable efforts as may be required to enter any such premises and to take possession of Abco’s Property;</li>
                                <li>undertakes to Abco to procure the consent of all persons having any interest in the premises where Abco’s Property may be situated, to entry of those premises by Abco;</li>
                                <li>indemnifies and keeps indemnified Abco from all claims, actions or suits whatsoever out of or in connection with or in relation to the entry by Abco to any premises where Abco’s Property may be situated;</li>
                                <li>shall reimburse Abco for all costs and losses incurred by it in recovering and/or reselling Abco’s Property.</li>
                            </ol>
                        </li>
                        <li>If the Customer adds any parts or accessories to Abco’s Property, Abco shall become the owner of those added parts and accessories as security for full Payment by the Customer of all monies due to Abco for Abco’s Property and the term Abco’s Property for the purpose of this subclause shall be appropriately read in conjunction with these T&C and construed accordingly.</li>
                        <li>If the Customer substantially transforms Abco’s Property, Abco shall become the owner of those new Goods and Services or other goods as security for full Payment by the Customer of all monies due to Abco for Abco’s Property and the term Abco’s Property for the purpose of this subclause shall be appropriately read in conjunction with these T&C and construed accordingly.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Notice</span>
                    <ol>
                        <li>Where, pursuant to these T&C, a party is required to give notice to the other party, such notice must be in writing and it is deemed to have been given and served on a party:
                            <ol>
                                <li>if delivered personally, on the same day on which it was delivered;</li>
                                <li>if sent by post, it is taken to have been given on the day it would have been delivered in the ordinary course of post;</li>
                                <li>if sent by fax, at the time recorded on the transmitting machine;</li>
                                <li>if sent electronically, at the “time of dispatch” within the meaning of section 13 of the Electronic Transactions Act 2001 (WA).</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Compliance</span>
                    <ol>
                        <li>Abco acknowledges that where the ACL applies with respect to Goods and Services, certain guarantees and rights are provided by the CCA and ACL:
                            <ol>
                                <li>any rights provided to the Customer by the ACL or CCA that by statute cannot be excluded that are inconsistent with any term of these T&C are to prevail over any terms of these T&C to the extent of the inconsistency;</li>
                                <li>nothing in these T&C purports to derogate from or exclude any rights provided by the ACL or CCA that by statute cannot be excluded or modified.</li>
                            </ol>
                        </li>
                        <li>The Customer acknowledges and agrees that Abco may register a security interest in the Goods and Services at any time before or after delivery of the Goods and Services. The Customer waives its right under section 157 of the PPSA to receive notice of any verification of the registration.</li>
                        <li>If the Customer defaults in the performance of any obligation owed to Abco under these T&C, Abco may enforce its security interest in any Goods and Services by exercising all or any of its rights under these T&C or the PPSA.</li>
                        <li>The Customer must promptly do anything reasonably required by Abco including, but not limited to, executing documents to ensure that Abco’s security interest is a perfected security interest and has priority over all other security interests in the Goods and Services.</li>
                        <li>Nothing in clauses 62-64 is limited by any other provision of these T&C or any other agreement between the parties. If a term used in these clauses has a particular meaning in the PPSA, it has the same meaning in those clauses.</li>
                        <li>The Customer must comply with all laws, bylaws and statutory and regulating authority applicable to the Customer and in particular without limitation all those relating to the environment, safety, dangerous goods, storage, handling, insurance and sale of goods supplied by the Abco under these T&C and any Condition of Sale.</li>
                        <li>To the extent permitted by law, the Customer and Abco agree that the following provisions of the PPSA do not apply to the enforcement by Abco of its security interest in the Goods and Services: sections 95, 118, 121(4), 125, 130, 132(3)(d), 132(4), 135, 137, 142 and 143.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Recovery of costs</span>
                    <ol>
                        <li>Any expenses, costs or disbursements incurred by Abco in recovering any outstanding monies owing by the Customer, including debt collection fees and solicitor-client legal costs, must be paid by the Customer on a full indemnity basis.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Variations</span>
                    <ol>
                        <li>These T&C may be varied unilaterally by Abco at any time and in its sole discretion.</li>
                        <li>Any variations to these T&C will apply to every Purchase order made by the Customer and accepted by Abco after the expiry of 7 days’ notice of the variation to the Customer. Notwithstanding any other provision of these T&C, notice may be affected for the purpose of this clause by provision of the new T&C as varied or by publication on Abco’s website.</li>
                        <li>No variation to these T&C will apply with respect to any Purchase Order already made by the Customer before notice is given under clause 66.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Privacy and Credit Reporting</span>
                    <ol>
                        <li>Abco may collect information in relation to the Customer, for the purpose of providing the Goods and Services, in accordance with the laws relating to the collection and disclosure of personal information under the Privacy Act 1998 (Cth) as amended from time to time.</li>
                        <li>Where the Goods and Services are supplied on credit, the Customer irrevocably authorises Abco, its employees and/or agents to make such inquiries as are deemed necessary to investigate the Customer’s creditworthiness, including (without limiting) the making of inquiries from persons nominated as trade referees, bankers, or any other credit providers <span class="bold">(‘Information Sources’)</span> or credit reporting agencies, and the Customer hereby authorises the Information Sources to disclose such information to Abco.</li>
                        <li>Abco affirms that data collated in relation to the Customer, for the purpose of providing the Goods and Services will be stored in secure databases including but not limited to the Customer Abco Portal Database and Asset Data in accordance with the laws relating to the storage of personal information under the Privacy Act 1998 (Cth).</li>
                        <li>Abco may track the Goods and Services through live GPS tracking of the machines, as this allows Abco to collect data in relation to machine remote diagnostic information, and this data will only be used in accordance with the laws relating to the usage of such information under the Privacy Act 1998 (Cth), for its intended purpose.</li>
                        <li>If the Customer requests a custom feature be created on the Goods and Services, Abco becomes and remains at all times the exclusive owner of the intellectual property created from the implementation of the feature. The Customer grants the intellectual property rights to Abco created for the custom feature.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Force Majeure</span>
                    <ol>
                        <li>Abco will not be liable for any delay in or failure of performance of supply or supply of Goods and Services caused by circumstances beyond its control including, but not limited to, strikes/industrial disputes, lock-outs, labour disturbances, acts of government, pandemic, riot, civil war, commotion, fire, flood, failure of power supply or similar event and shall not be liable for any consequential loss, damage or claim suffered by the Customer or any other party as a result of such event.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Severance and Governing Law</span>
                    <ol>
                        <li>If a court of competent jurisdiction decides that any part of these T&C is invalid or unenforceable, then that part of the T&C will be modified (if possible) so as to make it enforceable. If it is cannot be modified, then it will be severed and the rest of the T&C will continue to operate.</li>
                        <li>This contract is governed by the laws of Western Australia.</li>
                        <li>Any legal action in relation to each contract against any party or its property may be brought in any court of competent jurisdiction in the state of Western Australia, and the parties submit to the jurisdiction of that state.</li>
                        <li>To the extent permitted by law, the Sale of Goods Act 1985 (WA) does not apply to any contract between Abco and the Customer.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">Entire Agreement</span>
                    <ol>
                        <li>These T&C will prevail over any terms, T&C, representations or understandings between the Customer and Abco in relation to the Goods and Services, whether or not any inconsistency arises.</li>
                        <li>Every contract for the supply of Goods and Services pursuant to any Purchase Order or Abco document containing the description of the Goods and Services incorporates these T&C and constitutes the entire agreement between Abco and the Customer. All prior negotiations, proposals, previous dealings, correspondence, trade custom and/or trade usage are superseded by and will not affect the interpretation of the Contract.</li>
                        <li>The Customer acknowledges, represents and warrants to Abco that in any decision or matter concerning or in connection with any Goods and Services it has relied solely on the terms expressed in these T&C and in writing in an Purchase Order for the supply of Goods and Services and has not relied on any prior negotiations, proposals, understandings, representations, or conduct of Abco or Abco’s employees or agents.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">ADDITIONAL SPECIFIC SECTIONS</span>
                    <ol>
                        <li><span class="bold">RENTAL</span></li>
                        <li>In relation to rental of the products, the Customer is the Lessee and Abco is the Lessor.</li>
                        <li>The hire of the products commences from the date specified in the Rental Agreement and continues until the equipment is in Abco’s possession and control and the equipment is serviceable and in good working condition.</li>
                        <li>Extension of the rental period will occur where:
                            <ol>
                                <li>agreed in writing by both parties; or</li>
                                <li>the Customer retains possession of the hire product(s) after the expiry of the agreed hire period, in which case the hire period will be extended for an additional month and renew for a further month at the expiry of each additional month’s rental until notice is provided by Abco in accordance with clause 84.</li>
                            </ol>
                        </li>
                        <li>The Customer agrees to return the equipment upon demand from Abco and the Rental Agreement may be terminated at any time by Abco by providing the Customer 5 Business Days’ notice.</li>
                        <li>The Customer must insure any Rental products or agree to pay Abco for such.</li>
                        <li>Rental Products returned in an unclean state will incur a cleaning fee.</li>
                        <li>Delivery and pick up will attract additional charges and the Customer acknowledges and agrees these charges may vary based on the delivery location and the product being delivered.</li>
                        <li>The Customer indemnifies and holds harmless Abco from any damage, injury or death caused by the operation of the Rental products.</li>
                        <li>Billing and billing cycles will be in accordance with Payment and Billing in these T&C with the exception that Payments for Rentals will only be via credit card or direct debit.</li>
                        <li>The customer accepts liability for the loss and damage of the Rental products and any subsequent loss of ongoing rental charges, irrespective of how the damage occurred, including general wear and tear of the Rental Products, while in the Customer’s possession and agrees to fully reimburse Abco for replacement value or cost of repair.</li>
                        <li>Abco does not provide any guarantee to the condition, safety or suitability of the Rental products supplied and will attempt to rectify faults where possible on the job sites. In cases of minor damage and cleaning, the Customer agrees to accept any additional charges incurred. Where there is a breakdown of the Rental products, the Customer releases Abco from any liability caused by the breakdown and Abco will attempt to repair or replace the Rental product as soon as practically possible. The Customer is liable for the cost of any new hired equipment not returned to Abco.</li>
                        <li>The Customer acknowledges and accepts that consumable items including but not limited to chemicals and brushes are not included in the Contract and will be incurred as an extra charge.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">SERVICE</span>
                    <ol>
                        <li>Abco will provide servicing of the Goods and Services as agreed between the parties.</li>
                        <li>The Customer acknowledges and agrees that the Goods and Services require routine and non-routine servicing and where the products are subject to Rental will enter into a Service Contract with Abco to perform routine and non-routine servicing.</li>
                        <li>The Service will be charged in 15-minute increments or part thereof plus a call-out fee (dependent on distance travelled). These units of charge may be adjusted by mutual agreement.</li>
                        <li>Abco will provide the Service with fully trained and competent technicians using genuine manufacturer parts. Manufacturer warranty will apply to replacement parts.</li>
                        <li>Abco’s liability for defective workmanship will be limited to providing the Service again to rectify the issue.</li>
                        <li>The Customer will ensure access to the Goods and Services products at the allocated Service time and will be responsible for the safety of Abco personnel. The Customer indemnifies and holds harmless Abco from any liability due to death, injury or damage caused to its personnel or equipment while performing the Service.</li>
                        <li>Billing and payment will be in accordance with Payment and Billing in these T&C.</li>
                    </ol>
                </li>
                <li>
                    <span class="bold">TRAINING</span>
                    <ol>
                        <li>Abco will provide training of the Customer’s personnel on the Goods and Services as agreed between the parties.</li>
                        <li>Where training is required, the Customer will enter into a Training Contract with Abco to perform the training as specified in the purchase order or quotation.</li>
                        <li>The Training cost will include travel, course duration, setup and pack down.</li>
                        <li>Abco will provide the Training with experienced and competent trainers.</li>
                        <li>The Customer will ensure access to the Goods and Services products at the allocated Training time and will be responsible for the safety of Abco personnel. The Customer indemnifies and holds harmless Abco from any liability due to death, injury or damage caused to its personnel or equipment while performing the Training.</li>
                        <li>Where training involves the issue of a certification under the National Training Framework, Abco will use a third party to provide the Registered Training Organisation authority. Students will be assigned a Unique Student Identifier <span class="bold">(‘USI’)</span> in order to comply with the Student Identifiers Act 2014 (Cth).</li>
                        <li>The USI is required to be collected and reported to government departments but the information used to create the USI is destroyed after the USI is created. For more information go to <a href="https://www.usi.gov.au" rel="noreferrer" target="_blank"> https://www.usi.gov.au </a>.</li>
                        <li>Billing and payment will be in accordance with Payment and Billing in these T&C.</li>
                    </ol>
                </li>
            </ol>
        </div>
    </div>
</div>


	  `;

    // Create a string to store the form data as HTML
    const formHTML = `
      		<style>
			.pdf-container, 
.formset-container,
.formfield-container,
.terms-container,
h2,
p,
li,
li li {
    page-break-inside: avoid;
}

				.pdf-container {
					font-family: Arial, sans-serif;
					padding: 20px;
				}
				.pdf-container .formset-container {
					page-break-inside: avoid;
					margin-bottom: 1rem;
				}
				.pdf-container .formfield-container {
					display: flex;
					margin-left: 1rem;
				}
				.pdf-container .formfield-container .label {
					min-width: 250px;
					font-weight: 700;
				}
				.pdf-container h2 {
					font-size: 16px;
					background: #1a428a;
					color: white;
					padding: 0.5rem;
					font-weight: 700;
				}

				.pdf-container .formdata p {
					font-size: 16px;
					color: black;
					margin-top: 0.3rem;
					margin-bottom: 0.3rem;
				}
				.pdf-container .note-container {
					max-width: 550px;
					margin-bottom: 2rem;
				}
				.pdf-container .note-container p:nth-of-type(1) {
					font-size: 20px;
					color: #1a428a;
					font-weight: 700;
				}
				.pdf-container .note-container p:nth-of-type(2) {
					line-height: 1.2rem;
				}

				.terms-conditions {
					page-break-before: always;
				}

ol, ul {
    margin: 0 0 1rem 1rem; /* Add margin for spacing */
    padding: 0; /* Remove default padding */
    list-style-position: inside; /* Ensure list numbers or bullets are inside */
}

ol ol, ul ul, ol ul, ul ol {
    margin-left: 1rem; /* Adjust margin for nested lists */
}

ol li, ul li {
    font-size: 12px; /* Set consistent font size */
    line-height: 1.5; /* Improve readability */
}


				.terms-and-conditions
				{
					padding: 3rem 3rem;
					}

				.pdf-container .terms-container {
					margin-top: 1rem;
					border-top: 1px solid #ddd;
					padding-top: 1rem;
				}
				.pdf-container .terms-container h4 {
					font-size: 14px;
					font-weight: bold;
					margin-bottom: 1rem;
				}
				.pdf-container .terms-container p {
					font-size: 12px;
					margin-bottom: 0.5rem;
				}
				.pdf-container .terms-container .bold {
					font-weight: bold;
				}
				.pdf-container .terms-container .italic {
					font-style: italic;
				}

				.logo {
					width: 150px;
					height: 50px;
					margin-bottom: 20px;
					margin-left: auto;
					margin-right: auto;
					text-align: center;
					margin-bottom: 1rem;
				}

				.pdf-container img.signature {
					width: 200px;
					height: 60px;
					margin-top: 20px;
					background: white;
					margin-left: auto;
					margin-right: auto;
				}
				.pdf-container .signature-container {
					max-width: 250px;
					margin-left: auto;
					text-align: center;
					margin-top: 5rem;
				}
				.pdf-container .applicant-name {
					border-top: 1px solid;
					margin-top: 0.5rem;
				}
				.font-selected {
					font-family: ${selectedFont.value};
					font-size: 30px !important;
				}
				</style>

				<div class="pdf-container">
				<div class="logo-container">
					<img class="logo" src="${URL.createObjectURL(imgContent)}" alt="Logo" />
				</div>
				<div class="note-container">
					<p>30 Day End Of Month Credit Application Form</p>
					<p>
					Note: Account will be C.O.D until application is fully processed and
					approved. Services are provided by <b>Abco</b> on the Terms and Conditions
					of Trade as annexed.
					</p>
				</div>
				<div class="formdata">
					${formDataHTML}
				</div>
				${termsAndConditionsHTML}
				</div>
				<div class="signature-container">
				${
          signatureDataURL
            ? `<img class="signature" src="${signatureDataURL}" alt="Signature" />`
            : `<p class='font-selected'>${typedSignature}</p>`
        }
				<p class="applicant-name">${applicantName}</p>
				<p class="applicant-name">${new Date().toString()}</p>
				</div>

    `;

    const tempElement = document.createElement("div");
    document.body.appendChild(tempElement);
    tempElement.innerHTML = formHTML;
    const opt = {
      margin: [0.2, 0.1, 0.7, 0.1], // Top, left, bottom, and right margins (in inches)
      filename: `Credit Application - ${customerNumber} - ${tradingName}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, logging: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    // setHtmlForm(formHTML);
    let worker = await html2pdf()
      .set(opt)
      .from(tempElement)
      .toPdf()
      .output("datauristring")
      .then(function (dataUri: any) {
        // This logs the right base64 data URI
        console.log(dataUri);
        // Extract the base64 string from the data URI
        let base64String = dataUri.split(",")[1];
        return base64String;
      })
      .catch(function (error: any) {
        console.error("Error generating PDF:", error);
      });

    html2pdf()
      .from(tempElement)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf: any) {
        var totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);

          // Adjust the footer line and text placement
          pdf.setDrawColor(150); // Set color for the line
          pdf.setLineWidth(0.01); // Set line width
          const lineYCoordinate = pdf.internal.pageSize.getHeight() - 0.6; // Moved the line up a bit
          pdf.line(
            0.5,
            lineYCoordinate,
            pdf.internal.pageSize.getWidth() - 0.6,
            lineYCoordinate
          ); // Draw line

          // Footer text - Page number and additional text
          pdf.setFontSize(10);
          pdf.setTextColor(100);
          const footerYCoordinate = pdf.internal.pageSize.getHeight() - 0.4; // Moved the text up a bit
          const pageNumberText = "Page " + i + " of " + totalPages;
          const additionalFooterText =
            " | Abco Products Pty Ltd - Confidential Document";

          // Calculate total text width and position the text in the center
          const pageNumberWidth =
            (pdf.getStringUnitWidth(pageNumberText) *
              pdf.internal.getFontSize()) /
            pdf.internal.scaleFactor;
          const additionalTextWidth =
            (pdf.getStringUnitWidth(additionalFooterText) *
              pdf.internal.getFontSize()) /
            pdf.internal.scaleFactor;
          const totalTextWidth = pageNumberWidth + additionalTextWidth;

          const startXCoordinate =
            (pdf.internal.pageSize.getWidth() - totalTextWidth) / 2;

          pdf.text(
            pageNumberText + additionalFooterText,
            startXCoordinate,
            footerYCoordinate
          );
        }

        return pdf.output("arraybuffer");
      })
      .then(function (byte: any) {
        fetch(
          // "https://6763524-sb2.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524_SB2&ns-at=AAEJ7tMQ78nFVV1hfo0N29vYjqvAXm5fL2AVK8GRSQDB97yO6VI&action=sendTermsAndConditionEmail",
          "https://6763524.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524&ns-at=AAEJ7tMQscoLnHY3TQgUcIofUDhsW0KGKedD0ocvSoJn0oybK3M&action=sendTermsAndConditionEmail",
          {
            method: "POST",
            mode: "no-cors",
            headers: {
              "Content-Type": "application/json",
              "User-Agent":
                "Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36",
            },
            body: JSON.stringify({
              attachment: worker,
              applicantEmail: signeeEmail,
              email: "creditors@abcopro.com.au",
              applicantName: `Credit Application - ${customerNumber} - ${tradingName}`,
              name: "Online Account Application",
            }),
          }
        )
          .then((response) => {
            console.log("PDF Blob saved:");
          })
          .catch(function (error: any) {
            console.error("Error sending PDF:", error);
          });
      })
      .catch(function (error: any) {
        console.error("Error generating PDF:", error);
      })
      .save();

    document.body.removeChild(tempElement);
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const res = await sanityFetchOnlineApplication();
      const initialCheckedStatus: any = {};
      res.dynamicFormBuilder2.formSet.forEach((formSet: any) => {
        if (formSet.isConditional) {
          initialCheckedStatus[formSet._key] = false;
        }
      });
      setCheckedStatus(initialCheckedStatus);
      setData(res);
    };
    fetchData().then(() => {
      setIsLoading(false);
    });
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm({ mode: "all" });
  const onSubmit = (dataForm: any) => {
    generatePDF(dataForm).then(() => {
      history.push("/thank-you");
    });
  };
  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Seo
        description={data.seo?.meta_description && data.seo.meta_description}
        keyword={data.seo?.focus_keyword && data.seo.focus_keyword}
        title={data.seo?.seo_title && data.seo.seo_title}
      />
      <Container>
        <OnlineAccountApplicationContainer>
          <div className="logo-container">
            <img src={abcoLogo} alt="Logo" />
          </div>
          <div className="note-container">
            <p>30 Day End Of Month Credit Application Form</p>
            <p>
              Note: Account will be C.O.D until application is fully processed
              and approved. Services are provided by <b>Abco</b> on the Terms
              and Conditions of Trade as annexed.
            </p>
          </div>
          <OnlineAccountFormContainer className="online-account-form-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              {data.dynamicFormBuilder2 &&
                data.dynamicFormBuilder2.formSet.map(
                  (formSet: any, index: number) => {
                    if (formSet.isConditional) {
                      return (
                        <div key={index}>
                          <div className="checkbox-container">
                            <input
                              id="checkbox"
                              type="checkbox"
                              name={formSet._key}
                              onChange={(e) =>
                                setCheckedStatus({
                                  ...checkedStatus,
                                  [formSet._key]: e.target.checked,
                                })
                              }
                            />
                            {formSet.titleDescription && (
                              <h4>{formSet.titleDescription}</h4>
                            )}
                          </div>

                          {!checkedStatus[formSet._key] &&
                            formSet.formFieldList.map(
                              (field: any, index: number) => {
                                if (field._type === "header") {
                                  if (field.type === "emphasis") {
                                    return (
                                      <h4 key={field._key}>{field.title}</h4>
                                    );
                                  }
                                  return (
                                    <h3 key={field._key}>{field.title}</h3>
                                  );
                                }
                                if (field.fieldType === "select") {
                                  const selectedValue = watch(field.fieldName);
                                  return (
                                    <FieldSelect
                                      selectedValue={selectedValue}
                                      field={field}
                                      index={index}
                                      isConditional={false}
                                      register={register}
                                      errors={errors}
                                    />
                                  );
                                }

                                return (
                                  <CustomFormField
                                    key={index}
                                    field={field}
                                    register={register}
                                    errors={errors}
                                  />
                                );
                              }
                            )}
                        </div>
                      );
                    }
                    return (
                      <>
                        {formSet.formFieldList.map(
                          (field: any, index: number) => {
                            if (
                              formSet.title === "Company Contact" &&
                              !addClicked
                            ) {
                              return null;
                            }
                            if (field._type === "header") {
                              if (field.type === "emphasis") {
                                return <h4 key={field._key}>{field.title}</h4>;
                              }
                              return <h3 key={field._key}>{field.title}</h3>;
                            }
                            if (field.fieldType === "select") {
                              const selectedValue = watch(field.fieldName);
                              return (
                                <FieldSelect
                                  selectedValue={selectedValue}
                                  field={field}
                                  index={index}
                                  isConditional={false}
                                  register={register}
                                  errors={errors}
                                />
                              );
                            }
                            if (field.fieldType === "boolean") {
                              return (
                                <FieldCheckBox
                                  selectedValue={false}
                                  field={field}
                                  index={index}
                                  isConditional={false}
                                  register={register}
                                  errors={errors}
                                />
                              );
                            }
                            return (
                              <CustomFormField
                                key={index}
                                field={field}
                                register={register}
                                errors={errors}
                              />
                            );
                          }
                        )}
                        {formSet.isDynamic &&
                          !shownButtonsIndices.has(index) && (
                            <div
                              className="form-dynamic-button"
                              onClick={() => {
                                if (addClicked === false) {
                                  return setAddClicked(true);
                                }
                                setShownButtonsIndices((prevIndices) => {
                                  const updatedIndices = new Set(prevIndices);
                                  updatedIndices.add(index);
                                  return updatedIndices;
                                });
                                setData((prevData: any) => {
                                  const updatedData = { ...prevData };
                                  updatedData.dynamicFormBuilder2.formSet.splice(
                                    index + 1,
                                    0,
                                    {
                                      ...formSet,
                                      _key: Math.random()
                                        .toString(36)
                                        .substr(2, 9),
                                      formFieldList: formSet.formFieldList.map(
                                        (field: any) => ({
                                          ...field,
                                          fieldName:
                                            field.fieldName + "_" + index,
                                        })
                                      ),
                                    }
                                  );
                                  return updatedData;
                                });
                              }}
                            >
                              Add Company contact <FaPlus />
                            </div>
                          )}
                      </>
                    );
                  }
                )}
              <div>
                <p className="sig-title">Signature:</p>
                <SignatureTabs
                  clearSignature={clearSignature}
                  sigCanvasRef={sigCanvasRef}
                  handleCanvasChange={handleCanvasChange}
                  isDrawingMode={isDrawingMode}
                  setIsDrawingMode={setIsDrawingMode}
                  typedSignature={typedSignature}
                  setTypedSignature={setTypedSignature}
                  selectedFont={selectedFont}
                  setSelectedFont={setSelectedFont}
                />
              </div>

              <TermsAndConditions
                onAccept={handleAccept}
                acceptedTerms={acceptedTerms}
                setAcceptedTerms={setAcceptedTerms}
              />
              {!acceptedTerms && (
                <small className="must-accept">
                  Must accept Terms & Conditions.
                </small>
              )}

              <Button
                text="Send Application"
                type="secondary"
                disabled={buttonDisabled}
                className="campaign-submit"
              >
                <FaPaperPlane style={{ marginLeft: "1rem" }} />
              </Button>
            </form>
          </OnlineAccountFormContainer>
        </OnlineAccountApplicationContainer>

        {/* <div
        dangerouslySetInnerHTML={{
          __html: htmlForm,
        }}
      /> */}
      </Container>
    </>
  );
};

export default OnlineAccountApplication;
