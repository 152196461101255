import React, { useEffect, useState } from "react";
import { ServicingContainer } from "./ServicingData";
import { Seo, PageBuilder } from "../../components";
import { sanityFetchAllByType } from "../../utils/globalFunctions";
import LoaderComponent from "../../components/Loader/LoaderComponent";
const Servicing = () => {
  const [servicingData, setServicingData]: any = useState();
  useEffect(() => {
    let fetchServicingData = sanityFetchAllByType("servicing");
    fetchServicingData.then((res) => {
      setServicingData(res[0]);
    });
  }, []);
  if (servicingData === undefined) {
    return <LoaderComponent />;
  }
  return (
    <ServicingContainer>
      <Seo
        title="Abcopro | Power To Clean | Servicing"
        description="Abcopro | Power To Clean | Servicing"
        keyword="Servicing"
      />
      <PageBuilder pageBuilder={servicingData.pageBuilder} />
      {/*

 */}
    </ServicingContainer>
  );
};

export default Servicing;
