import { useState, useEffect } from "react";
import header from "../../assets/images/homepage-banner-new.jpg";
import videoThumbnail from "../../assets/images/home-video-thumbnail.jpg";
import givingCleaners from "../../assets/images/giving-cleaners-a-say.jpg";
import { testimonials, accordions } from "./data";
import {
  HeroSection,
  Summary,
  HomePromises,
  Testimonials,
  HomeContactSection,
  Button,
  TileListing,
  VideoSection,
} from "../../components";
import { HomeContainer } from "./Home.style";
import { sanityFetchAllByType } from "../../utils/globalFunctions";

const Home = () => {
  const [innovationProductListing, setInnovationProductListing] = useState([]);
  useEffect(() => {
    let fetchRes = sanityFetchAllByType("innovation");

    fetchRes.then((res) => setInnovationProductListing(res));
  }, []);
  // const { setCatalogue, setCatalogueData } = useContext(StateContext);
  return (
    <HomeContainer>
      <HeroSection
        title="Empowering Cleaners"
        subtitle="to get the job done fast and efficiently"
        image={header}
        large
      >
        <Button
          text="Download Catalogues"
          to={"https://issuu.com/abcopro.com.au"}
          external
          type="secondary"
          size="long"
        />
      </HeroSection>
      <Summary />
      <VideoSection
        url={"https://youtu.be/XlQn7ZmI0RA"}
        videoLabel={
          "Welcome To Abco - Australia's Most Trusted Commercial Cleaning Supplier"
        }
        videoThumbnail={videoThumbnail}
      />
      <Testimonials data={testimonials} />
      <HomePromises />
      <HomePromises type="abconet" />
      <TileListing type="innovation" data={innovationProductListing}>
        <h2>
          Keep Up-To-Date With
          <strong>
            <br />
            Our Latest Innovative Products
          </strong>
        </h2>
      </TileListing>
      <HeroSection
        title="Giving cleaners a say!"
        subtitle="“Cleaners are often looked down upon in our society when instead they should be celebrated for the work they do every day to keep our Hospitals, Schools, Offices, Streets and Houses clean, safe, and hygienic.  Without cleaners, we simply would not be able to maintain the modern lifestyle we live today. 
        By working with them and finding key innovative products to empower them in their day to day jobs, there is a sense of fulfilment shared by everyone in the company that we are doing the right thing for the right cause,  and at the right time.”"
        reverse
        image={givingCleaners}
      >
        <p className="james">- James Bagshaw, Managing Director, Abco.</p>
        <Button
          text="Learn More"
          type="secondary"
          size="long"
          className="learn-more"
          aria-label="What we do"
          to="/about-us/what-we-do"
        />
      </HeroSection>
      <HomeContactSection from="home" data={accordions} />
    </HomeContainer>
  );
};

export default Home;
