import React, { FC } from "react";
import { ContactForm } from "..";
import { ContactSectionContainer } from "./HomeContactSection.style";
import { IHomeContactSection } from "../../interfaces";
import { FadeInWhenVisible, Section } from "..";

const HomeContactSection: FC<IHomeContactSection> = ({ data, from }) => {
  return (
    <FadeInWhenVisible>
      <ContactSectionContainer>
        <Section className="homecontact-section">
          <div className="content-container">
            <div className="left">
              {/* {data.map((q, key) => (
                  <Accordion
                    key={key}
                    title={q.title}
                    content={q.content}
                    active={q.active}
                  />
                ))} */}
              <h2>
                For any additional questions regarding the company and our
                services, feel free to contact us
              </h2>
              <div className="white-line"></div>
            </div>
            <div className="right">
              <ContactForm
                from={from}
                className="home-contact-section"
                showContactForm={true}
              />
            </div>
          </div>
        </Section>
      </ContactSectionContainer>
    </FadeInWhenVisible>
  );
};

export default HomeContactSection;
