import { FC } from "react";
import { TileWrapper, TileLink, TileImageContainer } from "./Tile.style";
import { formatToDollar, urlFor } from "../../utils/globalFunctions";
import { Icons } from "..";
import { IIcon } from "../../interfaces";
import { motion } from "framer-motion";
import ServicingCard from "../Card/ServicingCard";
import Button from "../Button/Button";
import moment from "moment";
import { useLocation } from "react-router-dom";

interface ITile {
  data: any;
  type: string;
  category?: string;
  subcategory?: string;
  isSearch?: boolean;
}

const imageMotion = {
  rest: {
    transition: {
      duration: 0.4,
      scale: 1,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    scale: 1.1,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeOut",
    },
  },
};
const wrapperMotion = {
  rest: {
    filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.15))",
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    filter: "drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.15))",
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut",
    },
  },
};
const arrowMotion = {
  rest: {
    x: -7,
    rotate: 270,
    transition: {
      duration: 0.2,

      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    x: 0,
    transition: {
      duration: 0.2,
      type: "tween",
      ease: "easeOut",
    },
  },
};

const Tile: FC<ITile> = ({ data, type, category, subcategory }, props) => {
  let cardContent;
  let tileLink = "/";
  let teamLink = {};
  let renderBtn = null;
  let marketingPrice = null;
  let tileImageContent = (
    <TileImageContainer>
      <div style={{ overflow: "hidden" }}>
        <motion.img
          variants={imageMotion}
          alt={`${type} ${data.title}`}
          src={data.image ? data.image : urlFor(data.cardThumbnailImage)}
        />
      </div>
    </TileImageContainer>
  );
  const currentLocation = useLocation();
  switch (type) {
    case "innovation":
      tileLink = `/innovation/${data.slug.current}`;
      cardContent = (
        <div className={`tile-content ${type}`}>
          <h4 className="title">{data.title}</h4>
          <p>{data.cardDescription}</p>
          <div className="continue">
            Continue Reading
            <motion.span variants={arrowMotion} className="animated-arrow">
              <Icons className="arrow" type={IIcon.sortDown} />
            </motion.span>
          </div>
        </div>
      );
      break;
    case "industries":
      tileLink = `/industries/${data.slug.current}`;
      cardContent = (
        <div className={`tile-content ${type}`}>
          <h4 className="title">{data.title}</h4>
          <p>{data.cardDescription}</p>
          <div className="continue">
            Continue Reading
            <motion.span variants={arrowMotion} className="animated-arrow">
              <Icons className="arrow" type={IIcon.sortDown} />
            </motion.span>
          </div>
        </div>
      );
      break;
    case "csr":
      tileLink = `/articles/${data.slug.current}`;
      cardContent = (
        <div className={`tile-content ${type}`}>
          <h4 className="title">{data.title}</h4>
          <p>{data.cardDescription}</p>
          <div className="continue">
            Continue Reading
            <motion.span variants={arrowMotion} className="animated-arrow">
              <Icons className="arrow" type={IIcon.sortDown} />
            </motion.span>
          </div>
        </div>
      );
      break;
    case "governance":
      tileLink = `/governance-policies/${data.slug.current}`;
      cardContent = (
        <div className={`tile-content ${type}`}>
          <h4 className="title">{data.title}</h4>
          <p>{data.cardDescription}</p>
          <div className="continue">
            Continue Reading
            <motion.span variants={arrowMotion} className="animated-arrow">
              <Icons className="arrow" type={IIcon.sortDown} />
            </motion.span>
          </div>
        </div>
      );
      break;
    case "blog":
      tileImageContent = (
        <TileImageContainer>
          <div style={{ overflow: "hidden" }}>
            <motion.img
              // style={{ backgroundImage: `url(${testImage})` }}
              variants={imageMotion}
              alt={data.title}
              src={data && urlFor(data.thumbnailImage).width(300).url()}
            />
          </div>
        </TileImageContainer>
      );
      tileLink = `/blogs/${data.slug.current}`;
      cardContent = (
        <div className={`tile-content ${type}`}>
          <div className="info-container">
            <div className="date">
              <Icons type={IIcon.calendar} />{" "}
              <p>{moment(data.publishedAt).format("LL")}</p>
            </div>
            {/* <div className="tags">
              <Icons type={IIcon.tag} />
              <div className="tags-container">
                {data.tags.length !== 0 &&
                  data.tags.map((tag: string, key: number) => (
                    <p key={key}>{tag}</p>
                  ))}
              </div>
            </div> */}
          </div>
          <h2 className="blogcard-title">{data.title}</h2>
          <div className="continue">
            Read More{" "}
            <motion.span variants={arrowMotion} className="animated-arrow">
              <Icons className="arrow" type={IIcon.sortDown} />
            </motion.span>
          </div>
        </div>
      );
      break;
    case "team":
      teamLink = {
        target: "_blank",
      };
      tileImageContent = (
        <TileImageContainer>
          <div style={{ overflow: "hidden" }}>
            <motion.img
              // style={{ backgroundImage: `url(${testImage})` }}
              variants={imageMotion}
              alt={`${type} ${data.title}`}
              src={
                data.thumbnailImage &&
                urlFor(data.thumbnailImage).width(400).url()
              }
            />
          </div>
        </TileImageContainer>
      );
      cardContent = (
        <div className={`tile-content ${type}`}>
          <h4 className="team-name">{data.title.replace(/\u2028/g, " ")}</h4>
          <p className="position">{data.position}</p>
          <div className="linked">
            <p className="connect">Let's Connect</p>
            <div className="linkedIn">
              <Icons type={IIcon.linkedIn2} />
            </div>
          </div>
        </div>
      );
      break;
    case "machineryProduct":
      tileLink = `/machinery/${category}/${subcategory}/${data.slug.current}`;

      let technicalSpecs = data.machineryPageBuilder.filter(
        (machine: any) => machine._type === "pageBuilderTechnicalSpecification"
      )[0];
      tileImageContent = (
        <TileImageContainer>
          <div style={{ overflow: "hidden" }}>
            <motion.img
              // style={{ backgroundImage: `url(${testImage})` }}
              variants={imageMotion}
              alt={"Picture" + data.productName}
              src={data && urlFor(data.thumbnailImage).width(300).url()}
            />
          </div>
        </TileImageContainer>
      );

      cardContent = (
        <div className={`tile-content ${type}`}>
          <h3>{data.productName.replace(/\u2028/g, " ")}</h3>
          <ul>
            {technicalSpecs?.technicalData.length > 0 ? (
              technicalSpecs?.technicalData
                .slice(0, 3)
                .map((specification: any, key: number) => (
                  <li key={key}>
                    <div className="product-data">
                      <div className="left">
                        {specification?.technicalDataDescription}
                      </div>
                      <div className="right">
                        {specification?.technicalDataValue}
                      </div>
                    </div>
                  </li>
                ))
            ) : (
              <>
                <li>
                  <div className="product-data">
                    <div className="left">Power</div>
                    <div className="right">xx</div>
                  </div>
                </li>
                <li>
                  <div className="product-data">
                    <div className="left">Capacity</div>
                    <div className="right">xx</div>
                  </div>
                </li>
                <li>
                  <div className="product-data">
                    <div className="left">Weight</div>
                    <div className="right">xx</div>
                  </div>
                </li>
              </>
            )}
          </ul>
          <div className="continue">
            Learn More
            <motion.span variants={arrowMotion} className="animated-arrow">
              <Icons className="arrow" type={IIcon.sortDown} />
            </motion.span>
          </div>
        </div>
      );
      if (
        data?.productMarketing &&
        data?.productMarketing?.abconetUrl?.length > 0 &&
        currentLocation.pathname.startsWith("/promotions")
      ) {
        renderBtn = (
          <Button
            text="Shop Now"
            to={data.productMarketing.abconetUrl}
            type="secondary"
            external
            className="shop-btn"
          >
            <Icons type={IIcon.cart} />
          </Button>
        );
      }

      break;
    case "machinery":
      let machineryCategory =
        data.machinerySubcategory.mainCategory.slug.current;
      let machinerySubcategory = data.machinerySubcategory.slug.current;
      tileLink = `/machinery/${machineryCategory}/${machinerySubcategory}/${data.slug.current}`;
      let machineryTechnicalSpecs = data.machineryPageBuilder.filter(
        (machine: any) => machine._type === "pageBuilderTechnicalSpecification"
      )[0];
      tileImageContent = (
        <TileImageContainer>
          <div style={{ overflow: "hidden" }}>
            <motion.img
              // style={{ backgroundImage: `url(${testImage})` }}
              variants={imageMotion}
              alt={"Picture" + data.productName}
              src={data && urlFor(data.thumbnailImage).width(300).url()}
            />
          </div>
        </TileImageContainer>
      );
      cardContent = (
        <div className={`tile-content machineryProduct`}>
          <h3>{data.productName?.replace(/\u2028/g, " ")}</h3>
          <ul>
            {machineryTechnicalSpecs?.technicalData.length > 0 ? (
              machineryTechnicalSpecs?.technicalData
                .slice(0, 3)
                .map((specification: any, key: number) => (
                  <li key={key}>
                    <div className="product-data">
                      <div className="left">
                        {specification?.technicalDataDescription}
                      </div>
                      <div className="right">
                        {specification?.technicalDataValue}
                      </div>
                    </div>
                  </li>
                ))
            ) : (
              <>
                <li>
                  <div className="product-data">
                    <div className="left">Power</div>
                    <div className="right">xx</div>
                  </div>
                </li>
                <li>
                  <div className="product-data">
                    <div className="left">Capacity</div>
                    <div className="right">xx</div>
                  </div>
                </li>
                <li>
                  <div className="product-data">
                    <div className="left">Weight</div>
                    <div className="right">xx</div>
                  </div>
                </li>
              </>
            )}
          </ul>

          <div className="continue">
            Learn More
            <motion.span variants={arrowMotion} className="animated-arrow">
              <Icons className="arrow" type={IIcon.sortDown} />
            </motion.span>
          </div>
        </div>
      );
      if (
        data.productMarketing &&
        data?.productMarketing?.abconetUrl?.length > 0
      ) {
        renderBtn = (
          <Button
            text="Shop Now"
            to={data.productMarketing.abconetUrl}
            type="secondary"
            external
            className="shop-btn"
          >
            <Icons type={IIcon.cart} />
          </Button>
        );
      }
      if (data.productMarketing) {
        marketingPrice = (
          <div className="marketing-price-container">
            {data.productMarketing.was && (
              <div className="marketing-was">
                Was: {formatToDollar(data.productMarketing.was, 2)}
              </div>
            )}
            {data.productMarketing.now && (
              <div className="marketing-now">
                Now: {formatToDollar(data.productMarketing.now, 2)}
              </div>
            )}
          </div>
        );
      }
      break;
    case "machineryCategory":
      tileLink = `/machinery/${data.slug.current}`;
      tileImageContent = (
        <TileImageContainer>
          <div style={{ overflow: "hidden" }}>
            <motion.img
              // style={{ backgroundImage: `url(${testImage})` }}
              variants={imageMotion}
              alt={data.thumbnailImage.imageAlt}
              // style={{ backgroundImage: `url(${testImage})` }}
              src={
                data.thumbnailImage &&
                urlFor(data.thumbnailImage).width(300).url()
              }
            />
          </div>
        </TileImageContainer>
      );
      cardContent = (
        <div className={`tile-content ${type}`}>
          <h4 className="title">{data.title.replace(/\u2028/g, " ")}</h4>
          <p>{data.cardDescription}</p>
          <div className="continue">
            Learn More
            <motion.span variants={arrowMotion} className="animated-arrow">
              <Icons className="arrow" type={IIcon.sortDown} />
            </motion.span>
          </div>
        </div>
      );

      break;
    case "consumables":
      tileLink = `/consumables/${data.slug.current}`;
      cardContent = (
        <div className={`tile-content ${type}`}>
          <h4 className="title">{data.title}</h4>
          <p>{data.cardDescription}</p>
          <div className="continue">
            Continue Reading
            <motion.span variants={arrowMotion} className="animated-arrow">
              <Icons className="arrow" type={IIcon.sortDown} />
            </motion.span>
          </div>
        </div>
      );
      break;
    case "servicing":
      return <ServicingCard data={data} />;
    default:
      cardContent = <div>TEST default</div>;
  }

  return (
    <TileWrapper
      initial="rest"
      whileHover="hover"
      animate="rest"
      style={{
        marginBottom: currentLocation.pathname.startsWith("/promotions")
          ? "4rem"
          : "0",
        position: "relative",
        width: "100%",
      }}
      variants={wrapperMotion}
    >
      <TileLink
        {...teamLink}
        to={type === "team" ? { pathname: data.linkedin } : tileLink}
      >
        {tileImageContent}
        <div className="content-container">{cardContent}</div>
      </TileLink>
      {renderBtn}
      {marketingPrice}
    </TileWrapper>
  );
};

export default Tile;
