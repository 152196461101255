import {
  Home,
  About,
  Machinery,
  Consumables,
  Innovation,
  Servicing,
  Blogs,
  BlogPost,
  LegalPolicies,
  WorkWithUs,
  Abconet,
  CSR,
  Articles,
  MachineryCategoryPage,
  MachineryProductPage,
  InnovationProduct,
  GovernancePolicies,
  ThankYou,
  PageTemplate,
  Campaign,
  Industries,
  ConsumablesArticle,
  EquipmentHire,
  OnlineAccountApplication,
  Landing,
} from "../pages";
import Search from "../pages/Search/Search";
const Routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/about-us",
    component: About,
  },
  {
    path: "/work-with-us",
    component: WorkWithUs,
  },
  {
    path: "/Abconet",
    component: Abconet,
  },
  {
    path: "/csr-programs",
    component: CSR,
  },
  {
    path: "/machinery",
    component: Machinery,
  },
  {
    path: "/consumables",
    component: Consumables,
  },
  {
    path: "/innovation",
    component: Innovation,
  },
  {
    path: "/servicing",
    component: Servicing,
  },
  {
    path: "/blogs",
    component: Blogs,
  },
  {
    path: "/thank-you",
    component: ThankYou,
  },
  {
    path: "/equipment-hires",
    component: EquipmentHire,
  },
  {
    path: "/about-us/:slug",
    component: PageTemplate,
  },
  {
    path: "/industries/:slug",
    component: Industries,
  },
  {
    path: "/consumables/:slug",
    component: ConsumablesArticle,
  },
  {
    path: "/blogs/:id",
    component: BlogPost,
  },
  {
    path: "/legal-policies/:slug",
    component: LegalPolicies,
  },
  {
    path: "/promotions/:slug",
    component: Landing,
  },
  {
    path: "/articles/:slug",
    component: Articles,
  },
  {
    path: "/governance-policies/:slug",
    component: GovernancePolicies,
  },
  {
    path: "/machinery/:category",
    component: MachineryCategoryPage,
  },
  {
    path: "/innovation/:product",
    component: InnovationProduct,
  },
  {
    path: "/campaign/:slug",
    component: Campaign,
  },
  {
    path: "/machinery/:category/:subcategory/:product",
    component: MachineryProductPage,
  },
  {
    path: "/account-application",
    component: OnlineAccountApplication,
  },
  {
    path: "/search/:searchTerm",
    component: Search,
  },
];

export default Routes;
