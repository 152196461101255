import styled from "styled-components";
import device from "../../utils/deviceScreenSizes";

export const FooterContainer = styled.div`
  background: ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.primary};
  .footer-bottom {
    padding: 1.3rem;
    background: ${(p) => p.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    flex-direction: column;

    span {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    p {
      text-align: center;
      font-size: 1rem;
      font-weight: 300;
      max-width: 1300px;
      margin: 0 auto;
    }
  }
  .footer-section {
    min-height: 787px;

    .content-container {
      padding-top: 5rem;
      padding-bottom: 3rem;
      display: flex;
      @media ${device.custom_545} {
        flex-direction: column;
      }
      .left,
      .right {
        flex: 1;

        &-content {
          max-width: 500px;
          h3 {
            font-size: ${(props) => props.theme.fontSizes.medium};
            font-weight: 800;
          }
          .description-location {
            padding-bottom: 1.5rem;

            a {
              font-weight: 600;
            }
          }
          &-item {
            padding-bottom: 3.2rem;
            line-height: 32px;
            font-weight: 500;

            ul {
              list-style: none;
              li {
                padding-bottom: 1rem;
                a {
                  color: ${(props) => props.theme.colors.primary};
                }
              }
            }

            .address {
              font-weight: 500;
            }

            h4 {
              font-weight: 800;
            }

            &-info {
              display: flex;
              padding-bottom: 1.5rem;
              font-size: ${(props) => props.theme.fontSizes.medium};

              .info {
                font-size: ${(props) => props.theme.fontSizes.normal};
              }
            }
          }
        }
      }

      .left {
        .left-content {
          padding-right: 1rem;
        }
        .abco-logo {
          width: 150px;
          height: 40px;
        }
        .abco-description {
          line-height: 32px;
          font-weight: 800;
          max-width: 250px;
        }
        .left-content-item-info.mail {
          padding-right: 1rem;
        }
        .left-content-item-info.phone,
        .left-content-item-info.mail,
        .left-content-item-info.time {
          a {
            & > span {
              @media ${device.tablet} {
                padding-top: 0.5rem;
              }
            }
          }
        }
        .left-content-item-info {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${(props) => props.theme.colors.primary};
          }
          @media ${device.tablet} {
            font-size: 1rem;
          }
          span {
            height: 100%;
            justify-content: center;
            align-items: center;
            @media ${device.tablet} {
              align-items: flex-start;
            }
          }
        }
        .info {
          padding-top: 0 !important;
        }
        .time-icon {
          @media ${device.tablet} {
            padding-top: 0.5rem;
          }
        }
      }

      .right-content-item {
        padding-bottom: 2rem;
        display: flex;
        gap: 2rem;
        @media ${device.tablet} {
          flex-direction: column;
          gap: 0;
        }
        a {
          color: ${(props) => props.theme.colors.primary};
        }
        .address-container {
          min-width: 200px;
        }
        .image-placeholder {
          width: 100%;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
`;
