import React, { FC, useState } from "react";
import { VideoSectionContainer } from "./VideoSection.style";
import { urlFor } from "../../utils/globalFunctions";
import defaultVideoThumbnail from "../../assets/images/default-video-thumbnail.jpg";
import { Icons, Modal } from "..";
import "react-responsive-modal/styles.css";
import { IIcon } from "../../interfaces";

type VideoSectionProps = {
  url: string;
  id?: string;
  videoThumbnail?: any;
  videoLabel?: string;
};

const VideoSection: FC<VideoSectionProps> = ({
  url,
  id,
  videoThumbnail,
  videoLabel,
}) => {
  const [isOpen, setOpen] = useState(false);
  const toggleModal = () => setOpen(!isOpen);
  return (
    <VideoSectionContainer
      id={id ? id : "video-section"}
      bg={
        videoThumbnail
          ? typeof videoThumbnail === "object"
            ? urlFor(videoThumbnail)
            : videoThumbnail
          : defaultVideoThumbnail
      }
    >
      <div className="video-section-content">
        {url && (
          <>
            <h2>{videoLabel}</h2>
            <div className="play-container">
              <Icons type={IIcon.play} onClick={toggleModal} />
            </div>
          </>
        )}
      </div>
      <Modal open={isOpen} toggleModal={toggleModal} url={url} />
    </VideoSectionContainer>
  );
};

export default VideoSection;
