import React, { FC, useEffect, useState } from "react";
import { Seo, PageBuilder } from "../../../components";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import { sanityFetchSingleMainPage } from "../../../utils/globalFunctions";

const WorkWithUs: FC = () => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    sanityFetchSingleMainPage("work-with-us")
      .then((res) => {
        setIsLoading(false);
        setData(res);
      })
      .catch((err) => setIsLoading(false));
  }, []);
  return (
    <div>
      <Seo
        title={data?.seo?.seo_title}
        description={data?.seo?.meta_description}
        keyword={data?.seo?.focus_keyword}
      />
      {!isLoading ? (
        <PageBuilder pageBuilder={data.pageBuilder} />
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};

export default WorkWithUs;
