import React, { useEffect, useState } from "react";
import { PageBuilder, Seo, Section } from "../../components";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { sanityFetchSingleIndustry } from "../../utils/globalFunctions";
import { useParams } from "react-router-dom";
import { IndustriesContainer } from "./Industries.style";

const Industries = (props: any) => {
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let { slug } = useParams<any>();
  useEffect(() => {
    let fetchData = sanityFetchSingleIndustry(slug);

    fetchData
      .then((res) => {
        if (res.length === 0) {
          return props.history.push("/not-found");
        }
        setIsLoading(false);
        setData(res[0]);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [props.history, slug]);
  return (
    <IndustriesContainer>
      {data.seo && (
        <Seo
          description={data.seo.meta_description && data.seo.meta_description}
          keyword={data.seo.focus_keyword && data.seo.focus_keyword}
          title={data.seo.seo_title && data.seo.seo_title}
        />
      )}
      {!isLoading ? (
        <Section className="post-section-container">
          {/* <h2 className="blog-title">{data.title}</h2> */}
          <main className="blog-post-content">
            <PageBuilder pageBuilder={data.industryPageBuilder} />
          </main>
        </Section>
      ) : (
        <LoaderComponent />
      )}
    </IndustriesContainer>
  );
};

export default Industries;
