import styled from "styled-components";
import { Container } from "../../Theme/globalStyle";

export const NotFoundContainer = styled(Container)`
  text-align: center;
  color: ${(p) => p.theme.colors.primary};
  img {
    max-width: 500px;

    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  h1 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

export const PageNotFoundContainer = styled.div`
  .back-wrapper {
    position: absolute;
    max-width: 100%;
  }
  .back-btn-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-width: 1300px;
    margin: 0 auto;
    .btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: ${(p) => p.theme.colors.gray2};
    }
    .icon {
      color: ${(p) => p.theme.colors.gray2};
      font-size: 1.5rem;
    }
    .back {
      font-weight: 800;
    }
  }
`;
