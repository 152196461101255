import styled from "styled-components"


export const SearchBarContainer = styled.div`
height: 100%;
margin-right:auto;
.quick-links-container {
  padding: 1rem; 
  z-index: 99;
  position: relative;
  background-color: white; 
  border-radius: 0 0 1rem 1rem; 
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.quick-links-header {
  color: gray;
  font-family: 'general-sans', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  padding-right: 1rem;
  padding-left: 2.1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.quick-links-list {
  background-color: white;
  color: black;
  list-style-type: none;
}

.quick-links-item {
  font-weight: 600;
  a {
    text-decoration: none;
    color: #244289;
  }
}

.quick-links-item:hover {
  background-color: #244289;
  a {
    color: white;
    
  }
}

.quick-links-link {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 2.1rem;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
form.search-form {
    color: white;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    font-family: "General Sans", sans-serif;
    height: 100%;
  }
  
  form.search-form > div.search-container {
    display: flex;
    align-items: center;
    height: 100%;
    border-left: 1px solid #4B5563;
    border-right: 1px solid #4B5563;
  }
  
  div.search-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  div.search-icon-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 12px;
    cursor: pointer;
    z-index: 10;
  }
  
  svg.search-icon {
    width: 24px;
    height: 24px;
    fill: currentColor;
    color: #9CA3AF;
    transition: all 0.2s;
  }
  
  svg.search-icon:hover {
    color: black;
  }
  
  input.search-input {
    color: #000000;
    outline: none;
    display: block;
    width: 100%;
    padding-left: 48px;
    height: 100%;
    border: none;
    font-size: 1rem;
  }
  
  input.search-input:focus {
    ring-color: #3B82F6;
  }

`