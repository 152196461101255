import React from "react";
import { Link } from "react-router-dom";
import notFoundImage from "../../assets/images/error.jpg";
import {
  HeroSection,
  Section,
  Icons,
  Button,
  Navbar,
  Footer,
} from "../../components";
import { IIcon } from "../../interfaces";
import { PageNotFoundContainer } from "./PageNotFound.style";
const PageNotFound = () => {
  return (
    <>
      <Navbar />
      <PageNotFoundContainer>
        <Section className="back-wrapper">
          <div className="back-btn-container">
            <Link className="btn" to="/">
              <Icons type={IIcon.arrowLeft} className="icon" />{" "}
              <span className="back">Back</span>
            </Link>
          </div>
        </Section>

        <HeroSection
          title="Oh no!"
          subtitle="This page has been moved or redirected."
          image={notFoundImage}
          large
        >
          <Button type={"secondary"} size="long" to="/" text="Go back" />
        </HeroSection>
      </PageNotFoundContainer>
      <Footer />
    </>
  );
};

export default PageNotFound;
