import styled from "styled-components";
import device from "../../utils/deviceScreenSizes";

export const LinkWrapper = styled.div`
  font-size: 1rem;
  font-weight: 800;
  white-space: nowrap;
  .primary {
    background: ${(props) => props.theme.colors.primary};

    &:hover {
      background: ${(props) => props.theme.colors.secondary};
    }
  }
  .secondary {
    border: 1px solid ${(props) => props.theme.colors.secondary};
    background: ${(p) => p.theme.colors.secondary};
    color: ${(p) => p.theme.colors.white};

    &:hover {
      background: ${(p) => p.theme.colors.primary};
      color: ${(p) => p.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.white};
    }
  }
  .secondary.outlined {
    border: 1px solid ${(props) => props.theme.colors.secondary};
    background: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.secondary};

    &:hover {
      background: ${(p) => p.theme.colors.primary};
      color: ${(p) => p.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.white};
    }
  }
  .long {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;

export const EmailSubscriptionContainer = styled.div`
  label {
    font-weight: 700;
  }
  a:disabled,
  a[disabled] {
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
  }
  .input-container {
    display: flex;
    @media ${device.mobileL} {
      flex-direction: column;
    }
    input {
      flex: 2;
      border: 1px solid ${(p) => p.theme.colors.gray3};
      padding: 1rem;
      font-size: 1rem;
      border-right: 0;
      max-width: 400px;
      @media ${device.mobileL} {
        border-right: 1px solid #bdbdbd;
      }
    }

    .subscribe-btn {
      flex: 1;
    }
    .download-btn {
      &:hover {
        background: ${(p) => p.theme.colors.secondary};
      }
      padding-left: 3rem;
      padding-right: 3rem;
      width: 100%;
      height: 100%;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      /* max-width: 260px; */
      border: 1px solid ${(props) => props.theme.colors.white};
      border-radius: 0;
      font-weight: bold;
      cursor: pointer;
      font-size: 1rem;
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.primary};
      transition: 0.1s ease-in all;
      text-decoration: none;
      display: block;
    }
  }
`;
