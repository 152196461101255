import React, { FC } from "react";
import ReactPlayer from "react-player";
import { Modal as ResponsiveModal } from "react-responsive-modal";

interface IModal {
  open: boolean;
  toggleModal: any;
  url: string;
}

const Modal: FC<IModal> = ({ open, toggleModal, url }) => {
  return (
    <ResponsiveModal
      open={open}
      onClose={() => toggleModal()}
      styles={{
        modal: {
          maxWidth: "1200px",
          width: "100%",
          padding: "unset",
          marginLeft: 0,
          overflow: "hidden",
          borderRadius: "10px",
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.5)",
        },
        closeButton: {
          background: "white",
        },
      }}
      aria-labelledby="machinery product video"
      aria-describedby="machinery product showcase"
    >
      <ReactPlayer
        url={url}
        width="100%"
        height="calc(100vh - 100px)"
        playing={true}
        style={{}}
        controls
      />
    </ResponsiveModal>
  );
};

export default Modal;
