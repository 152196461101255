import React, { FC } from "react";
import { CoreSection, CoreContainer } from "./CoreValues.style";
import alwaysHungry from "../../assets/svg/always-hungry-2.svg";
import fastFix from "../../assets/svg/fast-fix-2.svg";
import weNotI from "../../assets/svg/we-not-i-2.svg";
import ownIt from "../../assets/svg/own-it-2.svg";

interface ICoreValues {
  data: any;
}

const CoreValues: FC<ICoreValues> = (props: ICoreValues) => {
  const { data } = props;
  return (
    <CoreSection isGray={data.backgroundGray}>
      <CoreContainer>
      <h2>Our Core Values</h2>

        <div className="core-cards-container">
          <div className="core-card">
            <div className="top">
              <img src={alwaysHungry} alt="Always Hungry" />
              <p>Always Hungry</p>
            </div>
            <p>
              Keep challenging the status quo, as we can ALWAYS do better and
              good is the enemy of GREAT! Strive to make things better, set new
              benchmarks and be a better person than you wer yesterday
            </p>
          </div>
          <div className="core-card">
            <div className="top">
              <img src={fastFix} alt="Fast Fix" />
              <p>Fast Fix</p>
            </div>
            <p>
              Actions always speak louder than words. Taking action right NOW to
              get results whilst always raising the bar is what keeps us ahead
              of the game.
            </p>
          </div>
          <div className="core-card">
            <div className="top">
              <img src={weNotI} alt="We not I" />
              <p>We, Not I</p>
            </div>
            <p>
              Your success is always a consequence of how well you work with
              your environment and those around you. Great leaders are always
              humble and work hard to set others up for success.
            </p>
          </div>
          <div className="core-card">
            <div className="top">
              <img src={ownIt} alt="Own It" />
              <p>Own it</p>
            </div>
            <p>
              Stop pointing out and start pointing in. Be responsible for your
              actions, your job, your life and your word by seeing things
              through to completion. Exceed expectations.
            </p>
          </div>
        </div>
      </CoreContainer>
    </CoreSection>
  );
};

export default CoreValues;
