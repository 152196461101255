import styled from "styled-components";

export const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  .hideForm {
    display: none;
  }

  .showForm {
    display: block;
  }
  label {
    font-size: 1rem;
    font-weight: 800;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: left;
  }
  input,
  textarea {
    font-size: 1rem;
    padding: 0.8rem 1rem;
    border: 1px solid ${(p) => p.theme.colors.gray3};
    border-radius: 2.5px;
    width: 100%;
  }
`;
