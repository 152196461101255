import React from "react";
import axios from "axios";
import { SubscribeFormContainer } from "./Forms.style";
interface MyWindow extends Window {
  dataLayer: any;
}
declare let window: MyWindow;
const SubscribeForm = (props: any) => {
  const onSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      email: formData.get("email"),
    };
    axios
      .post("https://www.createsend.com/t/subscribeerror?description=", data)
      .then((res) => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "form_submission",
            form_name: "subscribe-form",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <SubscribeFormContainer from={props.from && props.from}>
      <form
        className="js-cm-form"
        id="subForm"
        action="https://www.createsend.com/t/subscribeerror?description="
        method="post"
        data-id="A61C50BEC994754B1D79C5819EC1255C3AB21EA0F645BE1FE3066531CAED0F15B7AA430E9F52B51FDF9D49E3A5F7F766941D4D273A3AF56B88A32C96EB598C22"
        onSubmit={onSubmit}
      >
        <div>
          <div>
            {!props.from && <label>Email Address</label>}
            <div className="email">
              <input
                autoComplete="Email"
                aria-label="Email"
                className="js-cm-email-input qa-input-email"
                id="fieldEmail"
                maxLength={200}
                name="cm-ykyhtit-ykyhtit"
                required
                placeholder="example@address.com"
                type="email"
              />
              <button type="submit">Subscribe</button>
            </div>
          </div>
        </div>
      </form>
    </SubscribeFormContainer>
  );
};

export default SubscribeForm;
