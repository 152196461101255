import React, { FC } from "react";
import { IQuote } from "../../interfaces";
import googleReview from "../../assets/images/google-reviews.png";
import { QuoteContainer } from "./Testimonials.style";

const Quote: FC<IQuote> = ({ content, fullName, company }) => {
  return (
    <QuoteContainer>
      <img src={googleReview} alt="5 Star Google Review" />
      <br />
      {/* <p className="quote-title">Lorem ipsum, dolor sit amet</p> */}
      <p className="quote-content">{content}</p>
      <div className="info">
        <p>
          <b>{fullName}</b>
        </p>
      </div>
    </QuoteContainer>
  );
};

export default Quote;
