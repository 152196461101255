import React from "react";
import { FormFieldContainer } from "../../components/Forms/Forms.style";

type FieldCheckBoxProps = {
  field: any;
  selectedValue: any;
  isConditional: any;
  index: any;
  register: any;
  errors: any;
};

const FieldCheckBox: React.FC<FieldCheckBoxProps> = ({
  field,
  selectedValue,
  isConditional,
  index,
  register,
  errors,
}) => {
  return (
    <div key={index}>
      <FormFieldContainer style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            width: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            id={field.fieldName}
            {...register(field.fieldName, {
              required: field.fieldRequired ? true : false,
            })}
          />
        </div>

        <label style={{ maxWidth: "90%" }} htmlFor={field.fieldName}>
          {field.fieldLabel}
          {field.fieldRequired && "*"}
        </label>
      </FormFieldContainer>
      {field.conditionalFields &&
        field.conditionalFields.map((conditionalField: any, index: number) => {
          if (
            selectedValue === conditionalField.selectOptionValue &&
            conditionalField.fieldType !== "checkbox"
          ) {
            return (
              <FormFieldContainer key={index} className="form-field-container">
                <label htmlFor={conditionalField.fieldLabel}>
                  {conditionalField.fieldLabel}
                  {conditionalField.fieldRequired && "*"}
                </label>
                <input
                  type={conditionalField.fieldType}
                  id={conditionalField.fieldName}
                  placeholder={conditionalField.fieldPlaceholder}
                  {...register(conditionalField.fieldName, {
                    required: conditionalField.fieldRequired ? true : false,
                  })}
                />
                {errors[conditionalField.fieldName] && (
                  <span>{conditionalField.fieldLabel} is required</span>
                )}
              </FormFieldContainer>
            );
          }
          return null;
        })}
    </div>
  );
};

export default FieldCheckBox;
