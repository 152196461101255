import React, { FC } from "react";
import { SectionContainer } from "./CardsAndSection.style";
import { Card, FadeInWhenVisible, Section } from "..";

export interface ICardsAndSection {
  data?: any;
  bg?: string;
  children?: any;
  lightBlue?: boolean;
  type:
    | "team"
    | "article"
    | "skeleton"
    | "blog"
    | "product"
    | "machinery"
    | "innovation"
    | "governance"
    | "servicing";
  icon?: any;
}

const CardsAndSection: FC<ICardsAndSection> = ({
  data,
  children,
  lightBlue,
  type,
}) => {
  return (
    <FadeInWhenVisible>
      <SectionContainer lightBlue={lightBlue}>
        <h2>{children}</h2>
        <Section className="section-cards">
          {data.map((card: any, key: number) => {
            let allData = { ...card };
            return <Card type={type} key={key} data={allData} />;
          })}
        </Section>
      </SectionContainer>
    </FadeInWhenVisible>
  );
};

export default CardsAndSection;
