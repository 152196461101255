import styled from "styled-components";
import { Container } from "../../Theme/globalStyle";
import device from "../../utils/deviceScreenSizes";

export const HomeSection = styled.section<{ lightBlue: boolean | undefined }>`
  min-height: 950px;
  background: ${(p) => p.lightBlue && p.theme.colors.lightBlue};
`;

export const MarketingSectionContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 2rem;

  @media ${device.tablet} {
    flex-direction: column;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .left {
      margin-bottom: 3rem;
    }
  }

  .right,
  .left {
    flex: 1;
  }
  .left {
    color: ${(p) => p.theme.colors.primary};
    font-weight: 500;
    padding-right: 1rem;

    @media ${device.tablet} {
      padding-right: 0;
    }
    h1 {
      padding-bottom: 2rem;
      font-weight: 800;
      font-size: 3rem;
    }
    h2 {
      font-size: ${(p) => p.theme.fontSizes.large};
      font-weight: 300;
      line-height: 64px;
      padding-bottom: 1rem;

      @media ${device.tablet} {
        font-size: 2.5rem;
        text-align: center;
        @media ${device.mobileL} {
          font-size: 1.5rem;
          text-align: center;
        }
      }
    }
    p {
      line-height: 32px;
      padding-bottom: 1rem;
      @media ${device.tablet} {
        font-size: 1rem;
      }
    }
    ul {
      list-style: none;
      li {
        display: flex;
        align-self: start;
        padding-top: 2px;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        @media ${device.tablet} {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }

        span {
          padding-right: 1.5rem;
          align-self: center;
        }
      }
    }
    .btn-container {
      margin-top: 2rem;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      max-width: 500px;
      /* flex-direction: row; */
      & > div {
        /* width: 100%; */
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      @media ${device.tablet} {
        justify-content: space-between;
        max-width: 420px;
        & > div {
          margin-bottom: 1rem;
          margin-right: 1rem;
          a {
            text-align: center;
            display: block;
          }
        }
        @media ${device.mobileL} {
          flex-direction: column;

          & > div {
            margin-bottom: 1rem;
            margin-right: 0;
            button {
              width: 100%;
            }
          }
        }
      }

      .learnBtn {
        /* margin-right: 3rem; */
        @media ${device.mobileL} {
          margin-right: 0;
        }
      }
      .registerBtn {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
  }
  .right {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    @media ${device.mobileL} {
      grid-template-columns: 1fr;
    }
    /* grid-template-columns: repeat(auto-fill, minmax(270px, 1fr)); */
  }
  .skeleton-card {
    min-height: 325px;
    margin-bottom: 3rem;
    .top {
      p {
        font-size: 1.25rem;
        font-weight: 800;
      }
    }
  }
  .top-card-set {
    display: flex;
    margin-top: 6rem;
    flex-direction: column;
    @media ${device.tablet} {
      margin-right: 1rem;
      @media ${device.mobileL} {
        margin-top: 0;
        margin-right: 0;
      }
    }
  }
`;
