import styled, { css } from "styled-components";

interface IAccordionContainer {
  isActive: boolean;
}

export const AccordionContainer = styled.div<IAccordionContainer>`
  border-bottom: 1px solid #bdbdbd;
  ${({ isActive }) =>
    isActive &&
    css`
      background: linear-gradient(180deg, #244289 0%, #3b67d0 100%);
      mix-blend-mode: normal;
    `}
  .bg-white {
    background: ${(p) => p.theme.colors.white};
  }
  .bg-gray {
    background: ${(p) => p.theme.colors.lightBlue};
  }
  .bg-gray2 {
    background: #e1e1e1;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .accordion-divider {
    z-index: 999;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 1.5rem; */
    /* border-bottom: 2px solid ${(p) => p.theme.colors.gray2}; */
    border-radius: 2.5px;

    color: ${(p) => p.theme.colors.primary};
    .accordion-title-container {
      display: flex;
      width: 100%;
      /* cursor: pointer; */
      align-items: center;
      padding: 0.6rem 30px;
    }
    .accordion-title {
      margin-right: auto;
      /* font-size: 1.5rem; */
      font-weight: bold;
      a {
        text-decoration: none;
        color: ${(p) => p.theme.colors.primary};
      }
    }
    .accordion-icon {
      font-size: 1.5rem;
      color: ${(p) => p.theme.colors.primary};
      font-weight: 500;
      cursor: pointer;
    }
    .accordion-content {
      /* transition: all 0.3s ease-in-out; */
      font-size: 1rem;
      line-height: 32px;
      width: 100%;
      background: ${(p) => p.theme.colors.gray3};
    }
  }
`;
