import React, { useEffect, useRef } from "react";
import { SmoothScrollWrapper } from "./SmoothScroll.style";
import { useWindowSize } from "../../utils/customHooks";

const SmoothScroll = ({ children }: { children: any }) => {
  // 1.
  const windowSize = useWindowSize();

  //2.
  const scrollingContainerRef = useRef<any>();

  // 3.
  // const data = {
  //   ease: 0.1,
  //   current: 0,
  //   previous: 0,
  //   rounded: 0,
  // };

  // 4.
  useEffect(() => {
    setBodyHeight();
  }, [windowSize.height]);

  const setBodyHeight = () => {
    document.body.style.height = `${
      scrollingContainerRef.current.getBoundingClientRect().height
    }px`;
  };

  // 5.

  useEffect(() => {
    // requestAnimationFrame(() => smoothScrollingHandler());
  }, []);

  // const smoothScrollingHandler = () => {
  //   data.current = window.scrollY;
  //   data.previous += (data.current - data.previous) * data.ease;
  //   data.rounded = Math.round(data.previous * 100) / 100;

  //   scrollingContainerRef.current.style.transform = `translateY(-${data.previous}px)`;

  //   // Recursive call
  //   requestAnimationFrame(() => smoothScrollingHandler());
  // };

  return (
    <SmoothScrollWrapper>
      <div ref={scrollingContainerRef}>{children}</div>
    </SmoothScrollWrapper>
  );
};

export default SmoothScroll;
