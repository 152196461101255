import React, { useEffect, useState } from "react";
import { PageBuilder, Seo } from "../../components";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { sanityFetchSingleConsumable } from "../../utils/globalFunctions";
import { useParams } from "react-router-dom";
import { ConsumablesArticleContainer } from "./Consumables.style";

const ConsumablesArticle = (props: any) => {
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let { slug } = useParams<any>();
  useEffect(() => {
    let fetchData = sanityFetchSingleConsumable(slug);

    fetchData
      .then((res) => {
        // if (res.length === 0) {
        //   return props.history.push("/not-found");
        // }
        setIsLoading(false);
        setData(res[0]);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [props.history, slug]);
  return (
    <ConsumablesArticleContainer>
      {data.seo && (
        <Seo
          description={data.seo.meta_description && data.seo.meta_description}
          keyword={data.seo.focus_keyword && data.seo.focus_keyword}
          title={data.seo.seo_title && data.seo.seo_title}
        />
      )}
      {!isLoading ? (
        <main className="blog-post-content">
          <PageBuilder pageBuilder={data.consumableArticlePageBuilder} />
        </main>
      ) : (
        <LoaderComponent />
      )}
    </ConsumablesArticleContainer>
  );
};

export default ConsumablesArticle;
