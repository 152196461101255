import styled from "styled-components";

export const ThankYouContainer = styled.div`
  .back-wrapper {
    position: absolute;
    max-width: 100%;
  }
  .back-btn-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-width: 1300px;
    margin: 0 auto;
    .btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: ${(p) => p.theme.colors.gray2};
    }
    .icon {
      color: ${(p) => p.theme.colors.gray2};
      font-size: 1.5rem;
    }
    .back {
      font-weight: 800;
    }
  }
`;
