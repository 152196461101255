import styled from "styled-components";
import { Container } from "../../Theme/globalStyle";

export const CsrContainer = styled.div`
  .loader-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PolicyContainer = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  .policy-section {
    display: flex;
    color: ${(p) => p.theme.colors.primary};
    font-weight: 500;
    justify-content: space-between;

    .left,
    .right {
      flex: 1;
      line-height: 32px;
      max-width: 550px;
    }

    .csr-button {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

export const CardListingContainer = styled(Container)``;
