import styled from "styled-components";
import device from "../../utils/deviceScreenSizes";
export const LegalPoliciesContainer = styled.div`
  .post-section-container {
    margin-top: 3rem;
    margin-bottom: 3rem;
    max-width: 800px;
    padding: 0;
    padding-left: 2rem;
    padding-right: 2rem;

    .blog-title {
      font-size: 3rem;
      color: ${(p) => p.theme.colors.primary};
      font-weight: bolder;
      @media ${device.tablet} {
        font-size: 2.5rem;
        margin-top: 1rem;
      }
      @media ${device.mobileL} {
        font-size: 2rem;
        margin-top: 1rem;
      }
    }

    .info-container {
      display: flex;
      margin-top: 3rem;
      margin-bottom: 3rem;
      .date {
        display: flex;
        font-size: 1rem;
        font-weight: 800;
        color: ${(props) => props.theme.colors.primary};
        align-items: center;
        margin-right: 1rem;
      }
      span {
        align-self: center;
        margin-right: 0.5rem;
        svg {
          width: 25px;
          height: 25px;
          align-self: center;
        }
      }
      .tags {
        display: flex;
        .tags-container {
          display: flex;
          color: ${(props) => props.theme.colors.primary};
          font-weight: 800;

          p {
            margin-right: 1rem;
            padding: 0.65rem;
            border-radius: 3px;
            background: #eaedf3;
            border: none;
            color: #244289;
          }
        }
      }
    }
    .header-image-container {
      max-width: 800px;
      width: 100%;
      height: 100%;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    figure {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        m  margin-top: 1rem;
    margin-bottom: 1rem;
      }
    }
    .blog-post-content {
      color: ${(p) => p.theme.colors.primary};
      p {
        line-height: 2rem;
        margin-top: 2rem;
    margin-bottom: 2rem;
        font-weight: 500;
      }
      h1,
      h2 {
        line-height: 4rem;
      }
      h1 {
        font-size: 3rem;
        font-weight: 500;
      }
      h2 {
        font-size: 3rem;
      }
      ul {
        padding-left: 2rem;
        li {
          line-height: 2rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          font-weight: 500;
        }
      }
      @media ${device.tablet} {
        h1,
        h2 {
          line-height: 2rem;
        }
        h1 {
          font-size: 2.5rem;
        }
        h2 {
          font-size: 2rem;
        }
      }
      @media ${device.mobileL} {
        h1 {
          font-size: 2.5rem;
        }
        h2 {
          font-size: 1.5rem;
        }
      }
    }
  }
`;
