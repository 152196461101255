import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Button/Button";
import { FormFieldContainer, MultiStepFormContainer } from "./Forms.style";
import { useFormData } from "../../Context/FormContext";
import LoaderComponent from "../Loader/LoaderComponent";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { useToken } from "../../utils/customHooks";

interface IMultiStepForm {
  data: any;
  campaignTitle: string;
}
interface MyWindow extends Window {
  dataLayer: any;
}
declare let window: MyWindow;
const FormCard = (props: any) => {
  const { children, currentStep, data } = props;
  return (
    <>
      <div className="step-wrapper">
        <div className="stepper-wrapper">
          {data &&
            data.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={`stepper-item ${
                    currentStep === index ? "completed" : ""
                  } `}
                >
                  <div className="step-counter">{index + 1}</div>
                  <div className="step-name">{item.title}</div>
                </div>
              );
            })}
        </div>
      </div>
      {children}
    </>
  );
};
const FormGenerator = (props: any) => {
  const {
    formStep,
    nextFormStep,
    listLength,
    setIsSubmitted,
    campaignTitle,
    index,
  } = props;
  //@ts-ignore
  const { setFormValues, data: dataForm } = useFormData();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token } = useToken();
  const getCurrentUrlWithoutProtocol = () => {
    var currentUrl = window.location.href;
    return currentUrl.replace(/^https?:\/\//i, "");
  };
  let currentUrl = getCurrentUrlWithoutProtocol();

  useEffect(() => {
    if (isSubmitting) {
      let commentContent: any = `Campaign Name: ${campaignTitle} \n`;
      dataForm.url = currentUrl;
      for (var key in dataForm) {
        if (dataForm.hasOwnProperty(key)) {
          commentContent += `${key} : ${dataForm[key]} \n`;
        }
      }
      let newData: any = {
        email: dataForm.email ? dataForm.email : "",
        firstname: dataForm.firstname ? dataForm.firstname : "",
        lastname: dataForm.lastname ? dataForm.lastname : "",
        memo: commentContent,
        phone: dataForm.phone ? dataForm.phone : "",
        website: "1",
        custbody_esc_campaign_category: -4,
        custbodyabco_campaign_name: campaignTitle,
        requestDemo: dataForm.requestDemo ? true : false,
        url: currentUrl,
      };

      if (token !== null) {
        if (token["utm_campaign"]) {
          newData["custbodyabco_campaign_name"] = token["utm_campaign"];
          newData["custbody_esc_campaign_category"] = -11;
        }
        if (token["gclid"]) {
          newData["custbody_esc_campaign_category"] = -15;
        }
      }
      fetch(
        // "https://6763524-sb2.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3264&deploy=1&compid=6763524_SB2&h=7764186a3cda8ce0fca5&action=createOpportunity",
        "https://6763524.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524&ns-at=AAEJ7tMQscoLnHY3TQgUcIofUDhsW0KGKedD0ocvSoJn0oybK3M&action=createOpportunity",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
            "User-Agent":
              "Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36",
          },
          body: JSON.stringify(newData),
        }
      )
        .then((res) => {
          // console.log(JSON.stringify(newData));
          setIsSubmitted(true);
          setIsSubmitting(false);
          if (window.dataLayer) {
            window.dataLayer.push({
              event: "form_submission",
              form_name: "campaign-form",
            });
          }
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm, setIsSubmitted, setIsSubmitting]);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ mode: "all" });

  const submitHandler = (values: any) => {
    setIsSubmitting(true);
    setFormValues(values);
  };

  const onSubmit = (values: any) => {
    setFormValues(values);
    nextFormStep();
  };

  return (
    <div className={formStep === index ? "showForm" : "hideForm"}>
      <h2 className="form-title">{props?.data.title}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isSubmitting ? (
          <LoaderComponent />
        ) : (
          <>
            {props.data.fieldList.map((field: any, index: number) => {
              if (field.fieldType === "select") {
                return (
                  <FormFieldContainer
                    key={index}
                    className="form-field-container"
                  >
                    <label htmlFor={field.fieldLabel}>
                      {field.fieldLabel}
                      {field.fieldRequired && "*"}
                    </label>
                    <div className="custom-select">
                      <select
                        id={field.fieldName}
                        placeholder={field.fieldPlaceholder}
                        {...register(field.fieldName, {
                          required: field.fieldRequired ? true : false,
                        })}
                      >
                        <option value="">--Please Select--</option>
                        {field.optionList.map((option: any, key: number) => (
                          <option key={key} value={option.value}>
                            {option.title}
                          </option>
                        ))}
                      </select>
                      <span className="custom-arrow"></span>
                    </div>
                    {errors[field.fieldName] && (
                      <span>{field.fieldLabel} is required</span>
                    )}
                  </FormFieldContainer>
                );
              }
              return (
                <FormFieldContainer
                  key={index}
                  className="form-field-container"
                >
                  <label htmlFor={field.fieldLabel}>
                    {field.fieldLabel}
                    {field.fieldRequired && "*"}
                  </label>
                  <input
                    type={field.fieldType}
                    id={field.fieldName}
                    placeholder={field.fieldPlaceholder}
                    {...register(field.fieldName, {
                      // onChange: (e) => (formData[field.fieldName] = e.target.value),
                      required: field.fieldRequired ? true : false,
                      min: field.fieldType === "number" ? 1000000 : 0,
                      pattern:
                        field.fieldType === "email"
                          ? {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address",
                            }
                          : undefined,
                    })}
                  />
                  {errors[field.fieldName] && (
                    <>
                      {errors[field.fieldName].type === "min" ? (
                        <span>{field.fieldLabel} must be 7 or more digits</span>
                      ) : errors[field.fieldName]?.message ? (
                        <span>{field.fieldLabel} is not valid</span>
                      ) : (
                        <span>{field.fieldLabel} is required</span>
                      )}
                    </>
                  )}
                </FormFieldContainer>
              );
            })}
          </>
        )}

        <div className="control-container">
          {formStep === listLength - 1 ? (
            <Button
              className={`campaign-next ${formStep === 0 && "first-step"}`}
              text="Submit"
              onClick={handleSubmit(submitHandler)}
              type="secondary"
            >
              <AiFillCaretRight style={{ marginLeft: "1rem" }} />
            </Button>
          ) : (
            <Button
              className={`campaign-next ${formStep === 0 && "first-step"}`}
              text="Next"
              type="secondary"
            >
              <AiFillCaretRight style={{ marginLeft: "1rem" }} />
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};
const MultiStepForm: FC<IMultiStepForm> = (props: IMultiStepForm) => {
  //* Keep in mind: MUST include these fields (firstname, lastname, phone, email)
  const [formStep, setFormStep] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const nextFormStep = () => setFormStep((currentStep) => currentStep + 1);

  const prevFormStep = () => setFormStep((currentStep) => currentStep - 1);
  let title = props.campaignTitle;
  let newData = [...props.data];
  return (
    <MultiStepFormContainer>
      <h2>Get in touch with our team today</h2>
      <p>Fill out the form below and we will contact you shortly</p>

      <FormCard
        currentStep={formStep}
        prevFormStep={prevFormStep}
        data={newData}
      >
        {newData.map((item: any, key: number) => {
          return (
            <FormGenerator
              key={key}
              last={newData.length - 1 === key ? true : false}
              isSubmitted={isSubmitted}
              setIsSubmitted={setIsSubmitted}
              campaignTitle={title}
              index={key}
              listLength={newData.length}
              formStep={formStep}
              nextFormStep={nextFormStep}
              prevFormStep={prevFormStep}
              data={item}
            />
          );
        })}

        {formStep > 0 && !isSubmitted && (
          <Button
            className="campaign-prev "
            text="Prev"
            onClick={prevFormStep}
            type="secondary"
            childrenLeft
            outlined
          >
            <AiFillCaretLeft style={{ marginRight: "1rem" }} />
          </Button>
        )}
        {/* <FormGenerator last={true} prevFormStep={prevFormStep} /> */}
        {isSubmitted && (
          <div>
            <div className="thank-you-step">
              <h1>Thank You</h1>
              <h2>We'll be in touch Shortly</h2>
            </div>
          </div>
        )}
      </FormCard>
    </MultiStepFormContainer>
  );
};

export default MultiStepForm;
