import styled from "styled-components";
import device from "../../utils/deviceScreenSizes";
export const OnlineAccountApplicationContainer = styled.div`
  .signature-container {
    width: 100%;
    margin: 0 auto;
  }
  .font-select {
    margin-left: auto;
    margin-bottom: 1rem;
    min-width: 150px;
  }
  .sig-title {
    text-align: left;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .select__input {
    color: transparent;
  }
  .signature-tabs {
    display: flex;
    list-style: none;
    padding: 0;
    cursor: pointer;
    .clear {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      font-size: 1.5rem;
      transition: 0.2s ease-in all;

      &:hover {
        transform: rotate(90deg);
      }
    }
  }

  .signature-tabs li {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
  }

  .signature-tabs .active {
    background-color: #fff;
    border-bottom: none;
  }

  .signature-content {
    border: 1px solid #ccc;
    padding: 20px;
  }

  .signature-canvas {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sigCanvas {
    border: 1px solid #ccc;
  }

  .signature-type {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signature-type input {
    width: 100%;
    padding: 10px;
    font-size: 24px;
    border: 1px solid #ccc;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    text-align: center;
    margin-bottom: 20px;
    background: transparent;
    font-size: 4rem;
    outline: none;
  }
  .terms-and-conditions {
    display: flex;
    flex-direction: column;

    .bold {
      font-weight: 700;
      color: black;
    }
    .terms-container > ol {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    ol {
      counter-reset: item;
    }
    li {
      display: block;
      font-size: 0.85rem;
      font-weight: 700;
    }
    li > p {
      display: inline;
    }
    ol li::before {
      content: counters(item, ".") ". ";
      counter-increment: item;
    }

    ol ol li::before {
      content: counters(item, ".") " ";
    }
    li ol {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-left: 20px;
      margin-top: 8px;
    }
  }
  .consent {
    font-size: 0.7rem;
    line-height: inherit;
    margin-top: 0.5rem;
    color: #8f8f8f;
    text-align: left;
  }
  .must-accept {
    color: red;
    padding: 0.5rem;
    background: #ffffff;
    border: 1px solid;
    margin-bottom: 1rem;
    display: block;
    width: 75%;
    margin: 0 auto;
  }
  .form-dynamic-button {
    max-width: 250px;
    margin: 0 auto;
    background: #244289;
    color: white;
    border-radius: 6px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    cursor: pointer;
    gap: 0.5rem;
    margin-bottom: 2rem;
  }
  .terms-container {
    margin-top: 1rem;
    border: 1px solid #ccc;
    height: 300px;
    overflow-y: scroll;
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    & > h4 {
      margin: 0;
    }
    p {
      font-size: 0.8rem;
      line-height: 1.2rem;
    }
    color: #242424;
    button {
      align-self: center;
    }
  }

  .checkbox-container {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.4rem;
  }

  .signature-controls {
    display: flex;

    & > p:first-child {
      font-weight: 700;
      font-size: 1.2rem;
    }

    .clear {
      margin-left: auto;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      transition: 0.2s ease-in all;

      &:hover {
        transform: rotate(90deg);
      }
    }
  }

  .tacbox {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    padding: 1em;
    border: 3px solid #ddd;
    background-color: #eee;
    max-width: 800px;
    margin-top: 2rem;
    input {
      height: 2em;
      width: 2em;
      margin-right: 1rem;
      vertical-align: middle;
    }
  }

  .logo-container {
    text-align: center;
    img {
      max-width: 300px;
      margin: 0 auto;
      padding: 1rem;
      padding-block: 2rem;
    }
  }
  .note-container {
    max-width: 550px;
    @media ${device.laptop} {
      padding: 0 0;
    }
    margin: 0 auto;

    p:nth-of-type(1) {
      font-size: 1.3rem;
      color: ${(p) => p.theme.colors.primary};
      font-weight: 700;
    }

    p:nth-of-type(2) {
      line-height: 1.2rem;
    }
  }
`;
export const OnlineAccountFormContainer = styled.div`
  padding-block: 2rem;

  h3 {
    background: #244289;
    color: white;
    padding-block: 0.5rem;
  }
  h4 {
    padding-block: 0.5rem;
    color: #242424;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.2rem;
  }
  .form-title {
    padding-bottom: 1rem;
    border-bottom: 1px solid #bdbdbd;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .form-field-container {
    padding-bottom: 1rem;
  }
  .custom-select {
    position: relative;

    select {
      font-size: 1rem;
      padding: 0.8rem 1rem;
      border: 1px solid #bdbdbd;
      border-radius: 2.5px;
      width: 100%;
      background: #f4f6f9;
    }

    .custom-arrow {
      position: absolute;
      width: 40px;
      height: 90%;
      background: #f4f6f9;
      right: 2px;
      top: 2px;
      pointer-events: none;

      &::after {
        position: absolute;
        content: "";
        border-style: solid;
        border-width: 7px;
        border-color: ${(p) => p.theme.colors.primary} transparent transparent
          transparent;
        top: 45%;
        left: 35%;
      }
    }
  }
  .campaign-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 33px;
    bottom: 32px;
    margin-top: 1rem;
    & > svg {
      font-size: 1.5rem;
    }
  }
  .thank-you-step {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-weight: 500;
      font-size: 4rem;
      text-decoration: underline #bdbdbd;
      text-underline-offset: 12px;
      text-decoration-thickness: 2px;
    }
    h2 {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 20rem;
      margin-top: 1rem;
    }
  }
  span {
    text-align: left;
    padding: 0.2rem;
    font-size: 0.8rem;
    color: red;
    min-height: 22px;
  }

  .campaign-submit.custom-btn {
    border: 2px solid #fff;
    background: #669933;
    color: #fff;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border: 2px solid #fff;
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.1s ease-in all;
    &:hover {
      background: ${(p) => p.theme.colors.primary};
      /* margin-top: 0; */
    }
  }

  .campaign-submit {
    width: 100%;
  }

  .close-button {
    display: flex;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    span {
      margin-left: auto;
      padding-right: 0;
      cursor: pointer;
    }
  }
  .submitBtn {
    padding: 1rem 2rem;
    border: 1px solid #fff;
    border-radius: 2.5px;
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    color: #fff;
    background: ${(props) => props.theme.colors.secondary};
    -webkit-transition: 0.1s ease-in all;
    transition: 0.1s ease-in all;
    display: block;
    width: 100%;
    &:hover {
      background: ${(props) => props.theme.colors.primary};
    }
  }
  max-width: 550px;
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.primary};
  margin: 0 auto;
  border-radius: 2.5px;
  .close {
    position: absolute;
    right: 45px;
    cursor: pointer;
    top: 25px;
    svg {
      font-size: 1.5rem;
    }
  }
  h2 {
    font-weight: 800;
    font-size: 1.5rem;
  }

  p {
    line-height: 32px;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
  }

  form {
    text-align: center;
    & > div {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
    input,
    textarea {
      background: #f4f6f9;
    }
    button {
      margin-top: 3rem;
      @media ${device.mobileL} {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
`;
