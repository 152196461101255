import React, { useState, useEffect } from "react";
import { Seo, PageBuilder } from "../../components";

import { sanityFetchSingleMainPage } from "../../utils/globalFunctions";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const About = () => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    sanityFetchSingleMainPage("about-us").then((res) => {
      setIsLoading(false);
      setData(res);
    });
  }, []);
  return (
    <div>
      <Seo
        title={data?.seo?.seo_title}
        description={data?.seo?.meta_description}
        keyword={data?.seo?.focus_keyword}
      />
      {!isLoading ? (
        <PageBuilder pageBuilder={data.pageBuilder} />
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};

export default About;
