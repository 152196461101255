import React, { useContext } from "react";
import { EmailSubscriptionContainer } from "./EmailField.style";
import { StateContext } from "../../Context/ContextProvider";
import Button from "../Button/Button";
const EmailSubscription = (props: any) => {
  const { setCatalogue, setCatalogueData } = useContext(StateContext);

  let fileUrl = "";
  if (props.file && props.file.asset) {
    fileUrl = `${props.file.asset.url}?dl=${
      props.file.fileName ? props.file.fileName : "Product-Catalogue"
    }.${props.file.asset.extension ? props.file.asset.extension : "pdf"}`;
  }

  const downloadHandler = (e: any) => {
    e.preventDefault();
    if (!props.emailSubscription) {
      window.open(`${fileUrl}`, "_blank");
    } else {
      // alert("openForm");
      setCatalogue(true);
      setCatalogueData({
        file: fileUrl,
        fileName: props.file.fileName,
        url: window.location.href,
      });
    }
    //? ADD FORM SUBMISSION HERE.
    //? NEED NETSUITE LINK.
    // window.open(`${fileUrl}`, "_blank");
  };
  return (
    <EmailSubscriptionContainer className={props.className}>
      <form
        onSubmit={(e) => {
          downloadHandler(e);
        }}
      >
        <div className="input-container">
          <Button
            className={`subscribe-btn download-btn primary long outlined `}
            type="primary"
            text={props.downloadButtonText}
            buttonType="submit"
          />
          {/* <LinkWrapper>
            <input
              className={`subscribe-btn download-btn primary long outlined `}
              type="submit"
              value={props.downloadButtonText}
            />
          </LinkWrapper> */}
        </div>
      </form>
    </EmailSubscriptionContainer>
  );
};

export default EmailSubscription;
