import styled from "styled-components";

export const FloatingWidgetContainer = styled.div`
  .motion-container {
    position: fixed;
    z-index: 999;
    right: -150px;
    bottom: 40%;
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    ul {
      list-style: none;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      a {
        display: flex;
        padding-right: 10px;
        align-items: center;
        font-size: 1rem;
        text-decoration: none;
        color: white;
        span {
          width: 45px;
          height: 45px;
          color: white;
          display: flex;
          margin-right: 10px;
          justify-content: center;
          align-items: center;
          font-size: 3rem;
        }
      }
    }
  }
`;
