import styled from "styled-components";
import { Link } from "react-router-dom";
import device from "../../utils/deviceScreenSizes";
import { motion } from "framer-motion";

export const TileLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
  overflow: hidden;
  .content-container {
    background: white;
    height: 100%;
    .tile-content {
      padding: 1rem;
      color: ${(p) => p.theme.colors.gray};
      h4 {
        text-align: center;
        padding-bottom: 0.5rem;
        font-weight: 800;
        font-size: 1.25rem;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      p {
        line-height: 32px;
        font-size: 1rem;
        font-weight: 400;
      }
      .continue {
        align-self: end;
        color: #244289;
        font-weight: 400;
        font-size: 1.25rem;
        /* text-underline-offset: 3px;
        -webkit-text-decoration: underline;
        text-decoration: underline; */
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        .animated-arrow {
          transform: rotate(270deg) translateY(-7px);
          .arrow {
            /* display: contents; */
            /* transform: rotate(270deg); */
          }
        }
      }
    }
    .tile-content.innovation,
    .tile-content.team,
    .tile-content.csr,
    .tile-content.governance {
      display: flex;
      flex-direction: column;
      height: 100%;

      p {
        flex: 1;
      }
      .continue {
        width: 100%;
      }
    }
    .tile-content.team {
      h4 {
        text-decoration: none;
        font-size: 1.5rem;
        font-weight: 800;
        color: #4f4f4f;
      }
      .position {
        font-size: 1.25rem;
        text-align: center;
        line-height: 32px;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      p {
        text-decoration: none;
      }
      .linked {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        .connect {
          font-size: 1.25rem;
          color: #244289;
          font-weight: 800;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          -webkit-text-decoration: underline;
          text-decoration: underline;
        }
      }
      .linkedIn {
        font-size: 2.5rem;
      }
    }
    .tile-content.machineryCategory {
      height: 100%;
      display: flex;
      flex-direction: column;
      color: ${(p) => p.theme.colors.gray};
      text-decoration: none;

      p {
        flex: 1;
      }

      .continue {
        width: 100%;
      }
      h3 {
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 1.2rem;
      }
      ul {
        list-style: none;
        li {
          padding-top: 1rem;
          padding-bottom: 1rem;
          font-weight: 500;
        }
      }
    }
    .tile-content.blog {
      height: 100%;
      display: flex;
      flex-direction: column;
      h2 {
        flex: 1;
        color: #244289;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: 1.5rem;
        font-weight: 800;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;
        padding-bottom: 0;
        margin-top: 0.5rem;
      }
      .continue {
        width: 100%;
      }
      .info-container {
        .date {
          display: flex;
          padding-bottom: 1rem;
          font-size: 1rem;
          font-weight: 800;
          color: ${(props) => props.theme.colors.primary};
          align-items: center;
          p {
            font-weight: 600;
          }
        }
        span {
          align-self: center;
          margin-right: 0.5rem;
          svg {
            width: 25px;
            height: 25px;
            align-self: center;
          }
        }
        .tags {
          display: flex;
          .tags-container {
            display: flex;
            color: ${(props) => props.theme.colors.primary};
            font-weight: 800;
            flex-wrap: wrap;
            p {
              margin-right: 1rem;
              margin-bottom: 0.5rem;
              padding-left: 0.65rem;
              padding-right: 0.65rem;
              background: #eaedf3;
              border: none;
              color: #244289;
            }
          }
        }
      }
    }
    .tile-content.machineryProduct {
      background: white;
      padding: 1.5rem;
      height: 100%;

      h3 {
        font-size: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-decoration: none;
        color: ${(p) => p.theme.colors.gray};
      }

      ul {
        list-style: none;
        li {
          border-bottom: 1px solid ${(p) => p.theme.colors.gray3};
          margin-bottom: 1rem;
          color: ${(p) => p.theme.colors.gray};
          .product-data {
            display: flex;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            font-size: 1rem;
            .left {
              margin-right: auto;
            }
            .right {
              font-weight: bold;
              color: ${(p) => p.theme.colors.gray};
            }
          }
        }
      }
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
    @media screen and (max-width: 675px) {
      flex-direction: column;
    }
    .content-container {
      max-width: 100%;
      /* padding: 1rem; */
      flex: 1;
    }
  }

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const TileImageContainer = styled(motion.div)`
  width: 100%;
  background: ${(p) => p.theme.colors.lightBlue};
  & > div {
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
  }
  @media ${device.tablet} {
    flex: 1;
  }
`;

export const TileNoLink = styled.div`

display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
  overflow: hidden;
  .content-container {
    background: white;
    height: 100%;
    .tile-content {
      padding: 1rem;
      color: ${(p) => p.theme.colors.gray};
      h4 {
        text-align: center;
        padding-bottom: 0.5rem;
        font-weight: 800;
        font-size: 1.25rem;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      p {
        line-height: 32px;
        font-size: 1rem;
        font-weight: 400;
      }
      .continue {
        align-self: end;
        color: #244289;
        font-weight: 400;
        font-size: 1.25rem;
        /* text-underline-offset: 3px;
        -webkit-text-decoration: underline;
        text-decoration: underline; */
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        .animated-arrow {
          transform: rotate(270deg) translateY(-7px);
          .arrow {
            /* display: contents; */
            /* transform: rotate(270deg); */
          }
        }
      }
    }
`;

export const TileWrapper = styled(motion.div)`
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  max-width: 366px;
  .marketing-price-container{
    display: flex;
    padding:1rem;
    align-items: center;
    .marketing-was {
      text-decoration: line-through;
    }
    .marketing-now {
      margin-left: auto;
    font-size: 2rem;
    color: red;

    }
  }
  .shop-btn {
    position: absolute;
    bottom: 30px;
    display: flex;
    padding-inline:.5rem;
    right: 20px;
    span {
      color: white;
    }
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    margin-bottom: 1rem;
  }

  @media ${device.tablet} {
    max-width: 600px;
    @media screen and (max-width: 675px) {
      max-width: 365px;
    }
  }
  .tile-content.consumables {
    display: flex;
    flex-direction: column;
    height: 100%;
    h4 {
      text-align: left;
      font-size: 2rem;
    }
    p {
      padding-block: 1rem;
    }
    .continue {
      margin-top: auto;
      margin-right: auto;
    }
  }
  .tile-content.machineryProduct {
    display: flex;
    flex-direction: column;
    height: 100%;
    h4 {
      text-align: left;
      font-size: 2rem;
    }
    p {
      padding-block: 1rem;
    }
    .continue {
      margin-top: auto;
      margin-right: auto;
    }
  }
  .tile-content.industries {
    display: flex;
    flex-direction: column;
    height: 100%;
    h4 {
      text-align: left;
      font-size: 2rem;
    }
    p {
      padding-block: 1rem;
    }
    .continue {
      margin-top: auto;
      margin-right: auto;
    }
  }
  .tile-not-link-container {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    height: 100%;
    .content-container {
      background: ${(p) => p.theme.colors.lightBlue};
      height: 100%;

      .tile-content {
        padding: 1rem;
        color: ${(p) => p.theme.colors.gray};
        h4 {
          text-align: center;
          padding-bottom: 0.5rem;
          font-weight: 800;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        p {
          line-height: 32px;
        }
        .continue {
          align-self: end;
          color: #244289;
          font-weight: 500;
          font-size: 1.25rem;
          text-underline-offset: 3px;
          -webkit-text-decoration: underline;
          text-decoration: underline;
          padding-top: 1rem;
          padding-bottom: 1rem;
          display: flex;
          .animated-arrow {
            transform: rotate(270deg) translateY(-7px);
            .arrow {
              /* display: contents; */
              /* transform: rotate(270deg); */
            }
          }
        }
      }

      .tile-content.machineryCategory {
        .hover-underline-animation {
          display: inline-block;
          position: relative;
        }

        .hover-underline-animation:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 4px;
          bottom: -4px;
          left: 0;
          background-color: ${(p) => p.theme.colors.primary};
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        .hover-underline-animation:hover:after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
        color: ${(p) => p.theme.colors.gray};
        text-decoration: none;
        .tile-link {
          text-decoration: none;
          color: ${(p) => p.theme.colors.gray};

          &:hover {
            color: ${(p) => p.theme.colors.primary};
            font-weight: 500;
          }
        }
        h3 {
          font-size: 2rem;
          font-weight: 800;
          margin-bottom: 1.2rem;
        }
        ul {
          list-style: none;
          li {
            padding-top: 1rem;
            padding-bottom: 1rem;
            font-weight: 500;
          }
        }
      }
    }

    @media ${device.tablet} {
      flex-direction: row;
      .content-container {
        max-width: 100%;
        /* padding: 1rem; */
        flex: 1;
      }
    }
    @media ${device.mobileL} {
      flex-direction: column;
    }
  }
`;
