import styled, { css } from "styled-components";
import { Container } from "../../Theme/globalStyle";
import device from "../../utils/deviceScreenSizes";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ISearch } from "../../interfaces";
export const Nav = styled.nav`
  .hide-dropdown {
    display: none !important;
  }
  box-shadow: 2px 2px 10px 0px #0000001a;
  overflow-x: clip;
  border: 1px solid #bdbdbd;
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.colors.white};
  z-index: 999;
  .sliding-contact-form {
    position: absolute;
    right: -2px;
    height: 100vh;
    max-width: 450px;
    padding: 2rem 2.5rem;
    border-left: 1px solid #bdbdbd;
    top: 0;
    z-index: 9;
    h2 {
      font-size: 1.3rem;
    }
    p {
      line-height: 26px;
    }
    label,
    input {
      font-size: 1rem;
    }
    textarea {
      font-size: 1rem;
    }
  }
  .back-to-top {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 0;
    background: ${(props) => props.theme.colors.primary};
    justify-content: center;
    opacity: 0.6;
    cursor: pointer;

    &: hover {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
    span {
      margin-top: 0.6rem;
      color: white;
      font-size: 2rem;
      transform: rotate(90deg);
    }
  }
`;
export const NavMenu = styled(motion.ul)`
  /* position: relative; */
  height: 100%;
  list-style-type: none;
  align-items: center;
  display: flex;
  margin-right: auto;
  transition: 1s ease all;
  &:hover {
    .gray-on-hover {
      /* color: ${(props) => props.theme.colors.gray2}; */
    }
    .icon-gray-on-hover {
      /* color: ${(props) => props.theme.colors.gray2}; */
      transition: 0.4s ease all;
    }
    /* :after {
      opacity: 1;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background: rgba(255, 255, 255, 0.7);
      pointer-events: none;
      z-index: 2;
    } */
  }

  @media ${device.laptop} {
    display: none;
  }
`;

export const NavbarContainer = styled.div`
  position: relative;
  .navbar {
    display: flex;
    align-items: center;
    /* position: sticky; */
    height: 75px;
    z-index: 999;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  /* ${Container} */
`;

export const NavLogo = styled(Link)`
  cursor: pointer;
  margin-right: auto;
  z-index: 9;
`;

export const NavIcon = styled.img`
  width: 100%;
  width: 150px;
  height: 40px;
`;

export const NavItem = styled(motion.li)`
  /* padding: 1rem; */
  list-style: none;
  &:hover {
    z-index: 55;
    .navlink {
      color: ${(props) => props.theme.colors.secondary};
      .icon-arrow {
        color: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;

export const NavLinks = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 1rem;
  height: 100%;
  align-items: center;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  white-space: nowrap;
  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
  @media ${device.custom_1268} {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;
export const NavDropdownContainer = styled.div`
  width: 100vw;
  height: 500px;
  background: red;
  left: 0;
  position: absolute;
`;
export const NavDropdownContent = styled.div`
  display: none;
  position: absolute;
  left: 0;
  background-color: white;
  min-width: 100%;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: 0.4s ease all;
  max-height: 550px;
  .dropdown-content {
    display: flex;
    margin: 0 auto;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    .dropdown-links {
      .dropdown-links-divider {
        margin-bottom: 1rem !important;
        max-width: 240px;
        margin: 0;
        padding: 0;
        & > div {
          &:hover {
            transition: 0.2s ease all;
            a {
              color: ${(props) => props.theme.colors.secondary};
            }
          }
        }
        .link-name {
          /* width: 175px; */
          white-space: nowrap;
          max-width: 175px;
          text-transform: capitalize;
          transition: 0.2s ease all;
          &:hover {
            color: ${(props) => props.theme.colors.secondary};
          }
        }
      }
      padding: 1rem;
      max-width: 600px;
      display: flex;
      flex: 8;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 550px;
      max-width: 750px;
      a {
        max-width: 320px;
      }
      .subcategory-links {
        font-weight: 400;
        max-width: 230px;
        display: flex;
        align-items: center;
        .arrow-right {
          float: right;
          transform: rotate(180deg);
          display: block;
          opacity: 0;
          margin-bottom: 5px;
        }
        &:hover {
          .arrow-right {
            /* display: initial; */
            opacity: 1;
            transition: 0.4s ease all;
          }
        }
      }
    }
    .catalogue {
      flex: 1;
      max-width: 280px;
      margin-left: auto;
      background: ${(p) => p.theme.colors.lightBlue};
      display: flex;
      flex-direction: column;
      padding-bottom: 2rem;
      justify-content: start;
      align-items: center;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 100vw;
        height: 100%;
        background: ${(p) => p.theme.colors.lightBlue};
        z-index: 8;
        left: 0;
      }
      .image-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
        max-width: 200px;
        max-height: 200px;
        z-index: 99;
      }
      .text-container {
        text-align: center;
        line-height: 32px;
        font-size: 1.5rem;
        color: ${(p) => p.theme.colors.primary};
        font-weight: bold;
        z-index: 99;
        transition: ease 0.2s all;
        cursor: pointer;
        &:hover {
          color: ${(p) => p.theme.colors.secondary};
        }
      }
    }
    .filler {
      flex: 1;
      max-width: 195px;
    }
    @media ${device.custom_1268} {
      .catalogue {
        padding-left: 1rem;
        .text-container {
          font-size: 1rem;
        }
      }
    }
  }
  /* .content {
    display: contents;
    width: 820px;
    margin: 0 auto;
  } */
`;

export const NavDropdown = styled(motion.div)`
  overflow: hidden;
  z-index: 2;
  &:hover ${NavDropdownContent} {
    display: flex;
    z-index: 2;
    flex-direction: row;
    flex-wrap: wrap;
    /* padding: 1rem; */
    /* max-height: 300px; */
    transition: 0.4s ease all;
  }
`;

export const NavDropdownLinks = styled(Link)`
  display: flex;
  position: relative;
  color: ${(props) => props.theme.colors.primary};
  padding-right: 0.5rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  font-size: 1.25rem;
  text-decoration: none;
  font-weight: bold;
  transition: 75ms ease-in-out all;
  .underline {
    border-bottom: 3px solid transparent;
    max-width: 0;
  }
  .icon-arrow-dropdown {
    color: ${(props) => props.theme.colors.secondary};
  }
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    /* background: ${(props) => props.theme.colors.primary}; */
    .underline {
      border-bottom: 3px solid ${(props) => props.theme.colors.primary};
      transition: 0.3s ease 0s, left 0.3s ease 0s;
      max-width: 100%;
    }
  }
`;
export const NavDropdownHashLinks = styled(HashLink)`
  display: block;
  position: relative;
  color: ${(props) => props.theme.colors.primary};
  padding-right: 0.5rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-decoration: none;
  font-weight: bold;
  transition: 75ms ease-in-out all;
  .underline {
    border-bottom: 3px solid transparent;
    max-width: 0;
  }
  .icon-arrow-dropdown {
    color: ${(props) => props.theme.colors.secondary};
  }
  &:hover {
    color: ${(props) => props.theme.colors.primary};
    /* background: ${(props) => props.theme.colors.primary}; */
    .underline {
      border-bottom: 3px solid ${(props) => props.theme.colors.primary};
      transition: 0.3s ease 0s, left 0.3s ease 0s;
      max-width: 100%;
    }
  }
`;

export const NavBurgerIcon = styled.div`
  display: none;

  @media ${device.laptop} {
    padding-right: 0.3rem;
    display: block;
    font-size: 1.5rem;
    margin-left: auto;
    cursor: pointer;
  }
`;

export const SearchContainer = styled(motion.div)<ISearch>`
  font-size: 1.3rem;
  cursor: pointer;
  padding-right: 0;
  display: flex;
  span {
    padding: 1rem;
  }
`;
export const SearchContainerWrapper = styled(Container)<ISearch>`
  display: flex;
  min-height: 75px;
  .searchClose {
    font-size: 1.4rem;
    padding: 1rem;
    color: ${(props) => props.theme.colors.white};
    align-self: center;
    cursor: pointer;
  }
  .search-container {
    margin-left: auto;
    align-self: center;
  }
  .searchIcon {
    position: absolute;
    top: 26px;
    padding-left: 0.8rem;
    font-size: 1.4rem;
  }
  .btn-container {
    align-self: center;
    button {
      padding: 0.7rem;
      border-radius: 0;
      border: none;
    }
  }
  input {
    margin: 0 auto;
    width: 100%;
    height: 41px;
    padding: 0 20px;
    font-size: 1rem;
    /* border: 1px solid ${(props) => props.theme.colors.primary}; */
    outline: none;
    padding-left: 3rem;
    &:focus {
      border: 1px solid ${(props) => props.theme.colors.primary};
      transition: 0.35s ease;
      color: ${(props) => props.theme.colors.primary};
      &::-webkit-input-placeholder {
        transition: opacity 0.45s ease;
        opacity: 0;
      }
      &::-moz-placeholder {
        transition: opacity 0.45s ease;
        opacity: 0;
      }
      &:-ms-placeholder {
        transition: opacity 0.45s ease;
        opacity: 0;
      }
    }
  }
`;

export const CtaContainer = styled(motion.div)<ISearch>`
  /* display: block; */
  /* ${({ searchClicked }) =>
    searchClicked ? `display:none` : `display:block`}; */
  position: relative;
  z-index: 9;
  @media ${device.laptop} {
    display: none;
  }
  .shop-btn {
    display: flex;
    span {
      color: white;
      font-size: 1.2rem;
    }
    @media ${device.custom_1268} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`;
export const CtaContainerMobile = styled(motion.div)<ISearch>`
  /* display: block; */
  /* ${({ searchClicked }) =>
    searchClicked ? `display:none` : `display:block`}; */
  position: relative;
  z-index: 9;
  display: block;
  padding: 1rem 1.5rem;
  a {
    /* max-width: 150px; */
    margin: 0 auto;

    justify-content: center;
    padding-left: 3rem;
    padding-right: 3rem;

    text-align: center;
  }
  .shop-btn {
    display: flex;
    span {
      color: white;
      font-size: 1.2rem;
    }
    @media ${device.custom_1268} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`;

export const SearchFieldWrapper = styled(motion.div)`
  position: absolute;
  min-height: 75px;
  z-index: 1010;
`;

export const SearchOverlay = styled(motion.div)<ISearch>`
  /* display: none; */
  position: absolute;
  width: 100%;
  background: ${(props) => props.theme.colors.transparentGray};
  top: 0;
  z-index: 1010;
  /* ${({ searchClicked }) => searchClicked && css``} */
`;

export const CatalogueFormWrapper = styled(motion.div)`
  position: absolute;
  max-width: 550px;
  right: 0;
  height: 90vh;
  background: ${(props) => props.theme.colors.primary};
  padding: 1.6rem 3rem;
  color: ${(props) => props.theme.colors.white};
`;

export const Backdrop = styled(motion.div)`
  background: blue;
  width: 100vw;
  height: 100vh;
  display: none;

  transform: translateX(-100vw);
`;
export const NavbarCatalogueImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const TabletNavbar = styled(motion.div)`
  .tablet-nav-content {
    height: 100%;
  }
  .tablet-navbar-search-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 30px;
    padding-right: 30px;
    .search-form {
      max-width: 100%;
    }
    .search-input {
      padding-block: 0.5rem;
      font-size: 1.2rem;
      border: 1px solid gray;
    }
    .search-container {
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }
  ${({ theme }) => css`
    @supports (-webkit-touch-callout: none) {
      position: static;
    }
  `}
  ${({ theme }) => css`
    @supports not (-webkit-touch-callout: none) {
      position: absolute;
    }
  `}
  overflow: hidden;
  left: 0;
  background-color: #f9f9f9;
  min-width: 100%;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;
