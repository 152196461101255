/* eslint-disable */
// @ts-ignore
// src/components/pageBuilder.js
import BlockContent from "@sanity/block-content-to-react";
import React, { useContext } from "react";
import { IIcon } from "../../interfaces";
import {
  TwoSection,
  VideoSection,
  Button,
  Banner,
  HeroSection,
  GallerySection,
  Icons,
  TechnicalSpecification,
  CardsAndSection,
  TileListing,
  CoreValues,
  MarketingSection,
  Testimonials,
} from "../";
import { StateContext } from "../../Context/ContextProvider";
import { urlFor } from "../../utils/globalFunctions";
import { Link } from "react-router-dom";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";

const PageBuilder = (props: any) => {
  const { setCatalogue, setContactForm, setCatalogueData, catalogueData } =
    useContext(StateContext);
  const { pageBuilder, page } = props;

  const serializersPost = {
    marks: {
      internalLink: ({ mark, children }: { mark: any; children: any }) => {
        const { slug = {} } = mark;
        const href = `/${slug.current}`;
        return <Link to={mark.href}>{children}</Link>;
      },
      image: ({ mark, children }: { mark: any; children: any }) => {
        const { asset = {} } = mark;
        const { url = "" } = asset;
        return (
          <img
            src={urlFor(asset).format("webp").width(1200).url()}
            alt={mark.alt}
            className="img-fluid"
          />
        );
      },
      link: ({ mark, children }: { mark: any; children: any }) => {
        // Read https://css-tricks.com/use-target_blank/
        const { blank, href } = mark;
        return blank ? (
          <Link
            to={{ pathname: href }}
            target="_blank"
            rel="noreferrer noopener"
          >
            {children}
          </Link>
        ) : (
          <a href={href}>{children}</a>
        );
      },
    },
    types: {
      youtube: ({ node }: { node: any }) => {
        const { url } = node;
        const id: any = getYouTubeId(url);
        const style = {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };
        return (
          <YouTube videoId={id} style={style} className="youtube-player" />
        );
      },
      image: ({ node }: { node: any }) => {
        const { asset = {} } = node;
        const { url = "" } = asset;
        console.log(node);
        return (
          // <h1> TEST</h1>
          <figure>
            {node.url ? (
              <a href={node.url} target="_blank">
                <img
                  style={{ minWidth: "100%" }}
                  src={urlFor(asset).width(1200).url()}
                  alt={asset.alt}
                />
              </a>
            ) : (
              <img
                style={{ minWidth: "100%" }}
                src={urlFor(asset).width(1200).url()}
                alt={asset.alt}
              />
            )}
          </figure>
        );
      },
    },
    ignoreUnknownTypes: true,
  };
  const serializers = {
    marks: {
      internalLink: ({ mark, children }: { mark: any; children: any }) => {
        const { slug = {} } = mark;
        const href = `/${slug.current}`;
        return <a href={href}>{children}</a>;
      },
      link: ({ mark, children }: { mark: any; children: any }) => {
        // Read https://css-tricks.com/use-target_blank/
        const { blank, href } = mark;
        return blank ? (
          <a href={href} target="_blank" rel="noopener">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        );
      },
    },
    listItem: (props?: any, key?: number) =>
      props.type === "bullet" ? (
        <li key={key}>{props.children}</li>
      ) : (
        <li key={key}>
          <Icons type={IIcon.check} />
          {props.children}
        </li>
      ),
    ignoreUnknownTypes: true,
    unknownType: (props: any, key: number) => {
      switch (props.node._type) {
        case "pageBuilderButtonList":
          return (
            <PageBuilder
              key={key}
              pageBuilder={[props.node]}
              serializer={serializers}
            />
          );
        // break;
      }
    },

    // _type: (props: any) => console.log("serializer", props),
  };

  const determineButtonFunction = (block: any) => {
    let returnedFunction;
    if (block.buttonFunction) {
      if (block.buttonFunction === "downloadCatalogue") {
        // block.fileDownload && setCatalogue(block.fileDownload.asset.url);

        returnedFunction = function downloadFunc() {
          setCatalogue(true);
          if (block.fileDownload.asset) {
            setCatalogueData({
              file: `${block.fileDownload.asset.url}?dl=${
                block.fileDownload.fileName
                  ? block.fileDownload.fileName
                  : "Product-Catalogue"
              }.pdf`,
              fileName: block.fileDownload.fileName
                ? block.fileDownload.fileName
                : "Product-Catalogue",
              url: window.location.href,
            });
          }
        };
        // return;
      }
      if (block.buttonFunction === "enquire") {
        returnedFunction = setContactForm;
      }
      if (block.buttonFunction === "redirectExternal") {
        returnedFunction = function () {
          window.open(block.externalURL, "_blank");
        };
      }
      if (block.buttonFunction === "redirect") {
        // returnedFunction = console.log("redirect");
      }
      if (block.buttonFunction === "redirectToSection") {
        // returnedFunction = console.log("redirect");
        // console.log("redirectToSection", block);
      }
      return returnedFunction;
    } else {
      return null;
    }
  };
  if (!pageBuilder) return null;
  // 'raw' content needs to be passed in for the PortableText Component
  return pageBuilder.map((block: any, key: number) => {
    switch (block._type) {
      case "pageBuilderTwoSection":
        let twoSectionData = {
          title: "test",
          content: block.content,
          images: block.imagesGallery,
          singleImage: block.image,
          imageRight: block.imageRight,
          doubleImageList: block.doubleImageList,
          backgroundGray: block.backgroundGray ? block.backgroundGray : false,
          buttons: block.pageBuilder,
          leftContent: block.leftContent,
        };
        return (
          <TwoSection
            key={key}
            type={block.type}
            data={twoSectionData}
            pageBuilder
            reverse={block.imageRight ? true : false}
            lightBlue={block.backgroundGray ? block.backgroundGray : false}
          />
        );
      case "pageBuilderCardListing":
        return (
          <CardsAndSection
            key={key}
            type={block.type}
            data={block.pageBuilderBasic || block.pageBuilderTeam}
            lightBlue={block.backgroundGray ? block.backgroundGray : false}
          >
            {block.listingTitle || block.title}
          </CardsAndSection>
        );
      case "pageBuilderHeroSection":
        return (
          <HeroSection
            file={block.fileDownload}
            reverse={block.reverse}
            key={key}
            title={block.heroTitle}
            image={urlFor(block.image)}
            emailSubscription={block.emailSubscription}
            subContent={block.subContent}
            large={block.largeSubcontent}
            downloadButtonText={block.downloadButtonText}
            from="pageBuilder"
            subscribe={block.subscribe}
          />
        );
      case "pageBuilderVideo":
        // console.log(block);
        return (
          <VideoSection
            key={key}
            url={block.url}
            videoLabel={block.videoLabel && block.videoLabel}
            videoThumbnail={block.videoThumbnail}
          />
        );
      case "pageBuilderTestimonial":
        return <Testimonials key={key} data={block.testimonial} />;
      case "pageBuilderButton":
        let clickFunction = determineButtonFunction(block);
        console.log(block);
        return (
          <Button
            key={key}
            block={block}
            type={block.buttonType}
            toSection={block.toSection}
            to={block.externalURL}
            external={block.externalURL && true}
            size="long"
            outlined={block?.outlined && block?.outlined}
            className={block.className}
            text={block.buttonText}
            mailTo={block.mailTo}
            onClick={clickFunction}
          />
        );
      case "pageBuilderBanner":
        return <Banner key={key} pageBuilder data={block} />;
      case "pageBuilderMarketingSection":
        return <MarketingSection key={key} data={block.content} />;
      case "pageBuilderButtonList":
        return (
          <div key={key} className="btn-container">
            <PageBuilder
              key={key}
              pageBuilder={block.buttonList}
              // @ts-ignore
              serializer={serializers}
            />
          </div>
        );
      case "pageBuilderGallerySection":
        let galleryProps = {
          content: block.content,
          images: block.imageGallery,
          backgroundGray: block.backgroundGray,
          imageRight: block.imageRight,
        };
        return <GallerySection key={key} data={galleryProps} />;
      case "postBuilderImage":
        return (
          <div
            key={key}
            className="header-image-container skeleton-image-loader"
          >
            <img src={block && urlFor(block.postImage)} alt={block.title} />
          </div>
        );
      case "postBuilderContent":
        // console.log(block.postBlockContent);
        return (
          <div className="post-block-content">
            <BlockContent
              key={key}
              blocks={block.postBlockContent}
              serializers={serializersPost}
              projectId="1s6e2mzd"
              dataset="production"
            />
          </div>
        );
      case "pageBuilderTileListing":
        if (block.type === "article") {
          block.type = "csr";
        }
        // return <h1>TEST</h1>;
        return (
          <TileListing
            key={key}
            type={block.type}
            data={
              block.consumableArticlePageBuilder ||
              block.articleList ||
              block.teamList ||
              block.servicingList ||
              block.machineryList ||
              block.governanceList
            }
            lightBlue={block.backgroundGray}
            displayAll={block.displayAll}
            typeAll={block.typeAll}
          >
            {block.listingTitle && <BlockContent blocks={block.listingTitle} />}
          </TileListing>
        );
      case "pageBuilderCoreValues":
        return <CoreValues data={block} />;
      case "block":
        // console.log("block", block);
        return (
          <BlockContent
            key={key}
            blocks={block}
            serializers={serializersPost}
          />
        );
      case "pageBuilderTechnicalSpecification":
        return <TechnicalSpecification key={key} data={block.technicalData} />;
    }
  });
};

export default PageBuilder;
