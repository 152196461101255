import { IIcon } from "../../interfaces";
import Icons from "../Icons/Icons";
import { motion } from "framer-motion";
import { FloatingWidgetContainer } from "./FloatingWidget.style";
import { Link } from "react-router-dom";

const FloatingWidget = () => {
  const containerVariants = {
    // rest: {
    //   x: 0, // Starting position, off-screen to the left
    //   opacity: 0.7, // Optional: You can adjust the opacity as you like
    //   transition: {
    //     duration: 0.2,
    //     type: "tween",
    //     ease: "easeIn",
    //   },
    // },
    hover: {
      x: -150, // End position, sliding in from the left to the center
      opacity: 1, // Optional: You can adjust the opacity as you like
      transition: {
        duration: 0.2,
        type: "tween",
        ease: "easeOut",
      },
    },
  };

  return (
    <FloatingWidgetContainer>
      <motion.div
        variants={containerVariants}
        className="motion-container"
        // initial={{ x: "-150" }}
        // animate={{ x: "calc(100vw - 50%)" }}
        whileHover="hover"
        whileTap="hover"
      >
        <ul>
          <li>
            <Link
              to={{ pathname: "https://issuu.com/abcopro.com.au" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons type={IIcon.book} />
              View Catalogues
            </Link>
          </li>
          <li>
            <Link to="/about-us/book-anappointment">
              <Icons type={IIcon.cursor} />
              Book Appointment
            </Link>
          </li>
          <li>
            <Link to="/equipment-hires">
              <Icons type={IIcon.vacuum} />
              Equipment Hire
            </Link>
          </li>
          <li>
            <Link to="/about-us/esg-and-sustainability">
              <Icons type={IIcon.leaf} />
              ESG initiatives
            </Link>
          </li>
        </ul>
      </motion.div>
    </FloatingWidgetContainer>
  );
};

export default FloatingWidget;
