import React, { useEffect, useState } from "react";
import { Seo, PageBuilder } from "../../components";
import { CsrContainer } from "./CSR.style";
import { sanityFetchSingleMainPage } from "../../utils/globalFunctions";
import LoaderComponent from "../../components/Loader/LoaderComponent";
const CSR = () => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityFetchSingleMainPage("csr-programs")
      .then((res) => {
        setIsLoading(false);
        setData(res);
      })
      .catch((err) => setIsLoading(false));
  }, []);
  return (
    <CsrContainer>
      <Seo
        title={data?.seo?.seo_title}
        description={data?.seo?.meta_description}
        keyword={data?.seo?.focus_keyword}
      />
      {!isLoading ? (
        <PageBuilder pageBuilder={data.pageBuilder} />
      ) : (
        <LoaderComponent />
      )}
    </CsrContainer>
  );
};

export default CSR;
