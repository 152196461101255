import styled from "styled-components";
import { Container } from "../../Theme/globalStyle";
import device from "../../utils/deviceScreenSizes";
import { IconContainer } from "../Icons/Icons.style";
import { Link } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";

export const Nav = styled.nav`
  border-top: 1px solid ${(props) => props.theme.colors.gray2};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray2};
`;

export const TopNavbarContainer = styled(Container)`
  display: flex;
  align-items: center;
  height: 50px;
  z-index: 998;
  background: ${(props) => props.theme.colors.white};
  @media ${device.mobileL} {
    padding: 0;
  }
  .top-navbar-search-bar{
    width: 100%;
    max-width: 414px;
    @media ${device.laptop} {
      display: none;
    }
  }
  .search-container {
    border-right:none !important;
  }
  .phone {
    min-width: 115px;
    @media ${device.tablet} {
      min-width: 100%;
    }
  }
  ${Container}
`;

export const TopNavbarSocial = styled.div`
  // margin-right: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopNavbarSocialLink = styled(Link)`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  font-size: 1.5rem;
  border-inline: 1px solid ${(props) => props.theme.colors.gray3};
  transition: 1s ease-in-out all;
  &:hover ${IconContainer} {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const TopNavbarInfo = styled.div`
  margin-left: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopNavbarInfoItem = styled.div`
  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-inline: 1px solid ${(props) => props.theme.colors.gray3};
    padding: 0.5rem;
    color: ${(props) => props.theme.colors.primary};
  }
  &:hover {
    a {
      color: ${(props) => props.theme.colors.secondary};
    }

    span {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  ${IconContainer} {
    font-size: 1.3rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  @media ${device.tablet} {
    .info {
      display: none;
    }
  }
`;
export const TopNavDropdownHashLinks = styled.a`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-inline: 1px solid ${(props) => props.theme.colors.gray3};
  padding: 0.5rem;
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    a {
      color: ${(props) => props.theme.colors.secondary};
    }

    span {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  ${IconContainer} {
    font-size: 1.3rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  @media ${device.tablet} {
    .info {
      display: none;
    }
  }
`;
