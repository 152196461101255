import React from "react";
import { LoadingSpinnerContainer } from "./Forms.style";
const LoadingSpinner = () => {
  return (
    <LoadingSpinnerContainer className="spinner-container">
      <div className="loading-spinner"></div>
    </LoadingSpinnerContainer>
  );
};

export default LoadingSpinner;
