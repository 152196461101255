import React, { useEffect, useState } from "react";
import { Seo, PageBuilder } from "../../components";
import { sanityFetchAllByType } from "../../utils/globalFunctions";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { MachineryPostContainer } from "./Machinery.style";

const Machinery = () => {
  const [machineryData, setMachineryData]: any = useState();
  useEffect(() => {
    let fetchMachineryData = sanityFetchAllByType("machineryPage");
    fetchMachineryData.then((res) => {
      setMachineryData(res[0]);
    });
  }, []);

  if (machineryData === undefined) {
    return <LoaderComponent />;
  }
  return (
    <MachineryPostContainer>
      <Seo
        title={machineryData.seo.seo_title}
        description={machineryData.seo.meta_description}
        keyword="Machinery"
      />
      <PageBuilder pageBuilder={machineryData.pageBuilder} />
    </MachineryPostContainer>
  );
};

export default Machinery;
