import React, { FC } from "react";
import { Section } from "..";
import { TechnicalSpecificationContainer } from "./TechnicalSpecification.style";

interface ITechnicalSpecification {
  data: any[];
}

const TechnicalSpecification: FC<ITechnicalSpecification> = ({ data }) => {
  return (
    <TechnicalSpecificationContainer>
      <Section>
        <h2>Technical Specification</h2>
        <div className="specification-container">
          <ul>
            {data.map((specification, key) => (
              <li key={key}>
                <div className="specification-description">
                  {specification.technicalDataDescription}
                </div>
                <div className="lines"></div>
                <div className="specification-value">
                  <b>{specification.technicalDataValue}</b>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Section>
    </TechnicalSpecificationContainer>
  );
};

export default TechnicalSpecification;
