import styled from "styled-components";
import { Container } from "../../Theme/globalStyle";
import device from "../../utils/deviceScreenSizes";

interface ICoreSection {
  isGray?: boolean;
}

export const CoreSection = styled.section<ICoreSection>`
  background: ${(p) => (p.isGray ? p.theme.colors.lightBlue : "white")};
`;

export const CoreContainer = styled(Container)`
  color: ${(p) => p.theme.colors.primary};
  h2 {
    padding-top: 4rem;
    text-align: center;
    font-size: ${(props) => props.theme.fontSizes.large};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 500;
  }
  .sub {
    padding-top: 3rem;
    padding-bottom: 3rem;
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
  }
  .core-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    @media ${device.tablet} {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      grid-gap: 1rem;
    }
    padding-top: 5rem;
    padding-bottom: 5rem;

    .core-card {
      color: ${(p) => p.theme.colors.gray2};
      border: 1px solid #bdbdbd;
      padding: 2rem;
      background: white;

      flex: 1;

      .top {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        img {
          margin: 0 auto;
          max-width: 70px;
        }

        p {
          font-weight: bold;
          font-size: 1.25rem;
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          color: ${(p) => p.theme.colors.gray};
        }
      }

      p {
        line-height: 32px;
        color: ${(p) => p.theme.colors.gray};
      }
    }
  }
`;
