import React, { FC } from "react";
import { IIconProps } from "../../interfaces";
import {
  FaAngleDown,
  FaBars,
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaLinkedin,
  FaPhone,
  FaEnvelope,
  FaSearch,
  FaClock,
  FaTimes,
  FaWrench,
  FaUtensils,
  FaHandHoldingHeart,
  FaUserFriends,
  FaPiggyBank,
  FaRegHandshake,
  FaCartPlus,
  FaHandHoldingUsd,
  FaCheckCircle,
  FaSearchDollar,
  FaKey,
  FaClipboardList,
  FaChartLine,
  FaAngleUp,
  FaCalendarCheck,
  FaTag,
  FaArrowLeft,
  FaPlus,
  FaMinus,
  FaPlay,
  FaSortDown,
  FaShoppingCart,
  FaMapMarkerAlt,
  FaEye,
  FaHireAHelper,
  FaLeaf,
  FaYoutube,
} from "react-icons/fa";
import { GiOpenBook, GiVacuumCleaner } from "react-icons/gi";
import { RiCursorFill } from "react-icons/ri";
import { IconContainer } from "./Icons.style";

const Icons: FC<IIconProps> = ({
  type,
  color,
  size,
  className,
  onClick,
  variants,
}) => {
  let icon;
  switch (type) {
    case "FaAngleDown":
      icon = <FaAngleDown />;
      break;
    case "FaYoutube":
      icon = <FaYoutube />;
      break;
    case "FaBars":
      icon = <FaBars />;
      break;
    case "FaFacebook":
      icon = <FaFacebook />;
      break;
    case "FaInstagram":
      icon = <FaInstagram />;
      break;
    case "FaLinkedinIn":
      icon = <FaLinkedinIn />;
      break;
    case "FaPhone":
      icon = <FaPhone />;
      break;
    case "FaEnvelope":
      icon = <FaEnvelope />;
      break;
    case "FaSearch":
      icon = <FaSearch />;
      break;
    case "FaClock":
      icon = <FaClock />;
      break;
    case "FaTimes":
      icon = <FaTimes />;
      break;
    case "FaLinkedin":
      icon = <FaLinkedin />;
      break;
    case "FaWrench":
      icon = <FaWrench />;
      break;
    case "FaUtensils":
      icon = <FaUtensils />;
      break;
    case "FaHandHoldingHeart":
      icon = <FaHandHoldingHeart />;
      break;
    case "FaUserFriends":
      icon = <FaUserFriends />;
      break;
    case "FaPiggyBank":
      icon = <FaPiggyBank />;
      break;
    case "FaRegHandshake":
      icon = <FaRegHandshake />;
      break;
    case "FaCartPlus":
      icon = <FaCartPlus />;
      break;
    case "FaHandHoldingUsd":
      icon = <FaHandHoldingUsd />;
      break;
    case "FaCheckCircle":
      icon = <FaCheckCircle />;
      break;
    case "FaSearchDollar":
      icon = <FaSearchDollar />;
      break;
    case "FaKey":
      icon = <FaKey />;
      break;
    case "FaClipboardList":
      icon = <FaClipboardList />;
      break;
    case "FaChartLine":
      icon = <FaChartLine />;
      break;
    case "FaAngleUp":
      icon = <FaAngleUp />;
      break;
    case "FaCalendarCheck":
      icon = <FaCalendarCheck />;
      break;
    case "FaTag":
      icon = <FaTag />;
      break;
    case "FaArrowLeft":
      icon = <FaArrowLeft />;
      break;
    case "FaPlus":
      icon = <FaPlus />;
      break;
    case "FaMinus":
      icon = <FaMinus />;
      break;
    case "FaPlay":
      icon = <FaPlay />;
      break;
    case "FaSortDown":
      icon = <FaSortDown />;
      break;
    case "FaShoppingCart":
      icon = <FaShoppingCart />;
      break;
    case "RiCursorFill":
      icon = <RiCursorFill />;
      break;
    case "FaMapMarkerAlt":
      icon = <FaMapMarkerAlt />;
      break;
    case "FaEye":
      icon = <FaEye />;
      break;
    case "FaLeaf":
      icon = <FaLeaf />;
      break;
    case "FaHireAHelper":
      icon = <FaHireAHelper />;
      break;
    case "GiOpenBook":
      icon = <GiOpenBook />;
      break;
    case "GiVacuumCleaner":
      icon = <GiVacuumCleaner />;
      break;
    default:
      icon = <FaAngleDown />;
  }

  return (
    <IconContainer
      variants={variants}
      onClick={onClick}
      className={className ? className : ""}
    >
      {icon}
    </IconContainer>
  );
};

export default Icons;
