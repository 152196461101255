/* eslint-disable no-lone-blocks */

import React, { useRef } from "react";
import { Link } from "react-router-dom";

const TermsAndConditions = ({
  onAccept,
  acceptedTerms,
  setAcceptedTerms,
}: any) => {
  // const [acceptDisabled, setAcceptDisabled] = useState(true);
  const termsRef = useRef(null);

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const hasScrolledToBottom = scrollTop + clientHeight >= scrollHeight;
    console.log(hasScrolledToBottom);
    // setAcceptDisabled(!hasScrolledToBottom);
  };
  {
    /* <li>
            <p>
             TEST
            </p>
            <ol>
              <li>
                <p>sub li element</p>
              </li>
              <li>
                <p>sub li element</p>
              </li>
              <li>
                <p>sub li element</p>
              </li>
            </ol>
          </li> */
  }
  return (
    <div className="terms-and-conditions">
      <div className="terms-container" onScroll={handleScroll} ref={termsRef}>
        {/* Add your terms and conditions content here */}
        <h4>TERMS AND CONDITIONS</h4>
        <p className="bold">GENERAL</p>

        <p className="bold">Application</p>
        <ol>
          <li>
            <p>
              These Terms and Conditions (‘T&C’) apply to all contracts between
              Abco Products Pty Ltd (ACN 147 347 019) (‘Abco’) and the customer
              (‘Customer’) of any:
            </p>
            <ol>
              <li>
                <p>
                  Goods or products <span className="bold">(‘Goods’)</span>; or
                </p>
              </li>
              <li>
                <p>
                  Rent of products <span className="bold">(‘Rental’)</span>; or
                </p>
              </li>
              <li>
                <p>
                  Service agreement <span className="bold">(‘Service’)</span>;
                  or
                </p>
              </li>
              <li>
                <p>
                  Training service <span className="bold">(‘Training’)</span>;
                  or
                </p>
              </li>
            </ol>
          </li>
          <p className="bold">Interpretation</p>
          <li>
            <p>In these T&C:</p>
            <ol>
              <li>
                <p>
                  <span className="bold">ACL</span> means the Australian
                  Consumer Law which is contained in Schedule 2 of the
                  Competition and Consumer Act 2010 (Cth);
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Business Day</span> means a day other
                  than a Saturday, Sunday or Public Holiday in the State or
                  Territory where the Goods and Services are supplied.
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">CCA</span> means the Competition and
                  Consumer Act 2010 (Cth);
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Customer</span> means a person who
                  acquires Goods and Services from Abco;
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Customer Order</span> means any order
                  or instruction given by the Customer to Abco for the supply of
                  Goods and Services, including, without limitation, any
                  document entitled “Purchase Order” or any other document
                  containing the description of the required Goods and Services,
                  to be supplied by Abco to the Customer;
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Dollars</span> means Australian Dollars
                  (AUD$);
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Event of Default</span> means the
                  occurrences set out in clause 50;
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Goods and Services</span> means any
                  item or service of whatsoever nature supplied or to be sold or
                  rented by Abco to the Customer;
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">GST</span> means the tax payable on
                  certain goods within the meaning of the A New Tax System
                  (Goods and Services Tax) Act 1999 (Cth);
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Metropolitan Area</span> means the area
                  which, in Abco’s opinion, is within the metropolitan area of
                  Adelaide, Brisbane, Melbourne, Perth or Sydney.
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Special Order</span> means items that
                  are not normally kept in stock by Abco and may be subject to
                  additional costs.
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Payment</span> means any payment by the
                  Customer for Goods and Services.
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">Payment Terms</span> means Terms that
                  have been agreed with Abco.
                </p>
              </li>
              <li>
                <p>
                  <span className="bold">PPSA</span> means the Personal Property
                  Securities Act 2009 (Cth);
                </p>
              </li>
            </ol>
          </li>
          <p className="bold">Formation of contract</p>
          <li>
            <p>
              Abco expressly rejects and is not bound nor is any contract formed
              between the parties by any conditional order made by the Customer.
            </p>
          </li>
          <li>
            <p>
              A binding contract is formed when Abco receives each and any
              Purchase Order and either sends a written acceptance to the
              Customer or supplies the Goods and Services pursuant to a Purchase
              Order, whichever occurs first and that contract will be bound by
              these T&C.
            </p>
          </li>
          <p className="bold">Orders and prices</p>
          <li>
            <p>
              Abco reserves the right to accept or decline, in whole or in part,
              any Purchase Order for Goods and Services placed by the Customer.
            </p>
          </li>
          <li>
            <p>
              The Customer waives any claim for shortage of any Goods and
              Services supplied by Abco if the Customer has not lodged a written
              claim with Abco within 7 days from the date of receipt of the
              Goods and Services by the Customer.
            </p>
          </li>
          <li>
            <p>
              Unless otherwise stated, all prices for Goods and Services are
              exclusive of all applicable taxes and charges. Where the Goods and
              Services supplied are subject to GST, the Customer is liable to
              pay any applicable amount of GST at the same time as Payment is
              made.
            </p>
          </li>
          <li>
            <p>
              The prices of any Goods and Services and any related
              specifications, are indicated by the price and specifications
              contained on Abco’s website and/or any price-list, pamphlet or
              brochure or any information provided by Abco to the Customer and
              are subject to change at the absolute discretion of Abco without
              notice to the Customer. The final price shall be that contained in
              any final quotation or document supplied to the Customer in direct
              relation to the Goods and Services.
            </p>
          </li>
          <li>
            <p>
              The Customer acknowledges and warrants that they are purchasing
              the Goods and Services for resale or commercial purposes and not
              for personal, household, or domestic use.
            </p>
          </li>
          <li>
            <p>
              Orders must be made using Abco Code Number, as stated in the
              Pricelist of Abco or on the website of Abco. Abco will not accept
              responsibility for wrongly supplied products where codes are
              incorrect or not quoted when placing orders.
            </p>
          </li>
          <li>
            <p>
              Goods and Services ordered are the responsibility of the Customer
              to ensure the correct order is placed. Abco will endeavour to
              clarify the order but will not be liable for any costs/damage
              whatsoever for incorrect orders being placed.
            </p>
          </li>
          <li>
            <p>
              Orders placed for collection by the Customer will be returned to
              stock if not collected within 60 days of being notified the order
              is ready for collection. Any orders returned to stock will, where
              the Customer has paid, will attract a credit but no refund will be
              offered. A restocking fee may be charged and deducted from any
              credit given.
            </p>
          </li>
          <p className="bold">Delivery</p>
          <li>
            <p>
              All orders are subject to delivery fees payable by the Customer,
              unless agreed otherwise by Abco.
            </p>
          </li>
          <li>
            <p>
              Abco will endeavour to dispatch all Goods and Services within any
              timeframe stipulated in a Purchase Order; however, it will not be
              responsible for any loss or damage arising directly or indirectly
              from the transit of the Goods and Services arranged by the
              Customer or the receiver of the Goods and Services.
            </p>
          </li>
          <li>
            <p>
              Any time or date provided by Abco for completion, delivery, and/or
              dispatch is an estimate only and does not constitute a
              representation, or term of the contract, nor shall it be part of
              the description of the Goods and Services and is not of the
              essence of the contract. All branded pallets remain the
              responsibility of the Customer and the freight company.
            </p>
          </li>
          <li>
            <p>
              Pallets must be returned to Abco or exchanged in the same
              condition as received. Chep or Loscam pallets not returned will
              have a replacement cost charged to the Customer as a debt owing to
              Abco.
            </p>
          </li>
          <li>
            <p>
              Abco is deemed to have delivered the Goods and Services (whichever
              occurs first) when they are:
            </p>
            <ol>
              <li>
                <p>collected by the Customer; or</p>
              </li>
              <li>
                <p>
                  made available for unloading at the Customer’s nominated
                  delivery address.{" "}
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              Deliveries outside the Metropolitan area, unless at the Customer’s
              expense, will be by carrier selected by Abco. At the sole
              discretion of Abco, the Customer may nominate a preferred carrier.
            </p>
          </li>
          <li>
            <p>
              The Customer is liable to pay for deliveries outside the
              Metropolitan area subject to the application of the published
              freight rate or as agreed between Abco and the Customer.
            </p>
          </li>
          <li>
            <p>
              It is the Customer’s responsibility to ensure the Goods and
              Services are suitable and sufficient for the Customer’s purpose.
              Abco bears no liability for incorrect selection by the Customer
              and does not warrant that the Goods and Services are suitable for
              the Customer’s purpose.
            </p>
          </li>
          <p className="bold">Payment</p>
          <li>
            <p>
              Time for Payment for Goods and Services supplied by Abco is of the
              essence.
            </p>
          </li>
          <li>
            <p>
              Unless otherwise agreed in writing by Abco, Payment for the Goods
              and Services must be made by the Customer in accordance with
              invoices issued by Abco to the Customer. Abco may, at its absolute
              discretion, require the Customer to pay a deposit. Where a deposit
              is required, this must be paid by the Customer prior to supply by
              Abco.
            </p>
          </li>
          <li>
            <p>
              Where no payment terms have been agreed in writing by Abco,
              payment in full will be required prior to the dispatch of any
              Goods and Services except where only a Service is provided,
              payment will be due and payable in full at the completion of the
              Service.
            </p>
          </li>
          <li>
            <p>
              At the end of each month, the Customer will receive a statement of
              account (<span className="bold">Statement</span>), which indicates
              the total amount payable. Subject to the agreed Payment Terms
              between the parties, the balance of the{" "}
              <span className="bold">Statement</span> is payable in full and
              shall only be treated as being paid once the Payment has been
              received as cleared funds by Abco.
            </p>
          </li>
          <p className="bold">Billing</p>
          <li>
            <p>
              The Customer acknowledges and agrees that where a quote is
              requested, a quotation fee may be payable if the job does not
              proceed.
            </p>
          </li>
          <li>
            <p>
              The Customer acknowledges and agrees there may be additional fees
              payable in addition to the quoted price, including but not limited
              to sundries and environmental levies.
            </p>
          </li>
          <li>
            <p>
              The Customer acknowledges and agrees that additional charges,
              including but not limited to freight delivery, may be payable for
              non-stock items.
            </p>
          </li>
          <li>
            <p>
              The Customer acknowledges and agrees there may be additional
              charges incurred for the assembly of Goods and Services purchased.
            </p>
          </li>
          <li>
            <p>
              Where the Customer has provided budget data, Abco will not be
              liable for any errors in the Customer’s budget data, and the
              Customer acknowledges and agrees that where their order exceeds
              their budget, Abco will not be liable for the excess and agrees to
              pay Abco the invoiced amount.
            </p>
          </li>
          <p className="bold">Risk and retention of title</p>
          <li>
            <p>
              Unless otherwise provided in these T&C, the risk in the Goods and
              Services supplied by Abco will pass to the Customer on delivery of
              the Goods and Services.
            </p>
          </li>
          <li>
            <p>
              The Customer agrees that title to Goods and Services is retained
              by Abco:
            </p>
            <ol>
              <li>
                <p>
                  until it receives Payment in full for the Goods and Services;
                  or
                </p>
              </li>
              <li>
                <p>at all times for Goods and Services that are rented; and</p>
              </li>
              <li>
                <p>
                  until all other monies owing by the Customer to Abco are paid
                  in full.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              Where the Goods and Services comprise a number of items, the title
              of Abco in all items comprising the Goods and Services will not
              pass to the Customer until Payment in full is received for all
              items and all other monies owed by the Customer have been paid in
              cleared funds.
            </p>
          </li>
          <li>
            <p>
              Prior to title in the Goods and Services passing to the Customer,
              the Customer agrees that:
            </p>
            <ol>
              <li>
                <p>
                  it acts as bailee of the Goods and Services until such time as
                  title in them passes to the Customer and that this bailment
                  continues for each item of the Goods and Services;
                </p>
              </li>
              <li>
                <p>
                  it must keep the Goods and Services in good and merchantable
                  condition and fully insure the Goods and Services against loss
                  or damage however caused;
                </p>
              </li>
              <li>
                <p>
                  it must not sell the Goods and Services except with the prior
                  written consent of Abco;
                </p>
              </li>
              <li>
                <p>
                  it must not create any encumbrance over the Goods and Services
                  which is inconsistent with Abco’s title and ownership of the
                  Goods and Services.
                </p>
              </li>
            </ol>
          </li>
          <p className="bold">No sale by description</p>
          <li>
            <p>
              All descriptions, specifications and illustrations or any other
              information in relation to the Goods and Services contained in
              catalogues, price lists or other advertising material of Abco or
              elsewhere are approximations only and intended by Abco to be a
              general description for information and identification purposes
              and do not create a sale by description.
            </p>
          </li>
          <p className="bold">Returns and cancellations</p>
          <li>
            <p>
              Any cancelled Purchase orders, except Special Orders (Special
              Orders are not returnable), will result in the Customer being
              liable for the greater of a $30.00 processing fee or 10% of the
              order price for the costs of restocking. Special Orders cannot be
              cancelled or returned and the full amount is payable unless Abco
              exercises its sole discretion to waive or discount the amount
              payable. The Customer must bear any costs associated with the
              return of Goods and Services, including freight costs which must
              be paid by the Customer prior to returning the Goods or Services.
            </p>
          </li>
          <li>
            <p>
              Except as provided otherwise by the ACL, returns of all Goods and
              Services are subject to prior approval of Abco at its absolute
              discretion upon a written request by the Customer made within 7
              days from the date of delivery of Goods and Services. Goods must
              be unused, undamaged and in their original packaging and will be
              subject to inspection by Abco quality control team who will have
              the final say as to the condition of the Goods and Services.
            </p>
          </li>
          <li>
            <p>
              Servicing of Goods and Services may require order of parts. Any
              specially ordered parts required for the service cannot be
              cancelled once ordered. Any service that is cancelled prior to
              completion will incur the costs of the portion of the job
              completed and the parts used or ordered.
            </p>
          </li>

          <p className="bold">Warranties and liability</p>
          <li>
            <p>
              The Customer must inspect all Goods and Services immediately on
              delivery and must notify Abco in writing of any inaccuracies,
              short supply or fault, damage or defect in the goods or rental
              items within 7 days of the delivery. The Customer will be deemed
              to have accepted the Goods and Services thereafter.
            </p>
          </li>
          <li>
            <p>
              If the Customer fails to notify Abco under clause 38, Abco will
              not be liable for any loss or damage arising out of or resulting
              from such inaccuracies or short supply of goods or rental items.
            </p>
          </li>
          <li>
            <p>
              The Customer acknowledges that any manufacturer warranty may be
              voided if the manufacturer’s recommended maintenance programs on
              the goods or rental items are not complied with.
            </p>
          </li>
          <li>
            <p>
              Certain guarantees cannot be excluded under the ACL. You are
              entitled to a replacement or refund for a major failure and
              compensation for any other reasonably foreseeable loss or damage.
              You are also entitled to have the goods repaired or replaced if
              the Goods and Services fail to be of acceptable quality and the
              failure does not amount to a major failure.
            </p>
          </li>
          <li>
            <p>
              Abco makes no express warranties or representations in relation to
              the fitness for purpose of the Goods and Services.
            </p>
          </li>
          <li>
            <p>
              Abco will not be liable in any way whatsoever for the consequence
              of any representation or conduct made in connection with the Goods
              and Services whether by its employees, agents or sub-contractors
              or otherwise to the Customer or any third parties in relation to
              fitness for purpose. The Customer agrees that all such
              representations and/or advice are/is accepted or relied upon by
              the Customer entirely at the Customer’s risk.
            </p>
          </li>
          <li>
            <p>
              Abco’s liability to the Customer or any third parties (whether
              arising under statute, contract, tort (including negligence),
              equity or otherwise) for any defect in the Goods and Services, or
              the quality of the Goods and Services, will in all cases be
              limited to the cost of repair or replacement of such Goods and
              Services.
            </p>
          </li>
          <li>
            <p>
              Subject to clause 47, to the extent permitted by statute, all
              warranties, T&C and guarantees (whether express, implied or
              applied, and whether given by Abco, the manufacturer or a third
              party) pertaining to or in connection with the Goods and Services
              and any obligation of Abco to replace or refund any Goods and
              Services again are excluded.
            </p>
          </li>
          <li>
            <p>Abco will not be liable for the following:</p>
            <ol>
              <li>
                <p>
                  any consequential loss or damage of any kind suffered by the
                  Customer as a result of the supply of the Goods and Services;
                </p>
              </li>
              <li>
                <p>
                  any loss of profits, loss of anticipated profits, loss of
                  business, loss of reputation or goodwill, business
                  interruption of the Customer;
                </p>
              </li>
              <li>
                <p>
                  failure of Abco to perform any of its obligations if such a
                  delay or failure is due to anything beyond Abco’s reasonable
                  control;
                </p>
              </li>
              <li>
                <p>
                  any losses caused directly or indirectly by any failure or
                  breach by the Customer; and/or
                </p>
              </li>
              <li>
                <p>
                  any loss relating to the choice of the goods and how they meet
                  the Customer’s purpose;
                </p>
              </li>
              <li>
                <p>
                  any losses caused by unavailability or outage of Abco’s
                  website, inaccuracies in the Abco website or use of Abco’s
                  website or App generally;
                </p>
              </li>
              <li>
                <p>
                  in any circumstances, any claim that exceeds the total amount
                  of the price paid for the Goods and Services.
                </p>
              </li>
            </ol>
          </li>
          <p className="bold">Indemnity and Waiver</p>
          <li>
            <p>
              The Customer agrees to indemnify and forever hold Abco harmless
              from and against all liabilities, losses, damages, costs or
              expenses incurred or suffered by Abco, and from and against all
              actions, proceedings, claims or demands made against Abco,
              arising:
            </p>
            <ol>
              <li>
                <p>
                  as a result of the Customer’s failure to comply with any laws,
                  rules, standards or regulations applicable in relation to the
                  Goods and Services or the use of the goods or rental items;
                </p>
              </li>
              <li>
                <p>
                  as a result of any other negligence or other breach of duty by
                  the Customer in connection with the Goods and Services;
                </p>
              </li>
              <li>
                <p>
                  directly or indirectly from the Customer’s conduct; and/or
                </p>
              </li>
              <li>
                <p>as a result of any breach of these T&C by the Customer.</p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              Any failure of Abco to enforce any of its rights under these T&C
              does not operate as a waiver of those rights.
            </p>
          </li>
          <p className="bold">Default and termination</p>
          <li>
            <p>
              In the event of default by the Customer, Abco may terminate the
              Contract after providing 5 Business Days’ written notice to the
              Customer to remedy the default or breach.
            </p>
          </li>
          <li>
            <p>
              Each of the following occurrences constitutes an event of default:
            </p>
            <ol>
              <li>
                <p>
                  the Customer breaches or is alleged to have breached these T&C
                  for any reason (including, but not limited to, defaulting on
                  any Payment);
                </p>
              </li>
              <li>
                <p>
                  the Customer, being a natural person, commits an act of
                  bankruptcy;
                </p>
              </li>
              <li>
                <p>the Customer, being a corporation, is subject to:</p>
                <ol>
                  <li>
                    <p>
                      a petition being presented, an order being made or a
                      meeting being called to consider a resolution for the
                      Customer to be wound up, deregistered or dissolved;
                    </p>
                  </li>
                  <li>
                    <p>
                      a receiver, receiver and manager or an administrator under
                      Part 5.3A of the Corporations Act 2001 (Cth) being
                      appointed to all or any part of the Customer's property
                      and undertaking; or
                    </p>
                  </li>
                  <li>
                    <p>
                      the entering of a scheme of arrangement (other than for
                      the purpose of restructuring);
                    </p>
                  </li>
                  <li>
                    <p>
                      the Customer purports to assign its rights under these T&C
                      without Abco's prior written consent;
                    </p>
                  </li>
                  <li>
                    <p>
                      the Customer ceases/threatens to cease conduct of its
                      business in the normal manner.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <p>
              On the occurrence of an event of default, all invoices issued by
              Abco to the Customer will become immediately due and payable as a
              debt to Abco.
            </p>
          </li>
          <li>
            <p>
              Where an event of default occurs, until Payment in full has been
              received by Abco, Abco may:
            </p>
            <ol>
              <li>
                <p>
                  terminate any or all orders and credit arrangements (if any)
                  with the Customer;
                </p>
              </li>
              <li>
                <p>refuse to supply Goods and Services;</p>
              </li>
              <li>
                <p>
                  repossess any Goods and Services supplied to the Customer, the
                  Payment for which has not been received;
                </p>
              </li>
              <li>
                <p>
                  retain (where applicable) all money paid on account of Goods
                  and Services or otherwise;
                </p>
              </li>
              <li>
                <p>
                  demand interest on unpaid Payments at Commonwealth Bank
                  maximum overdraft rate for sums up to $50,000.00 and such
                  interest shall be recoverable by Abco as a debt from the
                  Customer.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              Subject to clause 52 and the PPSA, if the Customer fails to pay
              any amount of the Customer’s Payment(s) when it/they is/are due to
              Abco, Abco may without notice and without prejudice to any of its
              other rights and remedies recover and/or resell the goods. For
              that purpose, the Customer:
            </p>
            <ol>
              <li>
                <p>
                  authorises Abco (without prior notice to the Customer) to
                  enter any premises where Goods and Services, the title in
                  which remains in Abco (‘Abco’s Property’) may be situated and
                  to use such reasonable efforts as may be required to enter any
                  such premises and to take possession of Abco’s Property;
                </p>
              </li>
              <li>
                <p>
                  undertakes to Abco to procure the consent of all persons
                  having any interest in the premises where Abco’s Property may
                  be situated, to entry of those premises by Abco;
                </p>
              </li>
              <li>
                <p>
                  indemnifies and keeps indemnified Abco from all claims,
                  actions or suits whatsoever out of or in connection with or in
                  relation to the entry by Abco to any premises where Abco’s
                  Property may be situated;
                </p>
              </li>
              <li>
                <p>
                  shall reimburse Abco for all costs and losses incurred by it
                  in recovering and/or reselling Abco’s Property.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              If the Customer adds any parts or accessories to Abco’s Property,
              Abco shall become the owner of those added parts and accessories
              as security for full Payment by the Customer of all monies due to
              Abco for Abco’s Property and the term Abco’s Property for the
              purpose of this subclause shall be appropriately read in
              conjunction with these T&C and construed accordingly.
            </p>
          </li>
          <li>
            <p>
              If the Customer substantially transforms Abco’s Property, Abco
              shall become the owner of those new Goods and Services or other
              goods as security for full Payment by the Customer of all monies
              due to Abco for Abco’s Property and the term Abco’s Property for
              the purpose of this subclause shall be appropriately read in
              conjunction with these T&C and construed accordingly.
            </p>
          </li>
          <p className="bold">Notice</p>
          <li>
            <p>
              Where, pursuant to these T&C, a party is required to give notice
              to the other party, such notice must be in writing and it is
              deemed to have been given and served on a party:
            </p>
            <ol>
              <li>
                <p>
                  if delivered personally, on the same day on which it was
                  delivered;
                </p>
              </li>
              <li>
                <p>
                  if sent by post, it is taken to have been given on the day it
                  would have been delivered in the ordinary course of post;
                </p>
              </li>
              <li>
                <p>
                  if sent by fax, at the time recorded on the transmitting
                  machine;
                </p>
              </li>
              <li>
                <p>
                  if sent electronically, at the “time of dispatch” within the
                  meaning of section 13 of the Electronic Transactions Act 2001
                  (WA).
                </p>
              </li>
            </ol>
          </li>
          <p className="bold">Compliance</p>
          <li>
            <p>
              Abco acknowledges that where the ACL applies with respect to Goods
              and Services, certain guarantees and rights are provided by the
              CCA and ACL:
            </p>
            <ol>
              <li>
                <p>
                  any rights provided to the Customer by the ACL or CCA that by
                  statute cannot be excluded that are inconsistent with any term
                  of these T&C are to prevail over any terms of these T&C to the
                  extent of the inconsistency;
                </p>
              </li>
              <li>
                <p>
                  nothing in these T&C purports to derogate from or exclude any
                  rights provided by the ACL or CCA that by statute cannot be
                  excluded or modified.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              The Customer acknowledges and agrees that Abco may register a
              security interest in the Goods and Services at any time before or
              after delivery of the Goods and Services. The Customer waives its
              right under section 157 of the PPSA to receive notice of any
              verification of the registration.
            </p>
          </li>
          <li>
            <p>
              If the Customer defaults in the performance of any obligation owed
              to Abco under these T&C, Abco may enforce its security interest in
              any Goods and Services by exercising all or any of its rights
              under these T&C or the PPSA.
            </p>
          </li>
          <li>
            <p>
              The Customer must promptly do anything reasonably required by Abco
              including, but not limited to, executing documents to ensure that
              Abco’s security interest is a perfected security interest and has
              priority over all other security interests in the Goods and
              Services.
            </p>
          </li>
          <li>
            <p>
              Nothing in clauses 62-64 is limited by any other provision of
              these T&C or any other agreement between the parties. If a term
              used in these clauses has a particular meaning in the PPSA, it has
              the same meaning in those clauses.
            </p>
          </li>
          <li>
            <p>
              The Customer must comply with all laws, bylaws and statutory and
              regulating authority applicable to the Customer and in particular
              without limitation all those relating to the environment, safety,
              dangerous goods, storage, handling, insurance and sale of goods
              supplied by the Abco under these T&C and any Condition of Sale.
            </p>
          </li>
          <li>
            <p>
              To the extent permitted by law, the Customer and Abco agree that
              the following provisions of the PPSA do not apply to the
              enforcement by Abco of its security interest in the Goods and
              Services: sections 95, 118, 121(4), 125, 130, 132(3)(d), 132(4),
              135, 137, 142 and 143.
            </p>
          </li>
          <p className="bold">Recovery of costs</p>
          <li>
            <p>
              Any expenses, costs or disbursements incurred by Abco in
              recovering any outstanding monies owing by the Customer, including
              debt collection fees and solicitor-client legal costs, must be
              paid by the Customer on a full indemnity basis.
            </p>
          </li>
          <p className="bold">Variations</p>
          <li>
            <p>
              These T&C may be varied unilaterally by Abco at any time and in
              its sole discretion.
            </p>
          </li>
          <li>
            <p>
              Any variations to these T&C will apply to every Purchase order
              made by the Customer and accepted by Abco after the expiry of 7
              days’ notice of the variation to the Customer. Notwithstanding any
              other provision of these T&C, notice may be affected for the
              purpose of this clause by provision of the new T&C as varied or by
              publication on Abco’s website.
            </p>
          </li>
          <li>
            <p>
              No variation to these T&C will apply with respect to any Purchase
              Order already made by the Customer before notice is given under
              clause 66.
            </p>
          </li>
          <p className="bold">Privacy and Credit Reporting</p>
          <li>
            <p>
              Abco may collect information in relation to the Customer, for the
              purpose of providing the Goods and Services, in accordance with
              the laws relating to the collection and disclosure of personal
              information under the Privacy Act 1998 (Cth) as amended from time
              to time.
            </p>
          </li>
          <li>
            <p>
              Where the Goods and Services are supplied on credit, the Customer
              irrevocably authorises Abco, its employees and/or agents to make
              such inquiries as are deemed necessary to investigate the
              Customer’s creditworthiness, including (without limiting) the
              making of inquiries from persons nominated as trade referees,
              bankers, or any other credit providers{" "}
              <span className="bold">(‘Information Sources’)</span> or credit
              reporting agencies, and the Customer hereby authorises the
              Information Sources to disclose such information to Abco.
            </p>
          </li>
          <li>
            <p>
              Abco affirms that data collated in relation to the Customer, for
              the purpose of providing the Goods and Services will be stored in
              secure databases including but not limited to the Customer Abco
              Portal Database and Asset Data in accordance with the laws
              relating to the storage of personal information under the Privacy
              Act 1998 (Cth).
            </p>
          </li>
          <li>
            <p>
              Abco may track the Goods and Services through live GPS tracking of
              the machines, as this allows Abco to collect data in relation to
              machine remote diagnostic information, and this data will only be
              used in accordance with the laws relating to the usage of such
              information under the Privacy Act 1998 (Cth), for its intended
              purpose.
            </p>
          </li>
          <li>
            <p>
              If the Customer requests a custom feature be created on the Goods
              and Services, Abco becomes and remains at all times the exclusive
              owner of the intellectual property created from the implementation
              of the feature. The Customer grants the intellectual property
              rights to Abco created for the custom feature.
            </p>
          </li>
          <p className="bold">Force Majeure</p>
          <li>
            <p>
              Abco will not be liable for any delay in or failure of performance
              of supply or supply of Goods and Services caused by circumstances
              beyond its control including, but not limited to,
              strikes/industrial disputes, lock-outs, labour disturbances, acts
              of government, pandemic, riot, civil war, commotion, fire, flood,
              failure of power supply or similar event and shall not be liable
              for any consequential loss, damage or claim suffered by the
              Customer or any other party as a result of such event.
            </p>
          </li>
          <p className="bold">Severance and Governing Law</p>
          <li>
            <p>
              If a court of competent jurisdiction decides that any part of
              these T&C is invalid or unenforceable, then that part of the T&C
              will be modified (if possible) so as to make it enforceable. If it
              is cannot be modified, then it will be severed and the rest of the
              T&C will continue to operate.
            </p>
          </li>
          <li>
            <p>This contract is governed by the laws of Western Australia.</p>
          </li>
          <li>
            <p>
              Any legal action in relation to each contract against any party or
              its property may be brought in any court of competent jurisdiction
              in the state of Western Australia, and the parties submit to the
              jurisdiction of that state.
            </p>
          </li>
          <li>
            <p>
              To the extent permitted by law, the Sale of Goods Act 1985 (WA)
              does not apply to any contract between Abco and the Customer.
            </p>
          </li>
          <p className="bold">Entire Agreement</p>
          <li>
            <p>
              These T&C will prevail over any terms, T&C, representations or
              understandings between the Customer and Abco in relation to the
              Goods and Services, whether or not any inconsistency arises.
            </p>
          </li>
          <li>
            <p>
              Every contract for the supply of Goods and Services pursuant to
              any Purchase Order or Abco document containing the description of
              the Goods and Services incorporates these T&C and constitutes the
              entire agreement between Abco and the Customer. All prior
              negotiations, proposals, previous dealings, correspondence, trade
              custom and/or trade usage are superseded by and will not affect
              the interpretation of the Contract.
            </p>
          </li>
          <li>
            <p>
              The Customer acknowledges, represents and warrants to Abco that in
              any decision or matter concerning or in connection with any Goods
              and Services it has relied solely on the terms expressed in these
              T&C and in writing in an Purchase Order for the supply of Goods
              and Services and has not relied on any prior negotiations,
              proposals, understandings, representations, or conduct of Abco or
              Abco’s employees or agents.
            </p>
          </li>
          <p className="bold">ADDITIONAL SPECIFIC SECTIONS</p>
          <p className="bold">RENTAL</p>
          <li>
            <p>
              In relation to rental of the products, the Customer is the Lessee
              and Abco is the Lessor.
            </p>
          </li>
          <li>
            <p>
              The hire of the products commences from the date specified in the
              Rental Agreement and continues until the equipment is in Abco’s
              possession and control and the equipment is serviceable and in
              good working condition.
            </p>
          </li>
          <li>
            <p>Extension of the rental period will occur where:</p>
            <ol>
              <li>
                <p>agreed in writing by both parties; or</p>
              </li>
              <li>
                <p>
                  the Customer retains possession of the hire product(s) after
                  the expiry of the agreed hire period, in which case the hire
                  period will be extended for an additional month and renew for
                  a further month at the expiry of each additional month’s
                  rental until notice is provided by Abco in accordance with
                  clause 84.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              The Customer agrees to return the equipment upon demand from Abco
              and the Rental Agreement may be terminated at any time by Abco by
              providing the Customer 5 Business Days’ notice.
            </p>
          </li>
          <li>
            <p>
              The Customer must insure any Rental products or agree to pay Abco
              for such.
            </p>
          </li>
          <li>
            <p>
              Rental Products returned in an unclean state will incur a cleaning
              fee.
            </p>
          </li>
          <li>
            <p>
              Delivery and pick up will attract additional charges and the
              Customer acknowledges and agrees these charges may vary based on
              the delivery location and the product being delivered.
            </p>
          </li>
          <li>
            <p>
              The Customer indemnifies and holds harmless Abco from any damage,
              injury or death caused by the operation of the Rental products.
            </p>
          </li>
          <li>
            <p>
              Billing and billing cycles will be in accordance with Payment and
              Billing in these T&C with the exception that Payments for Rentals
              will only be via credit card or direct debit.
            </p>
          </li>
          <li>
            <p>
              The customer accepts liability for the loss and damage of the
              Rental products and any subsequent loss of ongoing rental charges,
              irrespective of how the damage occurred, including general wear
              and tear of the Rental Products, while in the Customer’s
              possession and agrees to fully reimburse Abco for replacement
              value or cost of repair.
            </p>
          </li>
          <li>
            <p>
              Abco does not provide any guarantee to the condition, safety or
              suitability of the Rental products supplied and will attempt to
              rectify faults where possible on the job sites. In cases of minor
              damage and cleaning, the Customer agrees to accept any additional
              charges incurred. Where there is a breakdown of the Rental
              products, the Customer releases Abco from any liability caused by
              the breakdown and Abco will attempt to repair or replace the
              Rental product as soon as practically possible. The Customer is
              liable for the cost of any new hired equipment not returned to
              Abco.
            </p>
          </li>
          <li>
            <p>
              The Customer acknowledges and accepts that consumable items
              including but not limited to chemicals and brushes are not
              included in the Contract and will be incurred as an extra charge.
            </p>
          </li>
          <p className="bold">SERVICE</p>
          <li>
            <p>
              Abco will provide servicing of the Goods and Services as agreed
              between the parties.
            </p>
          </li>
          <li>
            <p>
              The Customer acknowledges and agrees that the Goods and Services
              require routine and non-routine servicing and where the products
              are subject to Rental will enter into a Service Contract with Abco
              to perform routine and non-routine servicing.
            </p>
          </li>
          <li>
            <p>
              The Service will be charged in 15-minute increments or part
              thereof plus a call-out fee (dependent on distance travelled).
              These units of charge may be adjusted by mutual agreement.
            </p>
          </li>
          <li>
            <p>
              Abco will provide the Service with fully trained and competent
              technicians using genuine manufacturer parts. Manufacturer
              warranty will apply to replacement parts.
            </p>
          </li>
          <li>
            <p>
              Abco’s liability for defective workmanship will be limited to
              providing the Service again to rectify the issue.
            </p>
          </li>
          <li>
            <p>
              The Customer will ensure access to the Goods and Services products
              at the allocated Service time and will be responsible for the
              safety of Abco personnel. The Customer indemnifies and holds
              harmless Abco from any liability due to death, injury or damage
              caused to its personnel or equipment while performing the Service.
            </p>
          </li>
          <li>
            <p>
              Billing and payment will be in accordance with Payment and Billing
              in these T&C.
            </p>
          </li>
          <p className="bold">TRAINING</p>
          <li>
            <p>
              Abco will provide training of the Customer’s personnel on the
              Goods and Services as agreed between the parties.
            </p>
          </li>
          <li>
            <p>
              Where training is required, the Customer will enter into a
              Training Contract with Abco to perform the training as specified
              in the purchase order or quotation.
            </p>
          </li>
          <li>
            <p>
              The Training cost will include travel, course duration, setup and
              pack down.
            </p>
          </li>
          <li>
            <p>
              Abco will provide the Training with experienced and competent
              trainers.
            </p>
          </li>
          <li>
            <p>
              The Customer will ensure access to the Goods and Services products
              at the allocated Training time and will be responsible for the
              safety of Abco personnel. The Customer indemnifies and holds
              harmless Abco from any liability due to death, injury or damage
              caused to its personnel or equipment while performing the
              Training.
            </p>
          </li>
          <li>
            <p>
              Where training involves the issue of a certification under the
              National Training Framework, Abco will use a third party to
              provide the Registered Training Organisation authority. Students
              will be assigned a Unique Student Identifier{" "}
              <span className="bold">(‘USI’)</span> in order to comply with the
              Student Identifiers Act 2014 (Cth).
            </p>
          </li>
          <li>
            <p>
              The USI is required to be collected and reported to government
              departments but the information used to create the USI is
              destroyed after the USI is created. For more information go to{" "}
              <a href="https://www.usi.gov.au" rel="noreferrer" target="_blank">
                https://www.usi.gov.au
              </a>
              .
            </p>
          </li>
          <li>
            <p>
              Billing and payment will be in accordance with Payment and Billing
              in these T&C.
            </p>
          </li>
          {/* END */}
        </ol>
        <div className="tacbox">
          <input
            id="checkbox"
            type="checkbox"
            value={!acceptedTerms ? "true" : "false"}
            onClick={() => setAcceptedTerms(!acceptedTerms)}
          />

          <label htmlFor="checkbox">
            {" "}
            I agree to these <Link to={"/"}>Terms and Conditions</Link>.
          </label>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
