import styled from "styled-components";
import device from "../../utils/deviceScreenSizes";

export const BlogContainer = styled.div``;

export const BlogListContainer = styled.div`
  .no-result {
    h1 {
      margin: 0 auto;
      text-align: center;
      padding-top: 6rem;
      padding-bottom: 6rem;
      color: ${(p) => p.theme.colors.primary};
    }
  }
  .tags-container {
    /* background: ${(p) => p.theme.colors.lightBlue}; */
    text-transform: capitalize;
    /* margin-bottom: 3rem; */
    .tags-section-container {
      display: flex;
      max-width: 100vw;
      background: ${(p) => p.theme.colors.lightBlue};
      @media ${device.tablet} {
        flex-wrap: wrap;
        justify-content: center;
      }
      & > div {
        display: flex;
        margin: 0 auto;
        z-index: 1;
        width: 100%;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 30px;
        padding-right: 30px;
        @media ${device.tablet} {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
      /* margin-bottom: 3rem; */
      .tag.selected {
        background: ${(p) => p.theme.colors.primary};
        color: ${(p) => p.theme.colors.white};
      }
      .tag {
        margin: 2rem 1rem;
        padding: 0.65rem 1.5rem;
        background: #eaedf3;
        border: none;
        color: ${(props) => props.theme.colors.primary};
        @media ${device.tablet} {
          margin: 1rem 0.5rem;
        }

        &:hover {
          background: ${(p) => p.theme.colors.primary};
          color: ${(p) => p.theme.colors.white};
        }
      }
    }
  }
  .blog-card-section {
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    justify-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media ${device.tablet} {
      max-width: 640px;
      @media ${device.mobileL} {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .blog-card {
      margin: 1rem;
      min-height: 650px;
      min-width: 366px;
      flex: 1;
      @media ${device.mobileL} {
        min-width: 300px;
      }

      @media ${device.tablet} {
        min-height: 0;
        a {
          display: flex;
          flex: 1;
          .top {
            height: 100%;
          }
          .bottom {
            h2 {
              margin-top: 1rem;
              margin-bottom: 1rem;
            }
            .link-container {
              margin: 0;
              padding-top: 0;
            }
          }
          @media ${device.mobileL} {
            flex-direction: column;
            min-width: 300px;
          }
        }
      }

      .link-container {
        margin-top: 4rem;
      }
    }
    .loader-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const BlogPostContainer = styled.div`
  @media ${device.tablet} {
    padding-left: 2rem;
    padding-right: 2rem;
    .back-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .skeleton-image-loader {
    animation: skeleton-loading 1s linear infinite alternate;
    height: 100%;
    width: 100%;
  }
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 10%, 70%);
    }
    100% {
      background-color: hsl(200, 10%, 95%);
    }
  }
  .back-btn-container {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: ${(p) => p.theme.colors.gray2};
    }
    .icon {
      color: ${(p) => p.theme.colors.gray2};
      font-size: 1.5rem;
    }
    .back {
      font-weight: 800;
    }
  }
  .post-section-container {
    margin-bottom: 3rem;
    max-width: 800px;
    padding: 0;

    .blog-title {
      font-size: 3rem;
      color: ${(p) => p.theme.colors.primary};
      font-weight: bolder;
      @media ${device.tablet} {
        font-size: 2.5rem;
        margin-top: 1rem;
      }
      @media ${device.mobileL} {
        font-size: 2rem;
        margin-top: 1rem;
      }
    }

    .info-container {
      display: flex;
      margin-top: 3rem;
      margin-bottom: 3rem;
      .date {
        display: flex;
        font-size: 1rem;
        font-weight: 800;
        color: ${(props) => props.theme.colors.primary};
        align-items: center;
        margin-right: 1rem;
      }
      span {
        align-self: center;
        margin-right: 0.5rem;
        svg {
          width: 25px;
          height: 25px;
          align-self: center;
        }
      }
      .tags {
        display: flex;
        .tags-container {
          display: flex;
          color: ${(props) => props.theme.colors.primary};
          font-weight: 800;

          p {
            margin-right: 1rem;
            padding: 0.65rem;
            border-radius: 3px;
            background: #eaedf3;
            border: none;
            color: #244289;
          }
        }
      }
    }
    .header-image-container {
      max-width: 800px;
      width: 100%;
      height: 100%;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    figure {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    .blog-post-content {
      color: ${(p) => p.theme.colors.primary};
      p {
        line-height: 2rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-weight: 500;
      }
      h1,
      h2 {
        line-height: 4rem;
      }
      h1 {
        font-size: 3rem;
        font-weight: 500;
      }
      h2 {
        font-size: 3rem;
      }
      ul {
        padding-left: 2rem;
        li {
          line-height: 2rem;
          margin-top: 2rem;
          margin-bottom: 2rem;
          font-weight: 500;
        }
      }
      @media ${device.tablet} {
        h1,
        h2 {
          line-height: 2rem;
        }
        h1 {
          font-size: 2.5rem;
        }
        h2 {
          font-size: 2rem;
        }
      }
      @media ${device.mobileL} {
        h1 {
          font-size: 2.5rem;
        }
        h2 {
          font-size: 1.5rem;
        }
      }
    }
  }
`;
