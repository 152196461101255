import styled from "styled-components";
export const LandingContainer = styled.div`
.nav-wrapper {
    padding-block: 0.5rem;
  }
  .logo-wrapper {
    max-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  nav {
    display: flex;
    justify-content: space-between;
  }

  .call {
    padding-inline: 2rem;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: ${(p) => p.theme.colors.secondary};
    }
    svg {
      margin-left: 0.5rem;
    }
  }
`