import React from "react";
import Loader from "react-loader-spinner";
import { LoaderContainer } from "./Loader.style";

const LoaderComponent = (props: any) => {
  let defaultConfig = {
    color: props.color ? props.color : "#244289",
    height: props.height ? props.height : 150,
    width: props.width ? props.width : 150,
  };
  return (
    <LoaderContainer className={props.className}>
      <Loader type="TailSpin" {...defaultConfig} />
    </LoaderContainer>
  );
};

export default LoaderComponent;
