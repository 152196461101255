import React, { useEffect, useState } from "react";
import { PageBuilder, Seo } from "../../components";
import { useParams } from "react-router-dom";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { sanityFetchBySlug } from "../../utils/globalFunctions";
import { InnovationProductContainer } from "./InnovationProduct.style";

const InnovationProduct = () => {
  let { product } = useParams<any>();
  const [innovationProduct, setInnovationProduct] = useState<any>();
  useEffect(() => {
    let fetchRes = sanityFetchBySlug(product);

    fetchRes.then((res) => setInnovationProduct(res));
  }, [product]);
  return (
    <InnovationProductContainer>
      {innovationProduct ? (
        <>
          {innovationProduct?.seo &&
            Object.keys(innovationProduct.seo).length !== 0 && (
              <Seo
                description={innovationProduct.seo.meta_description}
                keyword={innovationProduct.seo.focus_keyword}
                title={innovationProduct.seo.seo_title}
              />
            )}

          <PageBuilder
            pageBuilder={innovationProduct.innovationPageBuilder}
            page="innovation"
          />
        </>
      ) : (
        <LoaderComponent />
      )}
    </InnovationProductContainer>
  );
};

export default InnovationProduct;
