import React, { useState, FC } from "react";
import { IIcon } from "../../interfaces";
import { Link } from "react-router-dom";
import { AccordionContainer } from "./Accordion.style";
import { Icons } from "..";
import { AnimatePresence, motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";

export interface IAccordion {
  title: string;
  children?: any;
  active?: boolean;
  to: string;
  onClick: any;
  className?: string;
  style?: any;
  hashLink?: boolean;
  scroll?: any;
  link?: any;
}

const Accordion: FC<IAccordion> = ({
  title,
  children,
  active,
  to,
  onClick,
  className,
  hashLink,
  scroll,
  link,
}) => {
  const [isActive, setIsActive] = useState(active ? true : false);

  return (
    <AccordionContainer isActive={isActive}>
      <div className="accordion-divider">
        <div
          className={`accordion-title-container ${className}`}
          onClick={() => setIsActive(!isActive)}
        >
          <div className="accordion-title" onClick={onClick}>
            {hashLink ? (
              <HashLink to={to} scroll={scroll}>
                {title}
              </HashLink>
            ) : (
              <Link
                target={link?.isExternal ? "_blank" : ""}
                rel={link?.isExternal ? "noopener noreferrer" : ""}
                to={link?.isExternal ? { pathname: link.slug.current } : to}
              >
                {title}
              </Link>
            )}
          </div>
          {children && (
            <Icons
              type={isActive ? IIcon.minus : IIcon.plus}
              className="accordion-icon"
            />
          )}
        </div>
        <AnimatePresence>
          {children && isActive && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { height: "auto" },
                collapsed: { height: 0 },
              }}
              className="accordion-content"
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </AccordionContainer>
  );
};

export default Accordion;
