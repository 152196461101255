import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";

export const replaceSpaceWithDash = (str: string) => {
  let newStr = str.replace(/\s+/g, "-").toLowerCase();
  return newStr;
};

export function formatToDollar(num: number, decimalPlaces: number = 0) {
  return (
    "$" + num.toFixed(decimalPlaces).replace(/\d(?=(\d{3})+(?!\d))/g, "$&,")
  );
}

export function isEmpty(obj: any) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const getParams = (url: string) => {
  var params: any = {};
  var parser = document.createElement("a");
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }

  return params;
};

export const redirectExternalHandler = (e: Event) => {
  e.preventDefault();
  // console.log("redirectExternal Clicked!");
};
export const enquireHandler = (e: Event) => {
  e.preventDefault();
  // console.log("Enquire Clicked!");
};
export const downloadCatalogueHandler = (e: Event) => {
  e.preventDefault();
  // console.log("Download Catalogue Clicked!");
};
export const redirectHandler = (e: Event) => {
  e.preventDefault();
  // console.log("Redirect Clicked!");
};
export const youtube_parser = (url: string) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

// export const determineButtonFunction = (block: any) => {
//   console.log(String(block));
//   let returnedFunction;
//   if (block.buttonFunction) {
//     if (block.buttonFunction === "downloadCatalogue") {
//       returnedFunction = downloadCatalogueHandler;
//     }
//     if (block.buttonFunction === "enquire") {
//       console.log("inside", block);
//       returnedFunction = enquireHandler;
//     }
//     if (block.buttonFunction === "redirectExternal") {
//       returnedFunction = function () {
//         window.open(block.externalURL, "_blank");
//       };
//     }
//     if (block.buttonFunction === "redirect") {
//       returnedFunction = redirectHandler;
//     }
//     console.log("test", returnedFunction);
//     return returnedFunction;
//   } else {
//     return null;
//   }
// };

export const sanitySiteSettings = async () => {
  try {
    let res = await sanityClient.fetch(
      `
  *[_type == 'siteSettings']
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const SEARCH_QUERY = `
*[_type in ["machinery", "post" ] && (
    postPageBuilder[].postBlockContent[].children[].text match $searchTerm || 
    machineryPageBuilder[].content[].children[].text match $searchTerm ||
    title match $searchTerm ||
    productName match $searchTerm 
    )]{
      ...,
      _type == "machinery" => {
        ...,
        machinerySubcategory->{
          ...,
          mainCategory->,
        },

      },
      
    }
`;
export const sanityConsumables = async () => {
  try {
    let res = await sanityClient.fetch(
      `
  *[_type == 'consumables']{
    ...,
   pageBuilder[]{
    ...,
    pageBuilderBasic[]{
      ...,
      thumbnailImage {
         asset -> {
          _id,
          url
        }
      }
    },
    pageBuilderTeam[]{
      ...,
      thumbnailImage {
         asset -> {
          _id,
          url
        }
      }
    }
   }
    
  }
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchBySlug = async (slug: string) => {
  try {
    let res = await sanityClient.fetch(
      `
  *[_type == 'innovation' && slug.current == "${slug}"][0]{
      ...,
      innovationPageBuilder[]{
        ...,
        _type == 'pageBuilderGallerySection' => {
        content[]{
          ...,
          _type == 'pageBuilderButtonList' => {
          buttonList[]{
            ...,
            fileDownload {
              fileName,
              asset-> {
                extension,
              url
              }
              
            }
          }
          
        }
        }
      }
    }
  }
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchPage = async (slug: string) => {
  try {
    let res = await sanityClient.fetch(
      `
  *[_type == 'pages' && slug.current == "${slug}"][0]{
    pageBuilder[]{
        ...,
            _type == 'pageBuilderTwoSection' => {
              content[]{
                ...,
                  _type == 'pageBuilderButtonList' => {
                    buttonList[]{
                      ...,
                        fileDownload {
                          fileName,
                          asset-> {
                            extension,
                            url
                            }
                        }
                    }
                  }
              }
            },
            _type == 'pageBuilderHeroSection' => {
              fileDownload {
                fileName,
                asset-> {
                  url,
                  extension
                  }
              },
              subContent[]{
                ...,
                  _type == 'pageBuilderButtonList' => {
                    buttonList[]{
                      ...,
                        fileDownload {
                          fileName,
                          asset-> {
                            url
                            }
                        }
                    }
                  }
              }
            }
    }
   }
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchSingleGovernancePolicy = async (slug: string) => {
  try {
    let res = await sanityClient.fetch(
      `
  *[_type == 'governance-policies' && slug.current == "${slug}"][0]{
    ...,
    pageBuilder[]{
      ...,
      _type == 'pageBuilderHeroSection' => {
        ...,
        fileDownload {
          asset->{
            _id,
            url,
            extension
          },
          fileName
         },
      },
    }
  }
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const sanityFetchMachineryCategoryLinks = async () => {
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == "machineryCategory"]{
        title,
        slug,
        order,
        "subcategory": *[references(^._id)] | order(order asc){
        slug,
        order,
        title
      }
      }
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchInnovationLinks = async () => {
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == "innovation"]{
        title,
        slug,
      }
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchBlogLinks = async () => {
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == "post"]{
        title,
        slug,
      }
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const sanityFetchSinglePost = async (slug: string) => {
  try {
    let res = await sanityClient.fetch(
      `
  *[_type == "post" && slug.current == "${slug}"][0]{
    ...,
    body[]{
      ...,
      markDefs[]{
        ...,
        _type == "internalLink" => {
          "slug": @.reference->slug
        }
      }
    }
  }
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchAllByType = async (type: string) => {
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == "${type}" ]{
        ...,
        pageBuilder[]{
          ...,
          servicingList[]{
            ...,
            cardIcon {
              asset -> {
                url,
              }
            }
          }
        }
      }
    `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchSinglePolicy = async (
  // subcategorySlug: string,
  slug: string
) => {
  // [_type == 'machinerySubcategory' && _id == "6ab48a72-46bd-435e-b165-fb028fbe8aac"]{pageBuilder[slug.current match '${slug}'][0]}
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == 'legalPolicies' && slug.current == "${slug}"]
    `
    );

    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchSingleProduct = async (
  // subcategorySlug: string,
  productSlug: string
) => {
  // [_type == 'machinerySubcategory' && _id == "6ab48a72-46bd-435e-b165-fb028fbe8aac"]{pageBuilder[slug.current match '${slug}'][0]}
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == 'machinery' && slug.current == "${productSlug}"]{
        ...,
        machineryPageBuilder[] {
          ...,
          fileDownload {
            asset->{
              _id,
              url,
              extension
            },
            fileName
           },
          },
        
      }
    `
    );

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const sanityFetchAllMachinery = async () => {
  try {
    let res = await sanityClient.fetch(
      `*[_type == "machineryCategory" ]{
        title,
        slug,
        cardDescription,
        order,
        thumbnailImage{
          asset->{
            _id,
            url
          },
          imageAlt
        },
        "machinerySubcategory": *[references(^._id)]{
          mainCategory,
          pageBuilder,
          backgroundGray,
          title,
          
        }
      }`
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchMachineryData = async () => {
  try {
    let res = await sanityClient.fetch(`*[_type == "machineryPage" ]{
      banner,
      heroSection,
      seo
    }`);
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchSingleMachinery = async (slug: string) => {
  try {
    let res = await sanityClient.fetch(
      `*[_type == "machineryCategory" && slug.current == "${slug}" ]{
        hero,
        title,
        slug,
        seo,
        "machinerySubcategory":  *[_type=='machinerySubcategory' && references(^._id)] | order(order asc)
        {
          mainCategory,
          pageBuilder,
          slug,
          title,
          order,
          technicalData,
          backgroundGray,
          "machineryListing": *[_type=='machinery' && references(^._id)]
        }

      }`
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const sanityFetchAllPosts = async () => {
  try {
    let res = await sanityClient.fetch(
      `*[_type == "post"] | order(publishedAt desc) {
        tags,
        slug,
        thumbnailImage,
        title,
        publishedAt
      }
    `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
const builder = imageUrlBuilder(sanityClient);

export function urlFor(source: any) {
  return builder.image(source);
}

export const sanityFetchSingleMainPage = async (slug: string) => {
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == "pagesMain" && slug.current == "${slug}"][0]{
        pageBuilder[]{
          ...,
          fileDownload {
            asset->{
              _id,
              url,
              extension,
            },
            fileName
           },
          articleList[]->,
          governanceList[]->,
          pageBuilderBasic[]{
            ...,
            thumbnailImage {
               asset -> {
                _id,
                url
              }
            }
          },
          pageBuilderTeam[]{
            ...,
            thumbnailImage {
               asset -> {
                _id,
                url
              }
            }
          }
        },
        seo
      }
      `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const sanityFetchAboutUsLinks = async () => {
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == "pagesMain" && showInNavbar == true]| order(order){
        ...,
        pageBuilder[]{
          ...,
          fileDownload {
            asset->{
              extension,
              _id,
              url
            },
            fileName
           },
          articleList[]->,
          governanceList[]->,
          pageBuilderBasic[]{
            ...,
            thumbnailImage {
               asset -> {
                _id,
                url
              }
            }
          },
          pageBuilderTeam[]{
            ...,
            thumbnailImage {
               asset -> {
                _id,
                url
              }
            }
          }
        },
        seo
      }
      `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const sanityFetchIndustries = async () => {
  try {
    let res = await sanityClient.fetch(
      `
  *[_type == 'industries'] | order(slug.current)
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchEquipmentHire = async () => {
  try {
    let res = await sanityClient.fetch(
      `
  *[_type == 'equipmentHire'][0]
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchSingleIndustry = async (slug: string) => {
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == 'industries' && slug.current == "${slug}"]
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchOnlineApplication = async () => {
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == 'onlineAccountApplication'][0]{
        dynamicFormBuilder,
        formBuilder,
        dynamicFormBuilder2,
        _type,
        title,
        seo,
      }
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchConsumables = async () => {
  try {
    let res = await sanityClient.fetch(
      `
  *[_type == 'consumableArticles'] | order(slug.current)
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchSingleConsumable = async (slug: string) => {
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == 'consumableArticles' && slug.current == "${slug}"]
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const sanityFetchSingleCampaign = async (slug: string, signal: any) => {
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == 'campaign' && slug.current == "${slug}"]{
        ...,
        campaignImage {
          asset->{
            _id,
            url
          }
        }
      }
  `,
      { signal }
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const sanityFetchLandingPageList = async (slug: string) => {
  try {
    let res = await sanityClient.fetch(
      `
      *[_type == 'landingPage' && slug.current=="${slug}"][0]{
        ...,
        landingPageBuilder[]{
          ...,
          _type == "pageBuilderTileListing" => {
            machineryList[]->
            {
              ...,
              machinerySubcategory->{
              ...,
              mainCategory->,
              },
            },
          },
        },
      }
  `
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};
