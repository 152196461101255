import React, { FC, useEffect, useState } from "react";
import { PageBuilder } from "../../components";
import { sanityFetchSingleGovernancePolicy } from "../../utils/globalFunctions";
import { GovernancePoliciesContainer } from "./GovernancePolicies.style";
import { useParams } from "react-router-dom";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const Articles: FC = (props: any) => {
  // const { hero, pageBuilder } = props.location.state.data;
  let { slug } = useParams<any>();
  const [governance, setGovernance] = useState<any>([]);
  useEffect(() => {
    // console.log(props);
    (async () => {
      try {
        let fetchRes = sanityFetchSingleGovernancePolicy(slug);
        fetchRes.then((res) => setGovernance(res));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [slug]);

  if (governance.length === 0) {
    return <LoaderComponent />;
  }

  return (
    <GovernancePoliciesContainer>
      <PageBuilder pageBuilder={governance.pageBuilder} />
    </GovernancePoliciesContainer>
  );
};

export default Articles;
