import React from "react";
import { FormFieldContainer } from "../../components/Forms/Forms.style";

type CustomFormFieldProps = {
  field: {
    fieldLabel: string;
    fieldName: string;
    fieldRequired: boolean;
    fieldType: string;
    fieldPlaceholder: string;
  };
  register: Function;
  errors: Record<string, any>;
};

const CustomFormField: React.FC<CustomFormFieldProps> = ({
  field,
  register,
  errors,
}) => (
  <FormFieldContainer className="form-field-container">
    <label htmlFor={field.fieldLabel}>
      {field.fieldLabel}
      {field.fieldRequired && "*"}
    </label>
    <input
      type={field.fieldType}
      id={field.fieldName}
      placeholder={field.fieldPlaceholder}
      {...register(field.fieldName, {
        required: field.fieldRequired ? true : false,
      })}
    />
    {errors[field.fieldName] && (
      <span style={{ marginTop: 4 }}>
        {field.fieldLabel === "Abco Customer Number"
          ? "If unknown please contact Customer Service on 1800 177 399 or customer.service@abcopro.com.au"
          : `${field.fieldLabel} is required`}
      </span>
    )}
  </FormFieldContainer>
);

export default CustomFormField;
