import styled from "styled-components";
import { Container } from "../../Theme/globalStyle";
import device from "../../utils/deviceScreenSizes";

export const SummaryContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.theme.colors.primary};

  .box {
    flex: 1;
    text-align: center;

    h1 {
      font-weight: 700;
      font-size: ${(p) => p.theme.fontSizes.large};
      line-height: 64px;
    }
    p {
      font-weight: 800;
      max-width: 150px;
      margin: 0 auto;
    }
  }

  @media ${device.tablet} {
    flex-wrap: wrap;
    .box {
      flex: 50%;
      margin-bottom: 2rem;
    }
  }
`;

export const SummarySection = styled.section`
  min-height: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: ${(p) => p.theme.colors.lightBlue};
`;
