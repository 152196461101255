import styled from "styled-components";
import ContactSectionImage from "../../assets/images/cust-service.jpg";
import device from "../../utils/deviceScreenSizes";

export const SectionWrapper = styled.section``;
export const ContactSectionContainer = styled.div`
  background: url(${ContactSectionImage}) center center;
  background-size: cover;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;

  :after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      #244289 0%,
      #244289 0.01%,
      rgba(36, 66, 137, 0) 96.35%
    );
    mix-blend-mode: multiply;
  }

  h3 {
    color: white;
    font-size: 1.8rem;
    margin: 0 auto;
    max-width: 800px;
    line-height: 48px;
    text-align: center;
    font-weight: 500;
  }

  .homecontact-section {
  }

  position: relative;
  min-height: 950px;
  z-index: 10;
  display: flex;
  align-items: center;

  .content-container {
    display: flex;
    z-index: 10;
    margin-bottom: 0;
    & > div {
      flex: 1;
      align-self: center;
    }
    div:last-child {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      flex-direction: column;
    }
  }
  .left {
    position: relative;
    @media ${device.tablet} {
      margin-bottom: 5rem;
    }
    h2 {
      color: white;
      font-size: ${(p) => p.theme.fontSizes.large};
      padding-right: 8rem;

      @media ${device.tablet} {
        font-size: 2.5rem;
        padding-right: 0;

        @media ${device.mobileL} {
          font-size: 2rem;
        }
      }
    }
    .white-line {
      position: absolute;
      bottom: -65px;
      width: 256px;
      height: 8px;
      background: white;
      @media ${device.tablet} {
        bottom: -30px;
      }
    }
  }
`;
