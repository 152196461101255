import h1 from "../../assets/images/h1.png";
import h2 from "../../assets/images/h2.png";
import h3 from "../../assets/images/h3.png";
import { IIcon } from "../../interfaces";

export const testImages = [h1, h2, h3];

export const newsAndArticles = [
  {
    title: "Empower Staff",
    content:
      "At ABCO, we give employees a voice and take their opinions seriously. They are motivated to move forward in their careers because of their involvement  in important operations while adding to their experience.",
    image: h3,
    link: "/",
  },
  {
    title: "Empower Cleaners",
    content:
      "We are more than just a supplier of cleaning products and equipment. For decades we’ve been empowering cleaners through innovative cleaning equipment, expert advice and investment in sustainable cleaning solutions.",
    image: h2,
    link: "/",
  },
  {
    title: "Empower The Community",
    content:
      "The sustainability of our business is directly connected to the health of the planet and local communities. We remain focused on sourcing sustainable solutions to  lessen our footprint, while serving as an example to our peers. ",
    image: h1,
    link: "/",
  },
];

export const videoCards = [
  {
    title: "Never Pay More",
    content:
      "Price Match Guarantee on published prices for identical or equivalent stocked products.",
    icon: IIcon.piggy,
  },
  {
    title: "Impeccable Service",
    content: "24-hour despatch from Monday to Friday across Australia.",
    icon: IIcon.shakeHands,
  },
  {
    title: "Stock Galore",
    content: "Zero back-orders on core stock items.",
    icon: IIcon.plusCart,
  },
  {
    title: "100% Credit",
    content:
      "For all core products in new condition within 100 days, no questions asked.",
    icon: IIcon.handMoney,
  },
];

export const testimonials = [
  {
    content:
      "“ I really enjoy working with Abco in Preston's, their operations team is phenomenal in their service and everything is always done with the best attitude ever!!!”",
    fullName: "Sherbrook Pridham",
    company: "Company 123",
  },
  {
    content:
      "“Great company to do business with, lovely staff and very professional!”",
    fullName: "Sarah Clark",
    company: "Company 123",
  },
  {
    content:
      "“Abco is a good company for cleaning products highly recommend.” ",
    fullName: "Amar Batth",
    company: "Company 123",
  },
  {
    content:
      "“It's always a pleasure to deal with the whole team at Abco Prestons , They are efficient and quick to respond to any queries we ever have. Trusted and recommended..!!!” ",
    fullName: "Paula F Lomu",
    company: "Company 123",
  },
  {
    content:
      "“Awesome experience with the service at ABCO warehouse guys in NSW, very friendly and helpful.” ",
    fullName: "Eddie Gale",
    company: "Company 123",
  },
];

export const accordions = [
  {
    title: "Question 1",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra",
    active: true,
  },
  {
    title: "Question 2",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra",
  },
  {
    title: "Question 3",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra",
  },
  {
    title: "Question 4",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in ornare quam viverra",
  },
];

export const MarketingSectionData = {
  title: "Why Choose Us?",
  content: `Not only is Abco Australia’s most trusted and leading cleaning
  consumables and equipment supplier, you will also experience
  efficient ordering and delivery through our online ordering system
  Abconet.
  <br />
  <br />
  Abconet is built and continually improved with close collaboration
  with Building Service Contractors in order to enable them to
  manage consumables for various sites with the click of a button.`,
  itemList: [
    "Convenient Online Shopping Cart",
    "Online customised consumable template orders",
    "Online account management – multiple sites",
    " Invoices and budget reports",
    "Orders tracking and managements",
  ],
};
