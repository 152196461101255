import React, { useState, useEffect } from "react";
import { Seo, PageBuilder } from "../../../components";
import { AbconetContainer } from "../About.style";
import { sanityFetchSingleMainPage } from "../../../utils/globalFunctions";
import LoaderComponent from "../../../components/Loader/LoaderComponent";

const Abconet = () => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    sanityFetchSingleMainPage("abconet")
      .then((res) => {
        setIsLoading(false);
        setData(res);
      })
      .catch((err) => setIsLoading(false));
  }, []);
  return (
    <AbconetContainer>
      <Seo
        title={data?.seo?.seo_title}
        description={data?.seo?.meta_description}
        keyword={data?.seo?.focus_keyword}
      />
      {!isLoading ? (
        <PageBuilder pageBuilder={data.pageBuilder} />
      ) : (
        <LoaderComponent />
      )}

      {/* <MarketingSection data={MarketingSectionData}>
        <Button type="secondary" text="Request a demo" size="long" />
      </MarketingSection> */}
      {/* <div id="abconet-video"></div>
      <VideoSection
        url="https://youtu.be/FFumTLwfJiY"
        videoThumbnail={vidThumbnail}
      />
      <Testimonials data={testimonials} /> */}
    </AbconetContainer>
  );
};

export default Abconet;
