import styled from "styled-components";
import { Container } from "../../Theme/globalStyle";

interface IVideoSectionContainer {
  bg?: any;
}
export const VideoSectionContainer = styled.section<IVideoSectionContainer>`
  background: ${(p) => (p.bg ? `url(${p.bg})` : p.theme.colors.gray3)} no-repeat
    center center;
  position: relative;
  background-size: cover;
  min-height: 800px;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      #244289 0%,
      #244289 0.01%,
      rgba(36, 66, 137, 0) 96.35%
    );
    mix-blend-mode: multiply;
  }
  .video-section-content {
    max-width: 900px;
    margin: 0 auto;
    h2 {
      text-align: center;
      font-size: 2.5rem;
      color: ${(p) => p.theme.colors.white};
      position: relative;
      z-index: 9;
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
    .play-container {
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      top: 45%;
      margin-left: auto;
      margin-right: auto;
      width: 100px;
      height: 100px;
      background: white;
      border-radius: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        width: 100%;
        height: 100%;
        display: flex;
        font-size: 2rem;
        justify-content: center;
        align-items: center;
        color: ${(p) => p.theme.colors.secondary};
        cursor: pointer;
      }
    }
  }
`;
export const VideoContainer = styled(Container)``;
