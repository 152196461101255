import React, { FC, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { StateContext } from "../../Context/ContextProvider";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any, type: string) {
  const { setCatalogue, setContactForm } = useContext(StateContext);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (type === "catalogue") {
          return setCatalogue(false);
        }
        if (type === "contact") {
          return setContactForm(false);
        }
        alert("You clicked outside of me!");
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setCatalogue, type, setContactForm]);
}

/**
 * Component that alerts if you click outside of it
 */
interface IOutsideAlerter {
  type: string;
  children: any;
}
const OutsideAlerter: FC<IOutsideAlerter> = ({ children, type }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, type);

  return <div ref={wrapperRef}>{children}</div>;
};

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default OutsideAlerter;
