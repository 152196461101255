import React, { FC } from "react";
import { TwoSectionContainer, ImageContainer } from "./TwoSection.style";
import { FadeInWhenVisible, PageBuilder, Section, Icons } from "../";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import SanityClient from "../../client";
import { IIcon } from "../../interfaces";

interface ITwoSection {
  type:
    | "double-image"
    | "quadruple-image"
    | "single-image"
    | "image-gallery"
    | "custom";
  reverse?: boolean;
  lightBlue?: boolean;
  data: { title: string; content: string; images?: any[]; buttons?: any[] };
  children?: any;
  pageBuilder?: true;
  buttons?: any;
  heading?: string;
}
interface ITwoSectionWithText {
  type: "text-on-side";
  reverse?: boolean;
  lightBlue?: boolean;
  data: { title: string; content: string; images?: any[] };
  children?: any;
  heading: string;
  pageBuilder?: true;
  buttons?: any;
}
const builder = imageUrlBuilder(SanityClient);

function urlFor(source: any) {
  return builder.image(source);
}

type TwoSectionProps = ITwoSection | ITwoSectionWithText;
//  Will refactor later

const TwoSection: FC<TwoSectionProps> = (props: any) => {
  // console.log("Two Section", props.data);
  let content;

  const serializers = {
    marks: {
      internalLink: ({ mark, children }: { mark: any; children: any }) => {
        const { slug = {} } = mark;
        const href = `/${slug.current}`;
        return <a href={href}>{children}</a>;
      },
      link: ({ mark, children }: { mark: any; children: any }) => {
        // Read https://css-tricks.com/use-target_blank/
        const { blank, href } = mark;
        return blank ? (
          <a href={href} target="_blank" rel="noreferrer noopener">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        );
      },
    },
    listItem: (props: any) =>
      props.type === "bullet" ? (
        <li>{props.children}</li>
      ) : (
        <li>
          <Icons type={IIcon.check} />
          {props.children}
        </li>
      ),
    unknownType: (props: any) => {
      switch (props.node._type) {
        case "pageBuilderButton":
          // return console.log(btnContainerRef.current);
          return <PageBuilder pageBuilder={[props.node]} />;
        // break;
        case "pageBuilderButtonList":
          return <PageBuilder pageBuilder={[props.node]} />;
      }
    },
    ignoreUnknownTypes: true,
    // _type: (props: any) => console.log("serializer", props),
  };

  switch (props.type) {
    case "single-image":
      content = (
        <div className="left-images-container-single">
          <div className="single-container">
            <ImageContainer
              className="item item1"
              isSingleImage={true}
              image={
                props.pageBuilder
                  ? urlFor(props.data?.singleImage)
                  : props.data.image[0]
              }
            ></ImageContainer>
          </div>
        </div>
      );
      break;
    case "double-image":
      content = (
        <div className="left-images-container-double">
          <div className="top">
            <ImageContainer
              className="item item1"
              image={
                props.pageBuilder
                  ? urlFor(props.data.doubleImageList[0])
                  : props.data.doubleImageList[0]
              }
            ></ImageContainer>
          </div>
          <div className="bottom">
            <ImageContainer
              className="item item1"
              image={
                props.pageBuilder
                  ? urlFor(props.data.doubleImageList[1])
                  : props.data.doubleImageList[1]
              }
            ></ImageContainer>
          </div>
        </div>
      );
      break;
    case "text-on-side":
      content = (
        <div className="left-images-container text-on-side">
          <BlockContent
            blocks={props.data.leftContent}
            dataset="production"
            projectId="1s6e2mzd"
            // @ts-ignore
            serializers={serializers}
          />
        </div>
      );
      break;
    case "custom":
      content = (
        <div className="left-images-container custom">{props.children}</div>
      );
      break;
  }
  return (
    <FadeInWhenVisible>
      <TwoSectionContainer reverse={props.reverse} lightBlue={props.lightBlue}>
        <Section className="two-section-content-wrapper">
          <div
            className={`left ${
              props.type === "image-gallery" ? "image-gallery-container" : ""
            } `}
          >
            {content}
          </div>
          <div
            className={`right ${
              props.type === "custom" ? "custom-container" : ""
            }`}
          >
            {props.pageBuilder || Array.isArray(props.data?.content) ? (
              <>
                <BlockContent
                  blocks={props.data.content}
                  dataset="production"
                  projectId="1s6e2mzd"
                  className="block-content"
                  // @ts-ignore
                  serializers={serializers}
                />
              </>
            ) : (
              <>
                <h1>{props.data?.title}</h1>
                {props.data?.content && (
                  <div
                    className="block-content"
                    dangerouslySetInnerHTML={{ __html: props.data?.content }}
                  ></div>
                )}
              </>
            )}

            {props.type !== "custom" && props.children}
          </div>
        </Section>
      </TwoSectionContainer>
    </FadeInWhenVisible>
  );
};

export default TwoSection;
