import React from "react";
// import { Link } from "react-router-dom";
import { Icons } from "..";
import { IIcon } from "../../interfaces";
import { CardContainer } from "./Card.style";
import { TeamCardContainer } from "./Card.style";

const TeamCard = (props: any) => {
  const { data } = props;
  return (
    <TeamCardContainer>
      <CardContainer whileHover={{ scale: 1.03 }}>
        <a
          className="connect"
          rel="noreferrer"
          target="_blank"
          href={data.linkedin}
          style={{ textDecoration: "none" }}
        >
          <div className={`top ${!data.image ? "bg-gray" : ""}`}>
            {data.thumbnailImage ? (
              <img src={data.thumbnailImage?.asset.url} alt="team" />
            ) : (
              <img src={data.image} alt="team" />
            )}
          </div>
          <div className="bottom team">
            <h4 className="team-name">{data.title}</h4>
            <p className="position">{data.position}</p>
            <div className="linked">
              <p className="connect">Let's Connect</p>
              <div className="linkedIn">
                <Icons type={IIcon.linkedIn2} />
              </div>
            </div>
          </div>
        </a>
      </CardContainer>
    </TeamCardContainer>
  );
};

export default TeamCard;
