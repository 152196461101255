import React, { useState, useEffect, FC } from "react";
import { Seo, PageBuilder } from "../../components";
import { BlogContainer } from "./Blogs.style";
import { sanityFetchAllByType } from "../../utils/globalFunctions";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const Blogs: FC = () => {
  const [learnData, setLearnData]: any = useState();
  useEffect(() => {
    let fetchLearnData = sanityFetchAllByType("learnPage");
    fetchLearnData.then((res) => {
      setLearnData(res[0]);
    });
  }, []);

  if (learnData === undefined) {
    return <LoaderComponent />;
  }
  return (
    <BlogContainer>
      <Seo
        title="Abcopro | Power To Clean | Blogs"
        description="Abcopro | Power To Clean | Blogs"
        keyword="Blogs"
      />

      <PageBuilder pageBuilder={learnData.pageBuilder} />
    </BlogContainer>
  );
};

export default Blogs;
