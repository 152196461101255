import React, { FC, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { StateContext } from "../../Context/ContextProvider";
// import Button from "../Button/Button";
import { ContactFormContainer, FormFieldContainer } from "./Forms.style";
import { ContactFormVariants } from "../../Theme/animationVariants";
import { OutsideAlerter, Icons } from "../";
import { AnimatePresence } from "framer-motion";
import LoadingSpinner from "./LoadingSpinner";
import { IIcon } from "../../interfaces";
import { useToken } from "../../utils/customHooks";
interface MyWindow extends Window {
  dataLayer: any;
}
declare let window: MyWindow;
interface IContactForm {
  showContactForm: boolean;
  className?: string;
  from?: string;
} /* eslint-disable no-useless-escape */
const ContactForm: FC<IContactForm> = ({
  showContactForm,
  className,
  from,
}) => {
  type formValues = {
    firstname: string;
    lastname: string;
    businessname: string;
    position: string;
    mobilephone: number;
    email: string;
    comments: string;
    state: string;
    respondtype: string;
    industry: string;
    customerType: string;
    requestDemoCallback: any;
    requestDemo: any;
  };

  const { token } = useToken();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");
  const onSubmit = (data: any, e: any) => {
    e.preventDefault();

    let comments = "";
    for (let key in data) {
      if (key !== "requestDemo") {
        comments += `${key}: ${data[key]}\n`;
      }
    }
    //add the url to the comments
    comments += `URL: ${window.location.href}\n`;
    setIsSubmitting(true);
    let newData: any = {
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      memo: comments,
      phone: data.mobilephone,
      website: "1",
      custbody_esc_campaign_category: -4,
      customerType: data.customerType === "Private" ? 1 : 2,
      requestDemo: data.requestDemo ? true : false,
    };

    if (token !== null) {
      if (token["gclid"]) {
        newData["custbody_esc_campaign_category"] = -15;
      }
    }
    console.log("ONSUBMIT", newData);
    fetch(
      // "https://6763524-sb2.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524_SB2&h=feb67c19fa1747d29813&action=createOpportunity",
      "https://6763524.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524&ns-at=AAEJ7tMQscoLnHY3TQgUcIofUDhsW0KGKedD0ocvSoJn0oybK3M&action=createOpportunity",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "User-Agent":
            "Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36",
        },
        body: JSON.stringify(newData),
      }
    ).then((res) => {
      setIsSubmitting(false);
      setContactForm(false);
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "form_submission",
          form_name: "contact-us",
        });
      }
      setButtonText("Submitted Successfully!");
      // e.target.reset();
      setTimeout(() => {
        setButtonText("Submit");
      }, 3000);
    });
    // e.target.submit();
  };

  const onError = (errors: any, e: any) => console.log(errors, e);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formValues>({ mode: "all" });
  const { setContactForm } = useContext(StateContext);
  // const onSubmit = (data: any) => {
  // };
  return (
    <AnimatePresence exitBeforeEnter>
      {showContactForm && (
        <OutsideAlerter type="contact">
          <ContactFormContainer
            variants={ContactFormVariants}
            animate="visible"
            initial="hidden"
            exit="hidden"
            className={className}
          >
            {from !== "home" && (
              <div className="close-button">
                <Icons
                  onClick={() => setContactForm(false)}
                  type={IIcon.close}
                />{" "}
              </div>
            )}
            <h2>Get in touch with our team today</h2>
            <p>Fill out the form below and we will contact you shortly</p>
            <form
              name="contact-us"
              onSubmit={handleSubmit(onSubmit, onError)}
              action="https://6763524.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524&h=8daaa23e3101d43855eb&action=createOpportunity"
              // action="https://6763524.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=6763524&formid=3&h=AAFdikaIxvlRop_4pPTedmCYDYF526KhxJQwLK6XTrMylt3ZC8k"
              // action="https://6763524-sb2.extforms.netsuite.com/app/site/crm/externalleadpage.nl?compid=6763524_SB2&formid=3&h=AAFdikaIJDaEZs3QRJZpqiclv3RbPxoprB2rzGD4P3ZIHPxhyeY"
            >
              <FormFieldContainer>
                <label htmlFor="firstname">First Name*</label>

                <input
                  id="firstname"
                  className={errors.firstname ? "error" : ""}
                  placeholder="John"
                  {...register("firstname", { required: true })}
                />
                {errors.firstname && errors.firstname.type === "required" ? (
                  <span>This is required</span>
                ) : (
                  <span></span>
                )}

                <label htmlFor="lastname">Last Name*</label>
                <input
                  id="lastname"
                  className={errors.lastname ? "error" : ""}
                  placeholder="Doe"
                  {...register("lastname", { required: true })}
                />
                {errors.lastname && errors.lastname.type === "required" ? (
                  <span>This is required</span>
                ) : (
                  <span></span>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <label htmlFor="businessname">Business Name*</label>
                <input
                  id="businessname"
                  className={errors.businessname ? "error" : ""}
                  placeholder="Business Name"
                  {...register("businessname", { required: true })}
                />
                {errors.firstname && errors.firstname.type === "required" ? (
                  <span>This is required</span>
                ) : (
                  <span></span>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <label htmlFor="position">Your Position*</label>
                <input
                  id="position"
                  className={errors.position ? "error" : ""}
                  placeholder="Your Position"
                  {...register("position", { required: true })}
                />
                {errors.position && errors.position.type === "required" ? (
                  <span>This is required</span>
                ) : (
                  <span></span>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <label htmlFor="mobilephone">Mobile Number*</label>
                <input
                  className={errors.mobilephone ? "error" : ""}
                  type="number"
                  placeholder="0400 000 000"
                  id="mobilephone"
                  {...register("mobilephone", { required: true })}
                />
                {errors.mobilephone &&
                errors.mobilephone.type === "required" ? (
                  <span>This is required</span>
                ) : (
                  <span></span>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <label htmlFor="email">Work Email*</label>
                {/* eslint-disable-next-line  */}
                <input
                  id="email"
                  className={errors.email ? "error" : ""}
                  placeholder="example@address.com"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please Enter a valid email",
                    },
                  })}
                />
                <span>
                  {errors.email && errors.email.type === "required"
                    ? "This is required"
                    : errors.email &&
                      errors.email.type === "pattern" &&
                      "Please use a valid Email"}
                </span>
              </FormFieldContainer>
              <FormFieldContainer className="form-field-container">
                <label htmlFor={"states"}>State*</label>
                <div className="custom-select">
                  <select
                    id={"state"}
                    placeholder={"state"}
                    {...register("state", {
                      required: true,
                    })}
                  >
                    <option value="">--Please Select--</option>

                    <option value="New South Wales">New South Wales</option>
                    <option value="Northern Territory">
                      Northern Territory
                    </option>
                    <option value="Queensland">Queensland</option>
                    <option value="South Australia">South Australia</option>
                    <option value="Tasmania">Tasmania</option>
                    <option value="Victoria">Victoria</option>
                    <option value="Western Australia">Western Australia</option>
                  </select>
                  <span className="custom-arrow"></span>
                </div>
                {errors.state && errors.state.type === "required" ? (
                  <span>This is required</span>
                ) : (
                  <span></span>
                )}
              </FormFieldContainer>

              <FormFieldContainer className="form-field-container">
                <label htmlFor={"respondtype"}>
                  How would you like us to respond?*
                </label>
                <div className="custom-select">
                  <select
                    id={"respondtype"}
                    placeholder={"How would you like us to respond?"}
                    {...register("respondtype", {
                      required: true,
                    })}
                  >
                    <option value="">--Please Select--</option>

                    <option value="Phone Call">Phone Call</option>
                    <option value="Email">Email</option>
                    <option value="Video Call">Video Call</option>
                  </select>
                  <span className="custom-arrow"></span>
                </div>
                {errors.respondtype &&
                errors.respondtype.type === "required" ? (
                  <span>This is required</span>
                ) : (
                  <span></span>
                )}
              </FormFieldContainer>
              <FormFieldContainer className="form-field-container">
                <label htmlFor={"industry"}>Industry*</label>
                <div className="custom-select">
                  <select
                    id={"industry"}
                    placeholder={"Industry"}
                    {...register("industry", {
                      required: true,
                    })}
                  >
                    <option value="">--Please Select--</option>

                    <option value="Building Service Contractor">
                      Building Service Contractor
                    </option>
                    <option value="Facilities Management">
                      Facilities Management
                    </option>
                    <option value="Education, Government or Health">
                      Education, Government or Health
                    </option>
                    <option value="Contract Cleaner">Contract Cleaner</option>
                    <option value="Distributor">Distributor</option>
                    <option value="Hospitality">Hospitality</option>
                    <option value="Warehousing">Warehousing</option>
                    <option value="Builder / Designer / Architect">
                      Builder / Designer / Architect
                    </option>
                    <option value="Sustainability">Sustainability</option>
                  </select>
                  <span className="custom-arrow"></span>
                </div>
                {errors.industry && errors.industry.type === "required" ? (
                  <span>This is required</span>
                ) : (
                  <span></span>
                )}
              </FormFieldContainer>

              <FormFieldContainer className="form-field-container">
                <label htmlFor={"customerType"}>Customer Type</label>
                <div className="custom-select">
                  <select
                    id="customerType"
                    placeholder="Customer Type"
                    {...register("customerType", {
                      required: true,
                    })}
                  >
                    <option value="">--Please Select--</option>
                    <option value={"Private"}>Private Customer</option>
                    <option value={"Business"}>Business Customer</option>
                  </select>
                  <span className="custom-arrow"></span>
                </div>
                {errors.customerType && <span>Customer Type is required</span>}
              </FormFieldContainer>
              <FormFieldContainer className="form-field-container">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="requestDemo"
                    {...register("requestDemo")}
                  />
                  <label htmlFor="requestDemoCallback">
                    Request Demo or Call Back
                  </label>
                </div>
              </FormFieldContainer>
              <FormFieldContainer>
                <label htmlFor="comments">Your Message*</label>
                <textarea
                  className={errors.comments ? "error" : ""}
                  placeholder="Any other background information or requests?
                  "
                  rows={5}
                  cols={50}
                  id="comments"
                  {...register("comments", { required: true, minLength: 10 })}
                />

                <span>
                  {errors.comments && errors.comments.type === "required"
                    ? "This is required"
                    : errors.comments &&
                      errors.comments.type === "minLength" &&
                      "Minimum of 10 Characters"}
                </span>
              </FormFieldContainer>

              <button
                className="submitBtn"
                type="submit"
                disabled={isSubmitting}
                style={{ background: `${isSubmitting && "#244289"}` }}
              >
                {isSubmitting ? <LoadingSpinner /> : buttonText}
              </button>
            </form>
          </ContactFormContainer>
        </OutsideAlerter>
      )}
    </AnimatePresence>
  );
};

export default ContactForm;
