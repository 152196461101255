import React, { FC } from "react";
import { ISkeletonCard } from "../../interfaces";
import { Icons } from "../index";
import { CardLink, SkeletonCardContainer } from "./Card.style";
import { Link } from "react-router-dom";

const SkeletonCard: FC<ISkeletonCard> = ({
  title,
  content,
  icon,
  className,
  image,
  link,
}) => {
  return (
    <SkeletonCardContainer
      className={className}
      whileHover={link && { scale: 1.03 }}
    >
      {link ? (
        <CardLink to={{ pathname: link }} target="_blank">
          <div className="content">
            <div className="top">
              {image ? (
                <img
                  className="img-container"
                  src={image}
                  alt={"Icon " + title}
                />
              ) : (
                <Icons type={icon} />
              )}
              <p>{title}</p>
            </div>
            <div className="bottom">
              {link ? <div>Shop now</div> : <p>{content}</p>}
            </div>
          </div>
        </CardLink>
      ) : (
        <div className="content">
          <div className="top">
            {image ? (
              <img className="img-container" src={image} alt="icon" />
            ) : (
              <Icons type={icon} />
            )}
            <p>{title}</p>
          </div>
          <div className="bottom">
            {link ? (
              <Link to={{ pathname: link }} target="_blank">
                Shop now
              </Link>
            ) : (
              <p>{content}</p>
            )}
          </div>
        </div>
      )}
    </SkeletonCardContainer>
  );
};

export default SkeletonCard;
