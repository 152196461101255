import React, { FC } from "react";
import {
  Section,
  CardsContainer,
  HomePromisesContainer,
} from "./HomePromises.style";
import { videoCards } from "../../pages/Home/data";
import { SkeletonCard, Button } from "../index";
import { FadeInWhenVisible } from "..";
import { IIcon } from "../../interfaces";
import { motion } from "framer-motion";
interface IHomePromises {
  type?: string;
}

const HomePromises: FC<IHomePromises> = ({ type }) => {
  return (
    <FadeInWhenVisible>
      <Section>
        <HomePromisesContainer type={type}>
          <div className="homepromises-content">
            {type === "abconet" ? (
              <>
                <h2>
                  <span>abconet</span> Purchasing Made Easy
                </h2>
                <p>
                  abconet is our state-of-art order management system, built and
                  continually improved with close collaboration with Building
                  Service Contractors in order to enable them to manage
                  consumables for various sites with the click of a button.
                </p>
                <div className="btn-container">
                  <Button
                    type="secondary"
                    size="long"
                    text="Learn More"
                    aria-label="Learn More"
                    className="learn-more"
                    to="/abconet"
                  />
                </div>
              </>
            ) : (
              <>
                <h2>
                  Our <span>Unbeatable</span> Customer Promises
                </h2>
                <p>
                  Here at Abco we take our service seriously and are dedicated
                  to making our customer’s lives easy when ordering from Abco.
                  They are the centre of everything we do, so that’s why we’ve
                  put in place new and improved customer promises.
                </p>
              </>
            )}
          </div>
        </HomePromisesContainer>
        <CardsContainer>
          <div className="cards-list">
            {type === "abconet" ? (
              <React.Fragment>
                <SkeletonCard
                  title="Budget Management"
                  content="Retain control with budget management"
                  icon={IIcon.searchDollar}
                  className="skeleton-card card1"
                />

                <SkeletonCard
                  title="Permissions Control"
                  content="Full control on how you want to structure your operations process, team members and sub-contractors."
                  icon={IIcon.keyIcon}
                  className="skeleton-card card2"
                />

                <SkeletonCard
                  title="Site Templates"
                  content="Lists of consumables for multiple sites, taking the hassle out of placing repeat orders."
                  icon={IIcon.clipboard}
                  className="skeleton-card card3"
                />
                <SkeletonCard
                  title="Consumption Reports"
                  content="Insightful reports that will help you optimise your ordering processes."
                  icon={IIcon.chart}
                  className="skeleton-card card4"
                />
              </React.Fragment>
            ) : (
              videoCards.map((card, key) => (
                <motion.a
                  key={key}
                  whileHover={{ scale: 1.01 }}
                  className="link-card"
                  href={"/static/updated-t-and-c.pdf"}
                  target={"_blank"}
                >
                  <SkeletonCard
                    key={key}
                    title={card.title}
                    content={card.content}
                    icon={card.icon}
                  />
                </motion.a>
              ))
            )}
          </div>
        </CardsContainer>
      </Section>
    </FadeInWhenVisible>
  );
};

export default HomePromises;
