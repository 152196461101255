import React from "react";
import { urlFor } from "../../utils/globalFunctions";
// import { Link } from "react-router-dom";
import { CardContainer, CardLink } from "./Card.style";

// interface IArticleCard {
//   image?: any;
//   title: string;
//   content: string;
//   to?: any;
// }

const InnovationCard = (props: any) => {
  const { data } = props;
  return (
    <>
      <CardContainer whileHover={{ scale: 1.03 }}>
        <CardLink
          className="innovation-card"
          to={{
            pathname: `/innovation/${data.slug.current}`,
            state: { ...props },
          }}
        >
          <div
            className={`top ${
              !data.cardThumbnailImage?.asset ? "bg-gray" : ""
            }`}
          >
            <img
              src={data && urlFor(data.cardThumbnailImage.asset)}
              alt="team"
            />
          </div>
          <div className="bottom">
            <h4 className="title">{data.title}</h4>
            <p className="content article-content">{data.cardDescription}</p>
            <div className="link-container">
              <div>Continue Reading</div>
            </div>
          </div>
        </CardLink>
      </CardContainer>
    </>
  );
};

export default InnovationCard;
