import React from "react";
import SkeletonCard from "./SkeletonCard";
import TeamCard from "./TeamCard";
import ArticleCard from "./ArticleCard";
import { InnovationCard, MachineryCard } from "..";
import GovernanceCard from "./GovernanceCard";
import ServicingCard from "./ServicingCard";

// export interface ICard {
//   title: string;
//   content: string;
//   image?: any;
//   job?: string;
//   linkedIn?: string;
//   icon?: any;
//   type: "team" | "article" | "skeleton" | "blog";
//   to?: string;
//   date?: string;
// }

const Card = (props: any) => {
  switch (props.type) {
    case "team":
      return <TeamCard data={props.data} />;
    case "article":
      return <ArticleCard data={props.data} />;
    case "machinery":
      return <MachineryCard data={props.data} />;
    case "governance":
      return <GovernanceCard data={props.data} />;
    case "skeleton":
      return (
        <SkeletonCard
          title={props.title}
          content={props.content}
          image={props.image}
          icon={props.icon}
        />
      );
    case "innovation":
      return <InnovationCard data={props.data} />;
    case "servicing":
      return <ServicingCard data={props.data} />;
    default:
      return (
        <ArticleCard
          title={props.title}
          content={props.content}
          image={props.image}
          to={props.to}
        />
      );
  }
};

export default Card;
