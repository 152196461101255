import React from "react";
import { HeroSection, Section, Icons, Button } from "../../components";
import thanks from "../../assets/images/thank_you.jpg";
import { Link } from "react-router-dom";
import { IIcon } from "../../interfaces";
import { ThankYouContainer } from "./ThankYou.style";

const ThankYou = () => {
  return (
    <ThankYouContainer>
      <Section className="back-wrapper">
        <div className="back-btn-container">
          <Link className="btn" to="/">
            <Icons type={IIcon.arrowLeft} className="icon" />{" "}
            <span className="back">Back</span>
          </Link>
        </div>
      </Section>
      <HeroSection title="Thank You for your enquiry" image={thanks}>
        <p>
          An Abco representative will be in touch with you shortly. If you
          require further assistance, call us on 1800 177 399.
        </p>
        <Button type={"secondary"} size="long" to="/" text="Go back" />
      </HeroSection>
    </ThankYouContainer>
  );
};

export default ThankYou;
