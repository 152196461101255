import React, { FC } from "react";
import {
  HeroSectionWrapper,
  TabletBackgroundContainer,
} from "./HeroSection.style";
import {
  FadeInWhenVisible,
  EmailSubscription,
  PageBuilder,
  SubscribeForm,
} from "../";
import useWindowDimensions from "../../utils/useWindowDimensions";

//! Theres a bug that if the name of image contains space, the image wont render

export interface IHeroSection {
  title?: string;
  subtitle?: string;
  image?: any;
  bg?: any;
  large?: boolean;
  reverse?: boolean;
  children?: any;
  emailSubscription?: boolean;
  subContent?: any;
  from?: string;
  largeSubcontent?: boolean;
  file?: any;
  downloadButtonText?: string;
  subscribe?: boolean;
}

const HeroSection: FC<IHeroSection> = ({
  title,
  subtitle,
  image,
  large,
  reverse,
  children,
  emailSubscription,
  subContent,
  from,
  file,
  downloadButtonText,
  subscribe,
}) => {
  const { width } = useWindowDimensions();
  let isTablet = width <= 1024;

  title = title?.trim();
  return (
    <FadeInWhenVisible>
      {isTablet ? (
        <HeroSectionWrapper>
          <div className="top-layer">
            {title && <h1>{title}</h1>}
            {subtitle && <p>{subtitle}</p>}

            {subContent && <PageBuilder pageBuilder={subContent} />}
            {file && (
              <EmailSubscription
                file={file}
                emailSubscription={emailSubscription}
                downloadButtonText={downloadButtonText}
              />
            )}
            {subscribe && <SubscribeForm />}

            {children}
          </div>
          <TabletBackgroundContainer
            bg={image}
            reverse={reverse ? true : false}
          >
            <div className="triangle-up-left"></div>  
          </TabletBackgroundContainer>
        </HeroSectionWrapper>
      ) : reverse ? (
        <HeroSectionWrapper bg={image} large={large} reverse>
          <div className="left-layer">
            <div className="triangle-left"></div>
            <div className="triangle-right"></div>
          </div>
          <div className="right-layer">
            <div className="triangle-right-overlay"></div>
            <div className="right-layer-content">
              <div className="container">
                <div className="info">
                  {title && <h1>{title}</h1>}
                  {subtitle && (
                    <p dangerouslySetInnerHTML={{ __html: subtitle }}></p>
                  )}
                  {subContent && <PageBuilder pageBuilder={subContent} />}
                  {file && file.asset && (
                    <EmailSubscription
                      file={file}
                      from={from}
                      emailSubscription={emailSubscription}
                      downloadButtonText={downloadButtonText}
                    />
                  )}
                  {subscribe && <SubscribeForm />}
                  {children}
                </div>
              </div>
            </div>
          </div>
        </HeroSectionWrapper>
      ) : (
        <HeroSectionWrapper bg={image} large={large}>
          <div className="left-layer">
            <div className="triangle-left-overlay"></div>
            <div className="left-layer-content">
              <div className="container">
                <div className="info">
                  {title && <h1 className={large ? "bold" : ""}>{title}</h1>}
                  {/* @ts-ignore */}
                  {subtitle && <p>{subtitle} </p>}
                  {subContent && <PageBuilder pageBuilder={subContent} />}
                  {file && downloadButtonText && (
                    <EmailSubscription
                      file={file}
                      from={from}
                      emailSubscription={emailSubscription}
                      downloadButtonText={downloadButtonText}
                    />
                  )}
                  {subscribe && <SubscribeForm />}
                  {children}
                </div>
              </div>
            </div>
          </div>
          <div className="right-layer">
            <div className="triangle-left"></div>
            <div className="triangle-right"></div>
          </div>
        </HeroSectionWrapper>
      )}
    </FadeInWhenVisible>
  );
};

export default HeroSection;
