import styled, { css } from "styled-components";
import { Container } from "../../Theme/globalStyle";
import device from "../../utils/deviceScreenSizes";

export const GalleryContainer = styled.div`
  /* height: 550px; */
  .skeleton-image-loader {
    /* animation: skeleton-loading 1s linear infinite alternate; */
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 10%, 70%);
    }
    100% {
      background-color: hsl(200, 10%, 95%);
    }
  }
  .gallery-card {
    img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      object-fit: cover;
    }
  }
  .gallery-slider {
    /* width: 550px;
    height: 550px; */
    .slick-list {
      box-shadow: 2px 2px 10px 0px #0000001a;
    }
    .image-gallery {
      width: 100%;
      height: 100%;

      .gallery-card {
        width: 100%;
        display: flex;
        justify-content: center;
        background: white;
        align-items: center;
      }
    }
  }
  .controls-container {
    display: flex;
    .arrow-container {
      flex: 1;
      padding: 2rem 1.5rem;
      .prev {
        margin-left: auto;
      }
      .next {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
`;
export const GalleryArrows = styled.div`
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: ${(p) => p.theme.colors.primary};
  transition: 0.3s all ease;
  cursor: pointer;
  opacity: 0.5;
  span {
    align-self: center;
    justify-self: center;
    font-size: 1.5rem;
  }
  svg {
    color: white;
  }

  &:hover {
    opacity: 1;
    background: ${(p) => p.theme.colors.primary};
  }
`;

interface ITwoSectionContainer {
  reverse?: boolean;
  double?: boolean;
  lightBlue?: boolean;
}

export const GallerySectionContainer = styled.div<ITwoSectionContainer>`
  .gallery-section {
    @media ${device.tablet} {
      flex-direction: column;
    }
  }
  ul {
    list-style: none;
    li {
      display: flex;
      align-self: start;
      padding-top: 2px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      line-height: 32px;

      span {
        align-self: start;
        padding-right: 1rem;
        padding-top: 0.4rem;
        font-size: 1.2rem;

        @media ${device.mobileL} {
          /* margin: 0 auto; */
          padding-right: 1rem;
        }
      }
    }
  }
  .left-images-container-single {
    height: 550px;
    width: 550px;
    position: relative;
    .single-container {
      width: 100%;
      height: 100%;
    }
    .top {
      position: absolute;
      top: 0;
      right: 0;
      width: 300px;
      height: 400px;
      z-index: 5;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    }
    .block-content {
      ul {
        list-style: none;
        li {
          display: flex;
          align-self: start;
          padding-top: 2px;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          line-height: 32px;

          span {
            align-self: center;
            padding-right: 1.5rem;
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .text-on-side-line {
    position: absolute;
    bottom: 0;
    width: 256px;
    height: 8px;
    background: ${(p) => p.theme.colors.secondary};
  }
  .text-on-side {
    align-self: baseline;
    h2 {
      color: ${(p) => p.theme.colors.primary};
      font-size: 3rem;
      line-height: 4rem;
      padding-right: 2rem;
    }
  }
  background: ${(props) =>
    props.lightBlue ? props.theme.colors.lightBlue : props.theme.colors.white};

  ${Container} {
    display: flex;
    padding-top: 4rem;
    padding-bottom: 4rem;
    ${({ reverse }) =>
      reverse &&
      css`
        flex-direction: row-reverse;
      `}

    .left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 4rem;
      @media ${device.tablet} {
        padding-right: 0;
      }
      position: relative;
      max-width: 620px;
      ${({ reverse }) =>
        reverse &&
        css`
          padding-right: 0;
        `}

      .left-images-container-double {
        height: 550px;
        width: 550px;
        position: relative;
        .bottom {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 350px;
          height: 450px;
          background: ${(p) => p.theme.colors.gray3};
          z-index: 9;
        }
        .top {
          position: absolute;
          top: 0;
          right: 0;
          width: 300px;
          height: 400px;
          background: blue;
          z-index: 5;
          background: ${(p) => p.theme.colors.gray3};
          box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
        }
      }

      .left-images-container {
        width: 100%;
        position: absolute;
        max-width: 500px;
        @media ${device.tablet} {
          /* margin: 0 auto; */
          position: relative;
        }
        &-top {
          display: flex;
          max-height: 350px;
          height: 350px;

          .item {
            flex: 1;
            /* background: ${(p) => p.theme.colors.gray2}; */
            margin-right: 25px;
            /* margin-bottom: 25px; */
          }
          .item2 {
            max-height: 266px;
            height: 266px;
            align-self: flex-end;
          }
        }
        &-bottom {
          display: flex;
          max-height: 200px;
          height: 200px;
          justify-content: flex-end;
          margin-top: 25px;
          .item {
            flex: 1;
            /* background: ${(p) => p.theme.colors.gray2}; */
            margin-right: 25px;
          }
          .item3 {
            flex: 2;
            width: 175px;
            height: 175px;
            max-width: 175px;
          }
          .item4 {
            width: 200px;
            height: 200px;
            max-width: 200px;
          }
        }
      }
    }
    .right {
      ${({ reverse }) =>
        reverse &&
        css`
          padding-right: 4rem;
        `}
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: ${(props) => props.theme.colors.primary};
      h1 {
        font-size: 3rem;
        font-weight: 500;
        line-height: 4rem;
        padding-bottom: 2rem;
        text-transform: capitalize;
      }
      h2 {
        @media ${device.tablet} {
          font-size: 2.5rem;
        }
        padding-bottom: 2rem;
        font-weight: 300;
        font-size: ${(props) => props.theme.fontSizes.large};
        text-transform: capitalize;
      }
      h3 {
        padding-bottom: 2rem;
        font-weight: 500;
        font-size: 1.5rem;
      }
      p {
        line-height: 32px;
        padding-bottom: 2rem;
      }
      .block-content {
        line-height: 32px;
      }
    }
  }
  .btn-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 2rem;
    & > div {
      padding-right: 2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;

      @media ${device.mobileL} {
        width: 100%;
        padding-right: 0;
        button {
          /* display: inline-block; */
          width: 100%;
        }
      }
    }
  }
  .image-gallery-container {
    padding-top: 0.5rem;
    align-items: flex-start !important;
  }
`;

interface IImageContainer {
  image: any;
}

export const ImageContainer = styled.div<IImageContainer>`
  /* background: url("https://robohash.org/test") center center;*/
  width: 100%;
  height: 100%;
  background: ${(p) => (p.image ? `url(${p.image})` : p.theme.colors.gray2)}
    center center;
  background-size: cover;
`;
