import React, { FC, useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { SearchBarContainer } from "./SearchBar.style";
// import { TfiClose } from "react-icons/tfi";

interface ISearch {
  isSearchOpen: boolean;
  setIsSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  isMobile?: boolean;
}

const SearchBar: FC<ISearch> = (props: ISearch) => {
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const containerDivRef = useRef<any>(null);
  const { isSearchOpen, setIsSearchOpen } = props;
  const history = useHistory();
  const handleClickOutside = (event: any) => {
    if (
      containerDivRef.current &&
      !containerDivRef.current.contains(event.target)
    ) {
      // Do something when the click is outside of the div
      setIsFocused(false);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const quickLinkList = [
    {
      title: "Online Account Application",
      link: "/account-application",
    },
    {
      title: "What we do",
      link: "/about-us/what-we-do",
    },
    {
      title: "Book appointment",
      link: "/about-us/book-anappointment",
    },
    {
      title: "Equipment Hires",
      link: "/equipment-hires",
    },
    {
      title: "ESG",
      link: "/about-us/esg-and-sustainability",
    },
    {
      title: "Shop Now",
      link: "https://abconet.com.au/",
    },
    {
      title: "Locations",
      link: "/about-us/esg-and-sustainability",
    },
  ];

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsFocused(false);
    history.push(`/search/${searchTerm}`);
    // setIsSearchOpen(false);
  };
  const onKeyDownHandler = (e: any) => {
    if (e.key === "Enter") {
      history.push(`/search/${searchTerm}`);
      // setIsSearchOpen(false);
    }
  };

  return (
    <SearchBarContainer className={props.className}>
      <form
        onSubmit={handleSubmit}
        ref={containerDivRef}
        className="search-form"
      >
        <div className="search-container">
          <div className="search-wrapper">
            <div className="search-icon-container" onClick={handleSubmit}>
              <svg
                aria-hidden="true"
                className="search-icon"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              className="search-input"
              placeholder="Search Here"
              onFocus={handleFocus}
              onKeyDown={onKeyDownHandler}
              onChange={(e) => setSearchTerm(e.target.value)}
              // onBlur={handleBlur}
              autoComplete="off"
            />
          </div>
          <div
            onClick={() => setIsSearchOpen(!isSearchOpen)}
            className="p-2.5 ml-2 text-body mr-2 font-medium text-white cursor-pointer hover:font-bold"
          >
            {/* <TfiClose /> */}
            {/* <span className="sr-only">Search</span> */}
          </div>
          {/* <input type="submit" value="SUBMIT" /> */}
        </div>
        {!props.isMobile && isFocused && (
          <div className="quick-links-container">
            <h4 className="quick-links-header">Quick Links</h4>
            <ul className="quick-links-list">
              {quickLinkList.map((link: any, key) => {
                if (link.title === "Shop Now")
                  return (
                    <li key={key} className="quick-links-item">
                      <Link
                        className="quick-links-link"
                        onClick={() => setIsFocused(false)}
                        to={{ pathname: link.link }}
                        target="_blank"
                      >
                        {link.title}
                      </Link>
                    </li>
                  );
                if (link.title === "Locations") {
                  return (
                    <li key={key} className="quick-links-item">
                      <a
                        className="quick-links-link"
                        href="#footer"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsFocused(false);
                          const element = document.getElementById("footer");
                          if (element) {
                            const yCoordinate =
                              element.getBoundingClientRect().top +
                              window.pageYOffset -
                              60;
                            window.scrollTo({
                              top: yCoordinate,
                              behavior: "smooth",
                            });
                          }
                        }}
                      >
                        {link.title}
                      </a>
                    </li>
                  );
                }
                return (
                  <li key={key} className="quick-links-item">
                    <Link
                      className="quick-links-link"
                      onClick={() => setIsFocused(false)}
                      to={link.link}
                    >
                      {link.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </form>
    </SearchBarContainer>
  );
};

export default SearchBar;
