import React, { FC, useState, useEffect } from "react";
import { Section, Tile } from "..";
import {
  sanityFetchAllByType,
  sanityFetchAllPosts,
  sanityFetchConsumables,
  sanityFetchIndustries,
} from "../../utils/globalFunctions";
import { TileListingContainer, TileListingWrapper } from "./TileListing.style";

interface ITileListingProps {
  data: any;
  lightBlue?: boolean;
  type:
    | "team"
    | "article"
    | "skeleton"
    | "blog"
    | "product"
    | "machinery"
    | "machineryCategory"
    | "innovation"
    | "machineryProduct"
    | "csr"
    | "consumables"
    | "governance";
  children?: any;
  category?: string;
  subcategory?: string;
  displayAll?: boolean;
  typeAll?: string;
}

const TileListing: FC<ITileListingProps> = ({
  data,
  type,
  children,
  category,
  subcategory,
  lightBlue,
  displayAll,
  typeAll,
}) => {
  const [allData, setAllData]: any = useState();
  useEffect(() => {
    let fetchRes;
    if (!displayAll) {
      return setAllData(data);
    }
    switch (typeAll) {
      case "blog":
        fetchRes = sanityFetchAllPosts();
        break;
      case "industries":
        fetchRes = sanityFetchIndustries();
        break;
      case "consumables":
        fetchRes = sanityFetchConsumables();
        break;
      default:
        fetchRes = sanityFetchAllByType(`${typeAll}`);
    }

    fetchRes.then((res) => {
      if (typeAll === "machineryCategory") {
        res.sort((a: any, b: any) => (a?.order > b?.order ? 1 : -1));
      }
      if (typeAll === "consumables") {
        res.sort((a: any, b: any) => {
          const orderA =
            typeof a?.order === "number" ? a.order : Number.MAX_SAFE_INTEGER;
          const orderB =
            typeof b?.order === "number" ? b.order : Number.MAX_SAFE_INTEGER;

          return orderA - orderB;
        });
      }
      setAllData(res);
    });
  }, [data, displayAll, typeAll]);

  return (
    <TileListingWrapper style={{ background: lightBlue ? "#f4f6f9" : "" }}>
      <Section className="tile-listing-section">
        <div className="header-container">{children}</div>
        <TileListingContainer>
          {allData !== undefined &&
            allData.map((tileInfo: any, key: number) => {
              return (
                <Tile
                  category={category}
                  subcategory={subcategory}
                  key={key}
                  data={tileInfo}
                  type={typeAll ? typeAll : type}
                />
              );
            })}
        </TileListingContainer>
      </Section>
    </TileListingWrapper>
  );
};

export default TileListing;
