import React, { FC, useEffect, useState } from "react";
import { LegalPoliciesContainer } from "./LegalPolicies.style";
import { PageBuilder, Seo, Section } from "../../components";
import { useParams } from "react-router-dom";
import { sanityFetchSinglePolicy } from "../../utils/globalFunctions";
import LoaderComponent from "../../components/Loader/LoaderComponent";
export interface ILegalPolicies {
  history?: any;
  match?: any;
  location: {
    state: {
      title: string;
      content: any;
      headerImage: any;
      slug: string;
      thumbnailImage: any;
      date: string;
      tags: string[];
    };
  };
}

const LegalPolicies: FC<ILegalPolicies> = (props: any) => {
  let { slug } = useParams<any>();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    let fetchRes = sanityFetchSinglePolicy(slug);
    fetchRes.then((res) => {
      setData(res[0]);
    });
  }, [slug]);

  if (data.length === 0) {
    return <LoaderComponent />;
  }

  return (
    <LegalPoliciesContainer>
      {data.seo && (
        <Seo
          description={data.seo.meta_description && data.seo.meta_description}
          keyword={data.seo.focus_keyword && data.seo.focus_keyword}
          title={data.seo.seo_title && data.seo.seo_title}
        />
      )}
      <Section className="post-section-container">
        <h2 className="blog-title">{data.title}</h2>
        <main className="blog-post-content">
          <PageBuilder pageBuilder={data.postPageBuilder} />
        </main>
      </Section>
    </LegalPoliciesContainer>
  );
};

export default LegalPolicies;
