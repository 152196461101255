import aboutUsCatalogue from "../../assets/images/navCatalogue.png";
import machineryCatalogue from "../../assets/images/new-catalogue.png";
// import innovationCatalogue from "../../assets/images/Stormpac-Brochure.png";

export const navLinks = [
  {
    id: 1,
    name: "About Us",
    path: "/about-us",
    sanityProvidedLink: true,
    sanityProvidedLinkType: "about-us",
    catalogue: {
      image: aboutUsCatalogue,
      text: "Check Out Our Convenient Online Shopping Platform ",
      url: "https://abconet.com.au/",
    },
    sublinks: [
      {
        name: "What We Do",
        path: "/what-we-do",
        external: false,
      },
      {
        name: "Mobilisations",
        path: "/mobilisations",
        external: false,
      },
      {
        name: "CSR and Sustainability",
        path: "/csr-programs",
        external: false,
      },
      {
        name: "Careers",
        path: "/work-with-us",
        external: false,
      },
    ],
  },
  {
    id: 2,
    name: "Consumables",
    path: "/consumables",
    sanityProvidedLink: false,
    sublinks: [],
  },
  {
    id: 3,
    name: "Machinery",
    path: "/machinery",
    sanityProvidedLink: true,
    sanityProvidedLinkType: "machinery",
    catalogue: {
      image: machineryCatalogue,
      text: "Download our Current Product Catalogues",
      url: "https://issuu.com/abcopro.com.au",
      file: "",
      // file: "/static/download-catalogues.zip",
    },
    sublinks: [{ dummy: "test" }],
  },
  {
    id: 4,
    name: "Articles",
    path: "/blogs",
    sanityProvidedLink: false,
    sanityProvidedLinkType: "blog",
    sublinks: [],
  },
  {
    id: 5,
    name: "Innovation",
    path: "/innovation",
    sanityProvidedLink: false,
    sanityProvidedLinkType: "innovation",
    // catalogue: {
    //   image: innovationCatalogue,
    //   text: "Download Our Brochure",
    //   file: "/static/surevac-brochure.pdf",
    // },
    sublinks: [],
  },
  {
    id: 6,
    name: "abconet",
    path: "/Abconet",
    sanityProvidedLink: false,
    sublinks: [],
  },
];
