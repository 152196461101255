// import { ChangeEvent } from "react";

export enum IIcon {
  cursor = "RiCursorFill",
  arrowDown = "FaAngleDown",
  arrowUp = "FaAngleUp",
  FaBars = "FaBars",
  facebook = "FaFacebook",
  instagram = "FaInstagram",
  linkedIn = "FaLinkedinIn",
  linkedIn2 = "FaLinkedin",
  phone = "FaPhone",
  mail = "FaEnvelope",
  search = "FaSearch",
  clock = "FaClock",
  close = "FaTimes",
  wrench = "FaWrench",
  handWithHeart = "FaHandHoldingHeart",
  people = "FaUserFriends",
  utensils = "FaUtensils",
  piggy = "FaPiggyBank",
  shakeHands = "FaRegHandshake",
  plusCart = "FaCartPlus",
  handMoney = "FaHandHoldingUsd",
  check = "FaCheckCircle",
  searchDollar = "FaSearchDollar",
  keyIcon = "FaKey",
  clipboard = "FaClipboardList",
  chart = "FaChartLine",
  tag = "FaTag",
  calendar = "FaCalendarCheck",
  arrowLeft = "FaArrowLeft",
  plus = "FaPlus",
  minus = "FaMinus",
  play = "FaPlay",
  sortDown = "FaSortDown",
  cart = "FaShoppingCart",
  location = "FaMapMarkerAlt",
  eye = "FaEye",
  hire = "FaHireAHelper",
  leaf = "FaLeaf",
  book = "GiOpenBook",
  vacuum = "GiVacuumCleaner",
  youtube = "FaYoutube",
}

export interface IIconProps {
  type: string;
  width?: string;
  height?: string;
  size?: string;
  color?: string;
  className?: string;
  onClick?: any;
  variants?: any;
}

export interface IHeroWrapper {
  bg?: any;
  large?: boolean;
  reverse?: boolean;
}

export interface ICardList {
  team?: boolean;
  data: {
    title?: string;
    name?: string;
    content?: string;
    image?: any;
    job?: string;
    linkedIn?: string;
  }[];
}

export interface ISkeletonCardList {
  data: {
    title: string;
    content: string;
    icon?: string;
    img?: any;
  }[];
}

export interface ISectionContainer {
  lightBlue?: boolean;
}

export interface ISearch {
  searchClicked?: boolean;
  onClick?: any;
}
export interface ICatalogueForm {
  showCatalogue: boolean;
}

export interface ISection {
  lightBlue?: boolean;
}

export interface ISkeletonCard {
  title: string;
  content: string;
  icon?: any;
  className?: string;
  image?: any;
  link?: string;
}

export interface IQuote {
  content: string;
  fullName: string;
  company: string;
}

export interface ITestimonials {
  data: {
    content: string;
    fullName: string;
    company: string;
  }[];
}

export interface IHomeContactSection {
  from?: string;
  data: {
    title: string;
    content: string;
    active?: boolean;
  }[];
}
