import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import Select from "react-select";
import { AiOutlineReload } from "react-icons/ai";

const SignatureTabs = ({
  handleCanvasChange,
  sigCanvasRef,
  clearSignature,
  isDrawingMode,
  setIsDrawingMode,
  typedSignature,
  setTypedSignature,
  selectedFont,
  setSelectedFont,
}: any) => {
  const [activeTab, setActiveTab] = useState("draw");

  const fonts = [
    { value: "Pacifico", label: "Pacifico" },
    { value: "Great Vibes", label: "Great Vibes" },
    { value: "Dancing Script", label: "Dancing Script" },
    { value: "Caveat", label: "Caveat" },
    { value: "Sacramento", label: "Sacramento" },
    { value: "Tangerine", label: "Tangerine" },
    { value: "Indie Flower", label: "Indie Flower" },
    { value: "Lobster", label: "Lobster" },
    { value: "Cookie", label: "Cookie" },
    { value: "Allura", label: "Allura" },
  ];
  const handleTabClick = (tab: any) => {
    if (tab === "type") {
      setIsDrawingMode(false);
    } else {
      setIsDrawingMode(true);
    }
    clearSignature();
    setActiveTab(tab);
  };

  const handleFontChange = (selectedOption: any) => {
    setSelectedFont(selectedOption);
  };
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      maxWidth: "150px",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontFamily: state.data.value,
      fontSize: "16px",
    }),
  };

  return (
    <div className="signature-container">
      <ul className="signature-tabs">
        <li
          className={activeTab === "draw" ? "active" : ""}
          onClick={() => handleTabClick("draw")}
        >
          Draw Signature
        </li>
        <li
          className={activeTab === "type" ? "active" : ""}
          onClick={() => handleTabClick("type")}
        >
          Type Signature
        </li>
        <div
          className="clear"
          onClick={clearSignature}
          title="Clear the signature"
        >
          <AiOutlineReload />
        </div>
      </ul>
      <div className="signature-content">
        {activeTab === "draw" && (
          <div className="signature-canvas">
            <SignatureCanvas
              ref={sigCanvasRef}
              minWidth={1}
              maxWidth={3}
              onEnd={handleCanvasChange}
              canvasProps={{
                width: 500,
                height: 200,
                className: "sigCanvas",
              }}
            />
            <p className="consent">
              By signing this document with electronic signature, I agree that
              such signature will be valid as handwritten signatures to the
              extent allowed by local law.{" "}
            </p>
          </div>
        )}
        {activeTab === "type" && (
          <>
            <Select
              value={selectedFont}
              onChange={handleFontChange}
              options={fonts}
              className="font-select"
              styles={customStyles}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
            <div className="signature-type">
              <input
                type="text"
                value={typedSignature}
                onChange={(e) => setTypedSignature(e.target.value)}
                style={{ fontFamily: selectedFont.value }}
              />
              <p className="consent">
                By signing this document with electronic signature, I agree that
                such signature will be valid as handwritten signatures to the
                extent allowed by local law.{" "}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignatureTabs;
