import styled, { css } from "styled-components";
import device from "../../utils/deviceScreenSizes";

interface ISectionContainer {
  lightBlue?: boolean;
}

export const SectionContainer = styled.div<ISectionContainer>`
  background: ${(props) => props.theme.colors.white};
  padding-bottom: 3rem;
  @media ${device.mobileL} {
    padding-left: 0;
    padding-right: 0;
  }
  h2 {
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
    font-size: ${(props) => props.theme.fontSizes.large};
    color: ${(props) => props.theme.colors.primary};
    max-width: 800px;
    margin: 0 auto;
    font-weight: 500;
    text-align: center;

    @media ${device.tablet} {
      font-size: 2.5rem;

      @media ${device.mobileL} {
        font-size: 2rem;
      }
    }
  }

  .section-cards {
    /* display: flex; */
    /* justify-content: space-between;
    flex-wrap: wrap; */
    /* justify-content: center; */
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    justify-items: center;
    /* column-count: 4; */
    &:after {
      content: "";
      flex: 1;
    }
    @media ${device.mobileL} {
      padding-left: 0;
      padding-right: 0;
    }

    & > div {
      margin: 1rem;
      min-height: 600px;
      min-width: 366px;
      flex: 1;
      @media ${device.tablet} {
        display: flex;
        flex-direction: row;
        flex: 100%;
        min-width: 200px;
        min-height: auto;
        /* background: red; */

        @media screen and (max-width: 600px) {
          flex-direction: column;
          flex: 100%;
          max-width: 400px;
        }
        @media ${device.mobileL} {
          /* background: blue; */
        }
      }
      a {
        .link-container {
          padding-left: 0;
        }
        @media ${device.tablet} {
          display: flex;
          flex-direction: row;
          flex: 100%;
          min-width: 200px;
          min-height: auto;
          .top {
            flex: 1;
            height: 100%;
          }
          .bottom {
            flex: 1;
          }
          /* background: red; */

          @media ${device.mobileL} {
            flex-direction: column;
            flex: 100%;
            min-width: 200px;
            /* background: blue; */
          }
        }
      }
    }
  }

  ${({ lightBlue }) =>
    lightBlue &&
    css`
      background: ${(props) => props.theme.colors.lightBlue};
    `}
`;
